import React, { useEffect, useState } from 'react'

import IAmenity from '@interfaces/IAmenity.d'

const AmenityContextState = React.createContext(null)

const useAmenityContextState = () => {
  const context = React.useContext(AmenityContextState)

  if (context === undefined) {
    throw new Error('useAmenityContextState was used outside of its Provider')
  }
  return context
}

interface AmenityContextProviderProps {
  children:      React.ReactNode,
  serverAmenity: IAmenity,
}

const AmenityContextProvider: React.FC<AmenityContextProviderProps> = ({ children, serverAmenity }) => {

  const [amenity, setAmenity] = useState(serverAmenity)

  useEffect(() => setAmenity(currentAmenity => currentAmenity.id !== serverAmenity.id ? serverAmenity : currentAmenity), [serverAmenity])

  const updateAmenity    = data => {
    if (data.response) {
      setAmenity(_amenity => data.response)
    } else {
      setAmenity(data)
    }
  }

  const addMeasurment    = data => setAmenity(amenity => ({ ...amenity, measurments: [...amenity.measurments, data]}))
  const updateMeasurment = data => setAmenity(amenity => ({ ...amenity, measurments: amenity.measurments.map(m => m.id === data.id ? data : m)}))

  const startUsage = data => setAmenity(amenity => ({ ...amenity, usage: data }))
  const takeUsage  = data => setAmenity(amenity => ({ ...amenity, usage: data }))
  const stopUsage  = ()   => setAmenity(amenity => ({ ...amenity, usage: null }))


  const machineStop    = data     => setAmenity(amenity => ({...amenity, machine_stop: data }))
  const addDocument    = data     => setAmenity(amenity => ({ ...amenity, documents: [data, ...amenity.documents], }))
  const updateDocument = document => setAmenity(amenity => ({ ...amenity, documents: amenity.documents.map(d => d.id === document.id ? document : d) }))
  const removeDocument = data     => setAmenity(amenity => ({ ...amenity, documents: amenity.documents.filter(d => d.id !== data.id) }))
  const moreDocuments  = data     => setAmenity(amenity => ({ ...amenity, documents: [...amenity.documents, ...data.documents] }))

  const updateMethods = {
    amenity: updateAmenity,

    addDocument:    addDocument,
    removeDocument: removeDocument,
    document:       updateDocument,
    moreDocuments:  moreDocuments,
    addMeasurment:  addMeasurment,
    measurment:     updateMeasurment,

    startUsage: startUsage,
    takeUsage:  takeUsage,
    stopUsage:  stopUsage,

    machineStop: machineStop,

  }

  return (
    <AmenityContextState.Provider value={{
      amenity, setAmenity,
      updateMethods
    }}>
      {children}
    </AmenityContextState.Provider>
  )
}

export { AmenityContextProvider, useAmenityContextState }
