import { Map, Marker } from 'react-map-gl'
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import Chart from 'react-apexcharts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Accordion  from '@components/Accordion'
import Avatar     from '@components/Avatar'
import Callout    from '@components/Callout'
import DatePicker from '@components/Form/DatePicker'
import FieldTag   from '@components/Form/FieldTag'
import Switch     from '@components/Switch'
import Tag        from '@components/Tag'
import Thumbnail  from '@components/Form/FileUploader/Thumbnail'

import { useGlobalContextState } from '@context/GlobalContext'

import { MEASURE_ACTIONS, measureReducer } from '@reducers/measureReducer'

import IMeasure from '@interfaces/IMeasure.d'

interface MeasureProps {
  measure: IMeasure
}

const MeasureDetails: React.FC<MeasureProps> = ({ measure }) => {

  const { i18n, fetchApi, CONSTANTS } = useGlobalContextState()

  const { MEASURMENT } = CONSTANTS

  const [measurment, setMeasurment] = useState(measure)
  const [dateRange,  setDateRange]  = useState([])
  const [preset,     setPreset]     = useState('thirty-last')

  useEffect(() => {
    switch(preset) {
      case 'thirty-last': setDateRange([]); break
      case 'thirty-days': setDateRange([new Date(new Date().setDate(new Date().getDate() - 30)).toISOString(), new Date().toISOString()]); break
      case 'six-months':  setDateRange([new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString(), new Date().toISOString()]); break
      case 'one-year':    setDateRange([new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString(), new Date().toISOString()]); break
      case 'custom':      setDateRange([])
    }
  }, [preset])

  const [_measureState, measureDispatch] = useReducer(measureReducer, { measure, fetchApi })

  useEffect(() => measureDispatch({ type: MEASURE_ACTIONS.FETCH_MEASURE, measure, callbacks: [response => setMeasurment(response.response)], params: { values: true, range: dateRange } }), [dateRange])

  const lowLimit    = useRef(null)
  const highLimit   = useRef(null)
  const limitPassed = useRef(null)

  const annotations = useMemo(() => {
    return measure.rules.map(rule => {
      let limit
      let margin
      let text

      switch(rule.rule_type) {
        case MEASURMENT.RULES.BIGGER_THAN:
          limit  = rule.limit
          margin = rule.limit - rule.margin
          text   = `> ${rule.limit} ~ ${rule.margin} `
          if (!highLimit.current || margin > highLimit.current) {
            highLimit.current   = margin
            limitPassed.current = true
          }
          break
        case MEASURMENT.RULES.SMALLER_THAN:
          limit  = rule.limit
          margin = rule.limit + rule.margin
          text   = `< ${rule.limit} ~ ${rule.margin} `
          if (!lowLimit.current || margin < lowLimit.current) {
            lowLimit.current    = margin
            limitPassed.current = true
          }
      }

      return ({
        y:           limit,
        y2:          margin,
        borderColor: 'var(--rep-danger)',
        fillColor:   'var(--rep-danger)',
        label:       { text }
      })
    })
  }, [measure])

  const options = useMemo(() => ({
    xaxis: {
      labels:     { show: false },
      axisTicks:  { show: false },
      type:       'category',
      categories: measurment.values.map(v => new Intl.DateTimeFormat(i18n.locale).format(new Date(v.date))),
    },
    yaxis: {
      min: 0
    },
    chart: {
      toolbar: {
        tools: {
          download:  true,
          selection: false,
          zoom:      false,
          zoomin:    false,
          zoomout:   false,
          pan:       false,
          reset:     false
        },
      },
      legend: {
        show: false
      }
    },
    tooltip: {
      x:      { show: false },
      y:      { show: false },
      custom: (opts) => {
        const value = measurment.values[opts.dataPointIndex]

        return `
        <div style="
          background: ${value.color};
          color: white;
          padding: 4px;
        ">
          ${value.reasons.map(r => `<strong>${i18n.t(`measurment.${r[0]}`)}</strong> ${r[1]}`).join('<br />')}
        </div>`
      }
    },
    dataLabels: {
      enabled: true,
      style:   {
        colors: [(opts) => {
          console.log(opts.dataPointIndex)
          const value = measurment.values[opts.dataPointIndex]
          return value.color
        }],
      },
    },
    stroke: {
      width:  2,
      colors: [limitPassed.current ? 'var(--rep-danger-middle)' : 'var(--rep-success-middle)'],
      curve:  'stepline',
    },
    annotations: {
      yaxis: annotations,
    },

    fill: {
      type:     'gradient',
      colors:   [limitPassed.current ? 'var(--rep-danger-middle)' : 'var(--rep-success-middle)'],
      gradient: {
        gradientToColors: ['white', 'var(--rep-primary-light)'],
        shadeIntensity:   .5,
        opacityFrom:      1,
        opacityTo:        0,
      }
    },
  }), [measurment, highLimit, lowLimit])

  const series = useMemo(() => [
    {
      name: measurment.name,
      data: measurment.values.map(v => v.content, 10),
    }
  ], [measurment])

  const parsedValue = measurmentValue => {
    switch(measurment.type) {
      case MEASURMENT.TYPES.NUMBER:    return measurmentValue.content
      case MEASURMENT.TYPES.FREE_TEXT: return measurmentValue.content
      case MEASURMENT.TYPES.LOCATION:  return measurmentValue.content.toString()
    }
  }

  return(
    <div>

      {measurment && measurment.type === MEASURMENT.TYPES.NUMBER &&
        <Switch
          name     = "preset"
          options  = {[
            { content: '30 dernières mesures', icon: <FontAwesomeIcon icon="layer-group" />,   value: 'thirty-last' },
            { content: '30 derniers jours',    icon: <FontAwesomeIcon icon="calendar-day" />,  value: 'thirty-days' },
            { content: '6 derniers mois',      icon: <FontAwesomeIcon icon="calendar-week" />, value: 'six-months' },
            { content: '1 an',                 icon: <FontAwesomeIcon icon="calendar" />,      value: 'one-year' },
            { content: 'Custom',               icon: <FontAwesomeIcon icon="gears" />,         value: 'custom' },
          ]}
          callback = {setPreset}
          selected = {preset}
        />
      }

      {preset === 'custom' &&
        <DatePicker
          name     = "date-range"
          mode     = "range"
          date     = {dateRange}
          callback = {setDateRange}
        />
      }
      {measurment && measurment.type === MEASURMENT.TYPES.NUMBER && measurment.values.length > 1 &&
        <Chart
          options = {options}
          series  = {series}
          type    = 'area'
          style   = {{ borderRadius: '5px' }}
          height  = '280px'
          width   = '100%'
        />
      }
      {measurment && measurment.type === MEASURMENT.TYPES.LOCATION &&
        <Map
          mapboxAccessToken = 'pk.eyJ1IjoiYmVydHJhbmRtb3JlbCIsImEiOiJjbGN1Z2o5a3cwbmk1M3dxdG91azJjZmZxIn0.mKd_D3yQCWmGnLC6TAvITw'
          initialViewState  = {{
            longitude: measure.last_value.content[1],
            latitude:  measure.last_value.content[0],
          }}
          style = {{
            borderRadius: '4px',
            top:          0,
            bottom:       0,
            width:        '100%',
            height:       '500px',
          }}
          mapStyle           = "mapbox://styles/mapbox/light-v11"
          attributionControl = {false}
        >
          {measurment.values.map(value =>
            <Marker
              key       = {value.id}
              longitude = {value.content[1]}
              latitude  = {value.content[0]}
              anchor    = "bottom"
              color     = {'var(--rep-primary)'}
              offset    = {[0, 0]}
            />
          )}
        </Map>
      }

      {!measurment.values.length &&
        <Callout
          type="warning"
          marginY='M'
        >
          Aucune mesure pour la période sélectionnée.
        </Callout>
      }
      {[...measurment.values].reverse().map(value =>
        <Accordion
          key         = {value.id}
          togglable   = {!!value.comment || !!value.reasons.length || !!value.fields.length || !!value.documents.length }
          header      = {
            <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
              <div style={{ color: value.color }}>{parsedValue(value)}</div>
              <Tag color='var(--rep-neutral-primary)' background='var(--rep-neutral-light)'>{new Date(value.date).toLocaleDateString(i18n.locale)}</Tag>
            </div>
          }
          action = {value.user &&
            <Avatar
              firstName  = {value.user.first_name}
              lastName   = {value.user.last_name}
              background = 'transparent'
              border     = 'var(--rep-neutral-primary-light)'
              color      = 'var(--rep-neutral-primary)'
              title      = {i18n.t('control_remarks.created_on', {date: new Date(value.date).toLocaleDateString(i18n.locale)})}
            />
          }
          colorHeader = 'var(--rep-neutral-primary)'
        >
          {value.comment
            ? <Callout
              type       = "primary"
              title      = {i18n.t('measurment.comment')}
              icon       = {<FontAwesomeIcon icon="comment-dots" />}
              border     = 'var(--rep-primary-light)'
              background = 'transparent'
              marginY    = 'S'
            >
              {value.comment}
            </Callout>
            : <div></div>
          }
          <div style={{display: 'flex' }}>
            {value.documents.map(file =>
              <Thumbnail
                key  = {file.id}
                file = {file}
                size = '50px'
              />
            )}
          </div>
          {value.fields.map(field =>
            <FieldTag
              key        = {field.id}
              field      = {field}
              background = 'transparent'
              withName
            />
          )}
          {value.reasons.map(r => {
            const colorIcon = { color: null, icon: null}
            switch(r[0]) {
              case 'ok':      colorIcon.color = 'var(--rep-success)'; colorIcon.icon = 'thumbs-up'; break
              case 'warning': colorIcon.color = 'var(--rep-warning)'; colorIcon.icon = 'exclamation-triangle'; break
              case 'danger':  colorIcon.color = 'var(--rep-danger)'; colorIcon.icon = 'land-mine-on'; break
            }
            return <Tag
              key            = {`measurment-${r[1]}`}
              color          = {colorIcon.color}
              icon           = {<FontAwesomeIcon icon={colorIcon.icon} />}
              tooltipContent = {i18n.t(`measurment.${r[0]}_description`)}
            >
              {r[1]}
            </Tag>
          })}
        </Accordion>
      )}
    </div>
  )
}

export default MeasureDetails
