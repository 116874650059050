import React, { useRef } from 'react'
import qs from 'qs'
import toast from 'react-hot-toast'

import Input from '../Input'

import { useGlobalContextState } from '@context/GlobalContext'

interface FormProps {
  formRef?:     React.RefObject<HTMLElement>
  addFormData?: (FormData) => void,
  validations?: (data: object) => {
    // Testing data, will trigger an error if true
    error:   boolean,
    // Error message string (already translated)
    message: string
  }[]
  callback:          (data: object, fieldset: React.RefObject<HTMLFieldSetElement>, reducerProps?: object) => void
  reducerProps?:     object   // Optional arguments to send to the callback
  children:          React.ReactNode
  submitText?:       string
  submitColor?:      string
  submitBackground?: string
  submitDisabled?:   boolean
  hideSumbit?:       boolean
}

const Form: React.FC<FormProps> = ({
  formRef,
  addFormData,
  validations,
  callback,
  reducerProps,
  children,
  submitText,
  submitColor,
  submitBackground,
  submitDisabled = false,
  hideSumbit = false,
}) => {

  const { i18n } = useGlobalContextState()

  const fieldset = useRef(null)

  // TODO
  // Move to react 19
  // Use <form action={sendData}> instead of onSubmit
  // + Using qs to parse FormData to correct json (instead of relying on rack)
  // Example
  // Form (the `key` is required in the name attribute for array of objects)
  // <input type="text" name="upload.names[15].id" />
  // <input type="text" name="upload.names[15].value" />
  // <input type="text" name="upload.names[21].id" />
  // <input type="text" name="upload.names[21].value" />
  // Callback
  // const data = [...formData].map(pair => `${pair[0]}=${pair[1]}`).join('&')
  // const parsed = qs.parse(data, { allowDots: true });
  // Result
  // { upload: { names: [{ id: '21', value: 'test' }, { id: '15', value: 'test' }] } }
  const sendData = event => {
    // Building data object
    event.preventDefault()
    const formData: FormData = new FormData(event.currentTarget)
    !!addFormData && addFormData(formData)
    // This is not parsing nested named inputs
    const dataObject = Object.fromEntries(formData)

    // Validations (optional)
    // If test if true, will trigger the error
    let errors
    if (validations?.length) {
      errors = validations(dataObject).filter(val => val.error)
    }

    if (errors?.length) return toast(errors.map(err => err.message).join(', '), { icon: '⚠️' })

    const data = [...formData].map(pair => `${pair[0]}=${encodeURIComponent(pair[1].toString())}`).join('&')
    const parsed = qs.parse(data, { allowDots: true })
    // Sending data and fieldset (to reactivate form once we get a response)
    callback(parsed, fieldset, reducerProps)
  }

  return (
    <form
      onSubmit = {sendData}
      ref      = {formRef}
    >
      <fieldset
        ref={fieldset}
        style = {{ position: 'relative'}}
      >
        {children}
        {!hideSumbit && <Input
          name         = 'submit'
          type         = 'submit'
          disabled     = {submitDisabled}
          defaultValue = {submitText || i18n.t('actions.save')}
          color        = {submitColor}
          background   = {submitBackground}
          marginY      = {'M'}
        />
        }
      </fieldset>
    </form>

  )
}

export default Form
