import React, { useState } from 'react'

import Button     from '@components/Button'
import Callout    from '@components/Callout'
import FormAnswer from './FormAnswer'
import Progress   from '@components/Progress'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGlobalContextState } from '@context/GlobalContext'

import * as FormStyle from '@components/Form/FormStyles'
import * as Style     from './style'

export default function FormReply({
  formReply,
  fetchReply,
  updateMethods,
  // answerQuestion
}) {


  const { i18n } = useGlobalContextState()

  // const fetchReply = (form, callback) => {
  //   fetchReply(form, [data => setForm(data)])
  // }

  // Should refetch the reply after answering
  const [form,        setForm]        = useState(formReply)
  const [openQuestions, setOpenQuestions] = useState(false)

  // useEffect(() => {
  //   console.log(form)
  // }, [form])

  // const answerCallback = (answer) => {
  //   answerQuestion(answer, form, [data => setForm(data.response), () => fetchReply(form)])
  //   // fetchReply(reply)
  // }

  if (!form.questions) return <div>Loading...</div>

  // const percent = useMemo(() => Number.parseInt((form.questions.filter(f => f.complete).length / form.questions.length * 100), 10), [form.questions])

  return <>
    {/* <div style={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }}> */}
    <FormStyle.FloatingHeader>
      <Style.ProgressText><div>Progrès</div><div>{Number.parseInt((form.questions.filter(f => f.complete).length / form.questions.length * 100), 10)} %</div></Style.ProgressText>
      <Progress
        percent = {Number.parseInt((form.questions.filter(f => f.complete).length / form.questions.length * 100), 10)}
        color   =  'var(--rep-success)'
      />
      <Button
        click = {() => setOpenQuestions(!openQuestions)}
        icon  = {<FontAwesomeIcon icon={openQuestions ? 'bars' : 'bars-progress'} />}
        background = 'var(--rep-primary-light)'
      >
        {openQuestions
          ? i18n.t('form_reply.actions.fold_replies')
          : i18n.t('form_reply.actions.open_replies')
        }
      </Button>
    </FormStyle.FloatingHeader>

    {/* </div> */}

    {form.questions.map(question =>
      <FormAnswer
        key            = {question.id}
        form           = {form}
        question       = {question}
        // answerQuestion = {answerQuestion}
        fetchReply     = {fetchReply}
        setForm        = {setForm}
        updateMethods  = {updateMethods}
        openQuestion   = {openQuestions}
      />
    )}
    {form.complete &&
      <Callout
        border     = 'var(--rep-success)'
        background = 'var(--rep-success-light)'
        color      = 'var(--rep-success)'
        icon       = {<FontAwesomeIcon icon="check" />}
      >
        {i18n.t('form_reply.success.completed')}
      </Callout>
    }
  </>
}
