import React, { useReducer, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DatePicker from '@form/DatePicker'
import Form       from '@components/Form/Form'
import Select     from '@form/Select'
import Textarea   from '@form/Textarea'

import * as FormStyle from '@form/FormStyles'

import { useGlobalContextState } from '@context/GlobalContext'

import { IReport, ITask } from '@interfaces/index'

import { MATERIAL_ACTIONS, materialReducer } from '@reducers/materialReducer'
import Spacer from '@components/Spacer'
import Input from '@components/Form/Input'

interface RequestFormProps {
  report:   IReport
  task?:    ITask,
  callback: (any) => void
}

const RequestForm: React.FC<RequestFormProps> = ({ report, task, callback }) => {

  const {
    current_company,
    current_user,
    fetchApi,
    i18n,
    CONSTANTS,
    closeModal
  } = useGlobalContextState()

  const { USER } = CONSTANTS

  const [selectedTask, setSelectedTask] = useState(task || report.tasks[0])

  const [_state, dispatch] = useReducer(materialReducer, { fetchApi })

  const sendRequestForm = (data, fieldset) => dispatch({
    type:      MATERIAL_ACTIONS.ADD_REQUEST,
    data,
    fieldset,
    callbacks: [resp => callback(resp.response), closeModal]
  })

  return (
    <Form
      callback   = {sendRequestForm}
      submitText = {i18n.t('material_request.actions.prepare_request')}
    >
      <FormStyle.Header marginY='S'>
        <FontAwesomeIcon icon="circle-info" />
        {i18n.t('shared.general_informations')}
      </FormStyle.Header>
      <Select
        label        = {i18n.t('offer_line.associated_task')}
        name         = "material_request.to_do_item_id"
        defaultValue = {selectedTask ? [selectedTask] : []}
        options      = {report?.tasks?.length ? report.tasks : selectedTask ? [selectedTask] : []}
        format       = {{ content: 'title', details: 'status', value: 'id' }}
        callback     = {(data) => setSelectedTask(data.object)}
        marginY      = 'M'
        withEmpty    = {!!report}
      />

      {report && <Input type="hidden" name="material_request.report_id"     defaultValue={report.id} />}

      <Spacer />

      <FormStyle.Header marginY='S'>
        <FontAwesomeIcon icon="list-ol" />{i18n.t('shared.details')}
      </FormStyle.Header>
      <FormStyle.Label>{i18n.t('material_request.preparer')}</FormStyle.Label>
      <Select
        name             = 'material_request.preparer_id'
        defaultValue     = {[current_company.default_stock_responsible || report.responsible]}
        search
        searchUrl        = '/users/search'
        filters          = {[
          {
            id:      'managers',
            name:    i18n.t('user.role.managers'),
            filters: { role: `${USER.ROLES.MANAGER},${USER.ROLES.LOCAL_MANAGER}` }
          }
        ]}
        defaultFilter    = 'managers'
        format           = {{ content: 'name', value: 'id', details: 'role' }}
        detailsLocaleKey = 'shared'
        placeholder      = {i18n.t('user.users')}
        marginY          = 'M'
        required
      />

      <FormStyle.Label>{i18n.t('material_request.requested_for')}</FormStyle.Label>
      <Select
        name             = 'material_request.requested_for_id'
        defaultValue     = {[current_user]}
        search
        searchUrl        = '/users/search'
        filters      = {[
          {
            id:      'technicians',
            name:    i18n.t('user.role.technicians'),
            filters: { role: ['technician', 'technician_manager'] }
          },
          {
            id:      'managers',
            name:    i18n.t('user.role.managers'),
            filters: { role: ['manager', 'local_manager'] }
          },
          {
            id:      'employees',
            name:    i18n.t('user.role.employees'),
            filters: { role: ['employee', 'employee_manager'] }
          },
          {
            id:      'all',
            name:    i18n.t('shared.all'),
            filters: {}
          }
        ]}
        defaultFilter    = 'technicians'
        format           = {{ content: 'name', value: 'id', details: 'role' }}
        detailsLocaleKey = 'shared'
        placeholder      = {i18n.t('user.users')}
        marginY          = 'M'
        required
      />

      <Textarea
        name         = 'material_request.description'
        label        = {i18n.t('shared.description')}
        marginY      = 'M'
      />

      <DatePicker
        label        = {i18n.t('material_request.deadline')}
        name         = 'material_request.deadline'
        date         = {new Date()}
      />
    </Form>
  )
}

export default RequestForm
