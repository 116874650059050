import styled from 'styled-components'

export const CroppedText = styled.div`
  /* width: 250px; */
  /* display: block; */
  overflow: hidden;
  overflow-x: auto;
  &::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  display: ${props => props.expanded ? '-webkit-box' : 'block'};
  -webkit-line-clamp: ${props => props.expanded ? 'unset' : 2};
  -webkit-box-orient: vertical;
  color:        var(--rep-neutral-primary);
  height: ${props => props.expanded ?  'initial' : '22ch'};
  /* text-overflow: ellipsis; */

  /* Needed to make it work */
  /* overflow:   hidden;
  white-space: nowrap; */
  /* box-shadow: ${props => props.expanded ? 'none' : 'inset 0px -30px 20px -10px var(--rep-primary-light)'}; */
  border-radius: 5px;
  /* border: 1px solid var(--rep-neutral-primary-middle); */
  padding: 4px;
  padding-bottom: 2rem;
  position: relative;
`

export const Expander = styled.div`
  position:        absolute;
  bottom:          ${props => props.expanded ? '-0.8rem' : '-0rem'};
  /* margin-bottom:   ${props => props.expanded ? 'rem' : '0'}; */
  display:         flex;
  justify-content: center;
  align-items:     center;
  background:      linear-gradient(0deg, white 60%, transparent 100%);
  height:          4rem;
  width:           100%;
  color:           var(--rep-primary);
  /* div {
    display:    block;
    width:      100%;
    text-align: center;
  } */
  &:hover { cursor: pointer }
`
