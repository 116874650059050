import React, { useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import RecurrencyForm from '@components/RecurrencyForm'

import Input          from '@form/Input'
import Textarea       from '@form/Textarea'

import PlanFormEquipments from './PlanFormEquipments'

import * as FormStyle from '@form/FormStyles'

import { useGlobalContextState } from '@context/GlobalContext'

import IMaintenancePlan from '@interfaces/IMaintenancePlan.d'
import Spacer from '@components/Spacer'

interface PlanQuickFormProps {
  data?:     IMaintenancePlan
  setData?:  React.Dispatch<React.SetStateAction<IMaintenancePlan>>
  callback?: (any) => void
}

const PlanQuickForm: React.FC<PlanQuickFormProps> = ({
  data,
  setData,
  callback
}) => {

  const { i18n } = useGlobalContextState()

  const nameRef        = useRef(null)
  const descriptionRef = useRef(null)

  const submitCallback = (event) => {
    event.preventDefault()
    callback(data)
  }

  return(
    <form onSubmit={submitCallback}>
      <FormStyle.Header marginY="M">
        <FontAwesomeIcon icon="circle-info" />
        {i18n.t('shared.general_informations')}
      </FormStyle.Header>

      <Input
        name         = 'name'
        label        = {i18n.t('shared.name')}
        tooltip      = {i18n.t('maintenance.form.tooltip.name_plan')}
        type         = 'text'
        marginY      = 'S'
        defaultValue = {data.name}
        forwardRef   = {nameRef}
        change       = {e => setData(data => ({...data, name: e.target.value }))}
        required
      />

      <Textarea
        name         = "description"
        forwardRef   = {descriptionRef}
        placeholder  = {i18n.t('shared.description')}
        marginY      = 'M'
        defaultValue = {data.description}
        tooltip      = {i18n.t('maintenance.form.tooltip.desc_plan')}
        change       = {e => setData(data => ({...data, description: e.target.value }))}
      />

      <RecurrencyForm
        data    = {data}
        setData = {setData}
        options = {false}
        resume  = 'inner'
      />

      <PlanFormEquipments
        data    = {data}
        setData = {setData}
        dateSelect
      />


      <Input
        name         = "submit-plan"
        type         = "submit"
        defaultValue = 'Sauver'
        marginY      = 'M'
      />

    </form>
  )
}

export default PlanQuickForm
