import React, { useEffect, useReducer, useRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button              from '@components/Button'
import DatePicker          from '@form/DatePicker'
import MultiselectItem     from '@form/Select/MultiselectItem'
import SelectEquipmentForm from '@components/Amenity/SelectEquipmentForm'

import { useGlobalContextState } from '@context/GlobalContext'

import { MAINTENANCE_ACTIONS, maintenanceReducer } from '@reducers/maintenanceReducer'

import * as FormStyle from '@components/Form/FormStyles'

import IMaintenancePlan      from '@interfaces/IMaintenancePlan.d'
import IMaintenancePlanGroup from '@interfaces/IMaintenancePlanGroup.d'
import Tag from '@components/Tag'

interface PlanFormEquipmentsProps {
  data?:      IMaintenancePlan | IMaintenancePlanGroup
  setData?:   React.Dispatch<React.SetStateAction<IMaintenancePlan | IMaintenancePlanGroup>>
  dateSelect: boolean
}

const PlanFormEquipments: React.FC<PlanFormEquipmentsProps> = ({
  data,
  setData,
  dateSelect = false,
  callback,
  showSave
}) => {

  const { fetchApi, i18n, fromRails, current_company, setInfoWindowProps } = useGlobalContextState()

  const [_loading, setLoading] = useState(false)
  const [_state, formDispatch] = useReducer(maintenanceReducer, {
    fetchApi,
    setLoading
  })

  // const parseEquipment = ({ maintenance, start = null, created = false }) => ({
  //   maintainable_id:   maintenance.maintainable_id || maintenance.id,
  //   maintainable_name: maintenance.maintainable_name || maintenance.name,
  //   class_plural:      maintenance.class_plural,
  //   start:             start || null,
  //   path_string:       maintenance.path_string,
  //   created:           maintenance.created || created
  // })

  const [units,           setUnits]     = useState(data.units.map(unit => ({...unit, id: unit.maintainable_id, name: unit.maintainable_name })))
  const [amenities,       setAmenities] = useState(data.amenities.map(amenity => ({...amenity, id: amenity.maintainable_id, name: amenity.maintainable_name })))

  const fetchPlan = id => {
    fetchApi({
      url:      `/maintenance_plans/${id}`,
      callback: planData => {
        setUnits(planData.units.map(u => ({ ...u, id: u.maintainable_id, name: u.maintainable_name })))
        setAmenities(planData.amenities.map(a => ({ ...a, id: a.maintainable_id, name: a.maintainable_name })))
        // setUnits(planData.units.map(u => ({ ...u, id: u.maintainable_id, name: u.maintainable_name })))
        // setAmenities(planData.amenities.map(a => ({ ...a, id: a.maintainable_id, name: a.maintainable_name })))
      }
    })
  }

  const fetched = useRef(false)

  useEffect(() => {
    if (fetched.current) return
    if (!data?.id) return
    // Dirty fix to fetch only when RoR modale is open
    const ror_modale = document.querySelector(`#edit-maintenance-equipments-react${data.id}`)
    if (fromRails && ror_modale) {
      const callback = (mutationList, observer) => {
        for (const mutation of mutationList) {
          if (mutation.attributeName === 'class' && ror_modale.classList.contains('show')) {
            fetched.current = true
            fetchPlan(data.id)
          }
        }
      }
      const observer = new MutationObserver(callback)
      observer.observe(ror_modale, { attributes: true, attributeOldValue: true, childList: false, subtree: false })
    // Normal React behaviour
    } else {
      fetched.current = true
      fetchPlan(data.id)
    }
  }, [])

  useEffect(() => { setData(existingData => ({...existingData, units, amenities })) }, [units, amenities])

  const updatePlan = equipments => {
    if (fromRails) {
      formDispatch({
        type:      MAINTENANCE_ACTIONS.UPDATE_PLAN_EQUIPMENTS,
        plan:      data,
        callbacks: [
          () => {
            window.location = '/maintenance_plans/full_index'
          }
        ],
        equipments: {...equipments, fromRails },
        fromRails,
      })
    } else {
      !!callback && callback(equipments)
    }
  }

  const addItem = item => {
    if (!item) return
    if (item.class_plural === 'units') {
      if (units.find(u => u.id === item.id)) return
      setUnits(selectedUnits => [...selectedUnits, { ...item, maintainable_id: item.id, maintainable_name: item.name, created: true}])
    } else if (item.class_plural === 'amenities') {
      if (amenities.find(a => a.id === item.id)) return
      setAmenities(selectedAmenities => [...selectedAmenities, { ...item, maintainable_id: item.id, maintainable_name: item.name, created: true}])
    }
  }

  const removeItem = (id, type) => {
    if (type === 'units') {
      let unitsCopy = [...units]
      unitsCopy = unitsCopy.filter(unit => unit.id !== id)
      setUnits(unitsCopy)
    } else if (type === 'amenities') {
      let amenitiesCopy = [...amenities]
      amenitiesCopy = amenitiesCopy.filter(unit => unit.id !== id)
      setAmenities(amenitiesCopy)
    }
  }

  const setDate = (id, date, type) => {
    if (type === 'units') {
      let unitsCopy = [...units]
      unitsCopy = unitsCopy.map(unit => unit.id === id ? {...unit, start: date.toISOString() } : unit)
      setUnits(unitsCopy)
    } else if (type === 'amenities') {
      let amenitiesCopy = [...amenities]
      amenitiesCopy = amenitiesCopy.map(amenity => amenity.id === id ? {...amenity, start: date.toISOString() } : amenity)
      setAmenities(amenitiesCopy)
    }
  }

  const openDetails = (object, type) => {
    current_company.beta_access
      ? setInfoWindowProps({ item: object, type: type, target: 'modal'})
      : window.open(`/${type}/${object.id}`, '_blank', 'noopener,noreferrer')
  }

  return(
    <>
      {showSave &&
        <FormStyle.FloatingHeader>
          <div style={{display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              click       = {() => updatePlan({units: units, amenities: amenities})}
              border      = 'var(--rep-primary-light)'
              background  = 'var(--rep-primary-light)'
              color       = 'var(--rep-primary)'
              icon        = {<FontAwesomeIcon icon="floppy-disk" />}
              align       = 'center'
            >
              {i18n.t('actions.save')}
            </Button>
          </div>
        </FormStyle.FloatingHeader>
      }
      <FormStyle.Header marginY='M'>
        <FontAwesomeIcon icon="oil-can" />
        {i18n.t('maintenance.assets')}
      </FormStyle.Header>

      {/* <FormStyle.Label>{i18n.t('maintenance.actions.add_equipments')}</FormStyle.Label> */}
      <SelectEquipmentForm
        units           = {units}
        amenities       = {amenities}
        unitAction      = {addItem}
        amenityAction   = {addItem}
        checkCallback   = {addItem}
        uncheckCallback = {item => removeItem(item.id, item.class_plural)}
        openSelector    = {!units.length && !amenities.length}
      />

      {[...units, ...amenities].map(maintenance =>
        <MultiselectItem
          key          = {`${maintenance.class_plural}-${maintenance.id}`}
          name         = {
            <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
              {maintenance.maintainable_name}
              {maintenance.created && <Tag
                color      = 'var(--rep-success)'
                background = 'var(--rep-success-light)'
              >
                {i18n.t('shared.new')}
              </Tag>
              }
            </div>}
          icon         = {<FontAwesomeIcon icon={maintenance.class_plural === 'units' ? 'location-dot' : 'gear'} />}
          tooltip      = {maintenance.path_string}
          border       = {maintenance.created ? 'var(--rep-success)' : 'var(--rep-neutral-primary-light)'}
          removeAction = {() => removeItem(maintenance.id, maintenance.class_plural)}
          click        = {() => openDetails({...maintenance, id: maintenance.id }, maintenance.class_plural)}
        >
          <DatePicker
            name     = 'maintenance-unit-start-date'
            label    = {i18n.t('maintenance.start_date')}
            date     = {maintenance.start ? new Date(maintenance.start) : new Date()}
            callback = {date => setDate(maintenance.id, date, maintenance.class_plural)}
            disabled = {dateSelect ? false : data?.id ? (maintenance.created ? false : true) : false}
            showTime
          />
        </MultiselectItem>
      )}

      {showSave &&
        <Button
          click       = {() => updatePlan({units: units, amenities: amenities})}
          border      = 'var(--rep-primary-light)'
          background  = 'var(--rep-primary-light)'
          color       = 'var(--rep-primary)'
          icon        = {<FontAwesomeIcon icon="floppy-disk" />}
          size        = 'L'
          marginY     = 'L'
          align       = 'center'
          fullWidth
        >
          {i18n.t('actions.save')}
        </Button>
      }
    </>
  )
}

export default PlanFormEquipments
