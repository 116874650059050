import React, { useMemo } from 'react'

import Avatar from '../Avatar'

import * as Style   from './style'

import IAvatarList  from './types.d'

import { useGlobalContextState } from '@context/GlobalContext'

const getRestOfUsers = (users) => users.map((user, key) => {
  if (!user) return
  return <div key={key}>{user.first_name} {user.last_name}</div>
})

/**
 * @description Component to describe several Avatars
 * @remarks
 * This component is a set of several {@link Avatar | Avatar component}
 * @example
 * <AvatarList users={[ first_name: 'Bertrand', last_name: 'Morel']} />
*/
const AvatarList: React.FC<IAvatarList> = ({
  users,
  click,
  limit      = 4,
  title      = 'Assigned to',
  border     = 'white',
  background = 'var(--rep-primary-light)',
  color      = 'var(--rep-primary)'
}) => {

  const { i18n } = useGlobalContextState()

  const limitedUsers = useMemo(() => getRestOfUsers(users), [users])
  const clickAction = () => !!click && click()

  if (!users.length) return
  return (
    <Style.AvatarList onClick={clickAction} overlapping={users.length !== 1}>
      { // Ensuring unique user display
        users.reduce((list, user) => {
          list.map(u => u.id).includes(user.id) ? null : list.push(user)
          return list
        }, [])
          .map((user, key) => {
            if (key < limit) {
              return <Avatar
                key            = {key}
                firstName      = {user.first_name}
                lastName       = {user.last_name}
                tooltipContent = {user.name}
                title          = {user.service_company || title || i18n.t('todo.assigned_to')}
                overlapping    = {users.length !== 1}
                background     = {background}
                color          = {color}
                border         = {border}
              />
            } else if (key === limit) {
              return <Avatar
                key            = {key}
                firstName      = ''
                lastName       = {users.length >= limit ? '…' : `${users.length - limit}`}
                title          = {user.service_company || title || i18n.t('todo.assigned_to')}
                tooltipContent = {limitedUsers}
                overlapping    = {users.length !== 1}
                border         = {border}
                background     = {background}
                color          = {color}
              />
            } else {
              return null
            }
          })
      }
    </Style.AvatarList>
  )
}

export default AvatarList
