import styled from 'styled-components'

export const FormContainer = styled.form`
  /* border:        1px solid var(--rep-primary-middle); */
  /* padding:       8px; */
  /* border-radius: 5px; */
`

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
`

export const QuestionName = styled.div`
  color:       var(--rep-neutral-primary);
  font-weight: 500;
  display:     flex;
  align-items: center;
`

export const AnswersContainer = styled.div`
  border:          1px dotted var(--rep-primary-light);
  border-radius:   5px;
  padding:         8px 16px;
`

export const AnswerOptionInput = styled.div`
  display:        flex;
  flex-direction: row;
  gap:            8px;
  margin-top:     16px;
`

export const AnswerOption = styled.li`
  align-items:     center;
  display:         flex;
  justify-content: space-between;
`

export const QuestionCheckboxes = styled.div`
  display        : flex;
  flex-direction : column;
  flex-grow      : 1;
`
