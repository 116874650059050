import React from 'react'

import * as Style from './style'

export default function Progress({ percent, color }) {
  return(
    <Style.Progress
      id    = "file"
      max   = "100"
      value = {percent}
      color = {color}
    >
      {percent}%
    </Style.Progress>
  )
}
