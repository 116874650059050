import React from 'react'

import WithTooltip from '@components/WithTooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Style from './style'

import { useGlobalContextState } from '@context/GlobalContext'
import Button from '@components/Button'

interface ExpanderProps extends React.PropsWithChildren {
  expanded:    boolean
  setExpanded: (any) => void
}

const Expander: React.FC<ExpanderProps> = ({
  expanded,
  setExpanded,
  children
}) => {

  const { i18n } = useGlobalContextState()

  return (
    <Style.CroppedText expanded={expanded}>
      {children}
      <Style.Expander expanded = {expanded}>
        {/* <WithTooltip
          content = {
            expanded
              ? i18n.t('shared.actions.fold')
              : i18n.t('shared.actions.expand')
          }
        > */}
        {/* {expanded && */}
        <div>
          <Button
            border     = 'var(--rep-primary)'
            icon       =  {<FontAwesomeIcon icon={`caret-${expanded ? 'up' : 'down'}`} />}
            click      = {() => setExpanded(actual => !actual)}
            background = 'white'
            align      = 'center'
            fullWidth
          >
            {expanded ? i18n.t('shared.actions.fold') : i18n.t('shared.actions.view_all')}
          </Button>
        </div>
        {/* // : <FontAwesomeIcon icon='caret-down' /> */}
        {/* } */}
        {/* </WithTooltip> */}
      </Style.Expander>
    </Style.CroppedText>
  )
}

export default Expander
