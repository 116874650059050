import React, { useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Avatar         from '@components/Avatar'
import Button         from '@components/Button'
import Checkbox       from '@form/Checkbox'
import DatePicker     from '@form/DatePicker/DatePicker'

import { useGlobalContextState } from '@context/GlobalContext'

import * as FormStyle from '@form/FormStyles'
import * as Style from './style'

import WorkPeriodProps from './types.d'


const WorkPeriod: React.FC<WorkPeriodProps> = ({
  period,
  editable = false,
  updatePeriod,
  deletePeriod
}) => {

  const { i18n, isDesktop, current_company } = useGlobalContextState()

  const [displayForm,    setDisplayForm]    = useState(false)
  const [dates,          setDates]          = useState([period.start, period.stop])
  const [showPeriodTime, setShowPeriodTime] = useState(current_company.permissions.dispatch_task_with_hours)
  const [notify,         setNotify]         = useState(true)
  const [startDateTime,  setStartDateTime]  = useState(period.start ? new Date(period.start) : null)

  useEffect(() => {
    setDates([period.start, period.stop])
    setStartDateTime(new Date(period.start))
  }, [period])

  useEffect(() => {
    if (startDateTime > new Date(dates[1])) {
      setDates(dates => [dates[0], null])
    }
  }, [startDateTime])

  const options = useMemo<object>(() => {
    return isDesktop
      ? { weekday: 'short', month: 'short', day: 'numeric' }
      : { month: 'short', day: 'numeric' }
  }, [])

  const savePeriod = () => {
    setDisplayForm(false)
    updatePeriod(period, dates, showPeriodTime, notify)
  }

  const startDate = useMemo(() => new Date(period.start).toLocaleDateString(i18n.locale, options), [period])
  const startTime = useMemo(() => new Date(period.start).toLocaleTimeString(i18n.locale, { hour: '2-digit', minute: '2-digit' }), [period])
  const stopDate  = useMemo(() => new Date(period.stop).toLocaleDateString(i18n.locale, options) ,[period])
  const stopTime  = useMemo(() => new Date(period.stop).toLocaleTimeString(i18n.locale, { hour: '2-digit', minute: '2-digit' }), [period])
  return (
    <>
      <Style.WorkPeriodContainer>
        <Avatar
          firstName   = {period.user.first_name}
          lastName    = {period.user.last_name}
          type        = 'left'
          fullHeight
        />
        <Style.WorkPeriod>
          {startDate} {startTime !== '00:00' && startTime}
          <FontAwesomeIcon icon="arrow-right-long" />
          {period.stop
            ? <>
              {stopDate} {stopTime !== '00:00' && stopTime}
            </>
            : <FontAwesomeIcon icon="question" />
          }
        </Style.WorkPeriod>
        {!!period.created_by &&
          <Avatar
            firstName      = {period.created_by.first_name}
            lastName       = {period.created_by.last_name}
            type           = 'right'
            color          = 'var(--rep-neutral)'
            background     = 'var(--rep-neutral-light)'
            fullHeight
            tooltipContent = {i18n.t('todo.assigned_by_name', { name: `${period.created_by?.first_name} ${period.created_by?.last_name}` })}
          />
        }
        {!!editable &&
          <Style.WorkPeriodAction>
            {displayForm
              ? <Button
                color      = 'var(--rep-success)'
                background = 'var(--rep-success-light)'
                click      = {savePeriod}
              >
                {i18n.t('actions.save')}
              </Button>
              : <Button
                background = 'var(--rep-primary-light)'
                color      = 'var(--rep-primary)'
                icon       = {<FontAwesomeIcon icon='pen' />}
                click      = {() => setDisplayForm(true)}
              >
                {i18n.t('actions.edit')}
              </Button>
            }
            <Button
              background = 'var(--rep-danger-light)'
              color      = 'var(--rep-danger)'
              icon       = {<FontAwesomeIcon icon='trash' />}
              click      = {() => deletePeriod(period)}
            />
          </Style.WorkPeriodAction>
        }
      </Style.WorkPeriodContainer>

      {!!editable &&
        <Style.WorkPeriodFormStyle>
          {!!displayForm &&
            <>
              {showPeriodTime
                ? <FormStyle.Group
                  align = 'center'
                  wrap  = 'nowrap'
                >
                  <DatePicker
                    name     = {`assigned_date_period_${period.id}`}
                    label    = {i18n.t('shared.from_date')}
                    date     = {dates[0]}
                    callback = {newDate => {
                      // Need to be refactored to be handled
                      setStartDateTime(new Date(newDate))
                      setDates(selectedDate => [new Date(newDate), selectedDate[1]])
                    }}
                    marginY  = 'M'
                    timeAsSelect
                    showTime
                    required
                  />
                  <FontAwesomeIcon icon="arrow-right" color='var(--rep-neutral-primary)'/>
                  <DatePicker
                    name     = {`assigned_date_period_${period.id}`}
                    label    = {i18n.t('shared.to_date')}
                    date     = {dates[1]}
                    callback = {newDate => setDates(selectedDates => [selectedDates[0], new Date(newDate)])}
                    minDateTime  = {startDateTime}
                    marginY  = 'M'
                    showTime
                    timeAsSelect
                    required
                  />
                </FormStyle.Group>
                : <DatePicker
                  name     = {`assigned_date_period_${period.id}`}
                  mode     = 'range'
                  date     = {dates}
                  callback = {setDates}
                  required
                />
              }
              <Checkbox
                name         = "show_time"
                label        = {i18n.t('todo.actions.dispatch_hours')}
                defaultValue = {showPeriodTime}
                callback     = {timeBox => setShowPeriodTime(timeBox.isCheck)}
              />
              {period.user.service_company_id &&
                <Checkbox
                  name         = "notify"
                  label        = {i18n.t('todo.actions.notify_user')}
                  defaultValue = {notify}
                  callback     = {notifyBox => setNotify(notifyBox.isCheck)}
                />
              }
            </>
          }
        </Style.WorkPeriodFormStyle>
      }
    </>
  )
}

export default WorkPeriod
