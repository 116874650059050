import React, { useCallback, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Accordion         from '@components/Accordion'
import Button            from '@components/Button'
import Callout           from '@components/Callout'
import ControlRemark     from '@components/ControlRemark/ControlRemark'
import DatePicker        from '@components/Form/DatePicker'
import FieldInput        from '@components/Form/FieldInput'
import Form              from '@components/Form/Form'
import Input             from '@components/Form/Input'
import MeasurementInForm from '@components/Measure/MeasureValue/MeasurmentInForm'
import Select            from '@components/Form/Select/Select'
import Tag               from '@components/Tag/Tag'
import Textarea          from '@components/Form/Textarea'

import { useGlobalContextState } from '@context/GlobalContext'

import * as FormStyle from '../Form/FormStyles'

import { IReport }        from '@interfaces/index'
import { REPORT_ACTIONS } from '@reducers/index'

import useHumanMs from '@hooks/useHumanMs'
import Spacer from '@components/Spacer'

const SEND_FOR_APPROVAL = 'send_for_approval'
const APPROVE           = 'approve'
const CLOSE             = 'close'
const CANCEL            = 'cancel'
const STEPS             = [SEND_FOR_APPROVAL, APPROVE, CLOSE, CANCEL]

interface EndReportProps {
  step:          typeof STEPS[number]
  report:        IReport,
  dispatch:      React.Dispatch<unknown>
  updateMethods: { [name: string]: (any) => void }
}

const EndReport: React.FC<EndReportProps> = ({
  step,
  report,
  dispatch,
  updateMethods
}) => {

  const {
    i18n,
    initAPI,
    current_user,
    CONSTANTS
  } = useGlobalContextState()

  const { TYPE_VALUES } = CONSTANTS.REPORT_TYPES
  const { ROLES } = CONSTANTS.USER
  const { MEASURMENT } = CONSTANTS.REPORT_TYPES

  const [closingDate, _setClosingDate] = useState(new Date())

  const API = initAPI({
    reportId:      report.id,
    updateMethods: updateMethods
  })

  const submitAction = useCallback((data, fieldset) => {
    switch (step) {
      case SEND_FOR_APPROVAL: return dispatch({
        type:      REPORT_ACTIONS.SEND_FOR_APPROVAL,
        report,
        data,
        fieldset,
        callbacks: [() => API.fetchReport().then(updateMethods.report)]
      })
      case CANCEL: return dispatch({
        type:      REPORT_ACTIONS.CANCEL,
        report,
        data,
        fieldset,
        callbacks: [() => API.fetchReport().then(updateMethods.report)]
      })
      case APPROVE: return dispatch({
        type:      REPORT_ACTIONS.APPROVE,
        report,
        data,
        fieldset,
        callbacks: [() => API.fetchReport().then(updateMethods.report)]
      })
      case CLOSE: return dispatch({
        type:      REPORT_ACTIONS.CLOSE,
        report,
        data,
        fieldset,
        callbacks: [() => API.fetchReport().then(updateMethods.report)]
      })
    }
  }, [])

  const submitValue = useMemo(() => {
    switch (step) {
      case SEND_FOR_APPROVAL: return i18n.t('report.actions.send_for_approval')
      case APPROVE: return i18n.t('report.actions.approve_report')
      case CANCEL: return i18n.t('report.actions.cancel_report')
      case CLOSE: return i18n.t('report.actions.close_report')
    }
  }, [])

  const fieldVisible = (field) => {
    switch (current_user.role) {
      case ROLES.EMPLOYEE_MANAGER:
        return field.visible_to_employee_managers
      case ROLES.EMPLOYEE:
        return field.visible_to_employees
      case ROLES.TECHNICIAN_MANAGER:
        return field.visible_to_technician_managers
      case ROLES.TECHNICIAN:
        return field.visible_to_technicians
      default:
        return true
    }
  }

  return (
    <Form
      callback         = {submitAction}
      submitText       = {submitValue}
      submitBackground = {[CANCEL].includes(step) ? 'var(--rep-danger-light)' : 'var(--rep-primary-light)'}
      submitColor      = {[CANCEL].includes(step) ? 'var(--rep-danger)' : 'var(--rep-primary)'}
    >
      {step === CANCEL &&
        <Callout
          icon       = {<FontAwesomeIcon icon="triangle-exclamation" />}
          color      = 'var(--rep-warning)'
          background = 'var(--rep-warning-light)'
          border     = 'var(--rep-warning)'
        >
          {i18n.t('report.deleting_warning_message')}
        </Callout>
      }
      {report.permissions.can_see_costs && report.permissions.require_report_approve && ![CANCEL].includes(step) &&
        <>
          <FormStyle.Header><FontAwesomeIcon icon="money-bill-transfer" />{i18n.t('offer_line.cost_resume')}</FormStyle.Header>
          <Accordion
            open        = {false}
            border      = 'var(--rep-neutral-primary-light)'
            colorHeader = 'var(--rep-neutral-primary)'
            togglable   = {false}
            header      = {
              <>
                <div style={{ flexGrow: 1 }}><FontAwesomeIcon icon="user-clock" /> {i18n.t('offer_line.line_types.hours')} × {useHumanMs(report.hours_quantity)}</div>
                {!!report.permissions.can_see_prices &&
                  <Tag background='white' color='var(--rep-success)'>
                    {Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(report.hours_amount)}
                  </Tag>
                }
              </>
            }
          />

          <Accordion
            open        = {false}
            border      = 'var(--rep-neutral-primary-light)'
            colorHeader = 'var(--rep-neutral-primary)'
            togglable   = {false}
            header      = {
              <>
                <div style={{ flexGrow: 1 }}><FontAwesomeIcon icon="truck" /> {i18n.t('offer_line.line_types.transport')}  × {report.transports_quantity}</div>
                {!!report.permissions.can_see_prices &&
                  <Tag background='white' color='var(--rep-success)'>
                    {Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(report.transports_amount)}
                  </Tag>
                }
              </>
            }
          />

          <Accordion
            open        = {false}
            border      = 'var(--rep-neutral-primary-light)'
            colorHeader = 'var(--rep-neutral-primary)'
            togglable   = {false}
            header      = {
              <>
                <div style={{ flexGrow: 1 }}><FontAwesomeIcon icon="cubes" /> {i18n.t('offer_line.line_types.inventory')}</div>
                {!!report.permissions.can_see_prices &&
                  <Tag background='white' color='var(--rep-success)'>
                    {Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(report.materials_amount)}
                  </Tag>
                }
              </>
            }
          />

          <Accordion
            open        = {false}
            border      = 'var(--rep-neutral-primary-light)'
            colorHeader = 'var(--rep-neutral-primary)'
            togglable   = {false}
            header      = {
              <>
                <div style={{ flexGrow: 1 }}><FontAwesomeIcon icon="file-invoice-dollar" /> {i18n.t('offer_line.line_types.external_invoice')}</div>
                {!!report.permissions.can_see_prices &&
                  <Tag background='white' color='var(--rep-success)'>
                    {Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(report.invoices_amount)}
                  </Tag>
                }
              </>
            }
          />

          {!!report.permissions.can_see_prices &&
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tag
                icon       = {<FontAwesomeIcon icon="receipt" />}
                background = 'white'
                color      = 'var(--rep-success)'
                border     = 'var(--rep-success)'
              >
                {i18n.t('offer_line.total_approved', { total: Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(report.all_costs_amount) })}
              </Tag>
            </div>
          }
        </>
      }

      {[APPROVE, CLOSE].includes(step) && !!report.remark &&
        <>
          <FormStyle.Header><FontAwesomeIcon icon="section" />{i18n.t('control_remarks.linked_remark')}</FormStyle.Header>
          <div style={{ margin: '8px 0' }}></div>
          <ControlRemark
            remark               = {report.remark}
            report               = {report}
            showFollowingReports = {false}
            displayOptions       = {false}
            updateMethods        = {updateMethods}
            showOriginReport
          />
        </>
      }

      {/* Fix visibility */}
      {[SEND_FOR_APPROVAL, APPROVE, CLOSE].includes(step) &&
        <>
          <FormStyle.Header><FontAwesomeIcon icon="receipt" />{i18n.t('anomaly.anomaly')}</FormStyle.Header>
          <Select
            name         = 'anomaly'
            search       = {true}
            searchUrl    = '/anomalies/search'
            withEmpty    = {true}
            defaultValue = {[report.anomaly]}
            format       = {{ content: 'name', value: 'id' }}
            marginY      = {'M'}
            required     = {report.permissions.require_anomaly}
            filters={[
              {
                id:      'report_type',
                name:    i18n.t('anomaly.anomalies'),
                filters: { report_type_id: `${report.type.id}` }
              }
            ]}
          />
        </>
      }

      {/* Fix visibility */}
      {[SEND_FOR_APPROVAL, APPROVE, CLOSE].includes(step) &&
        <>
          <FormStyle.Header><FontAwesomeIcon icon="receipt" />{i18n.t('charge_number.charge_number')}</FormStyle.Header>
          <Select
            name        = 'charge_number'
            search      = {true}
            searchUrl   = '/charge_numbers/search'
            withEmpty   = {true}
            defaultValue= {[report.input_code]}
            format      = {{ content: 'number', value: 'id', details: 'type' }}
            marginY     = 'M'
            required    = {report.permissions.require_charge_number}
          />
        </>
      }

      {[SEND_FOR_APPROVAL, APPROVE, CLOSE].includes(step) &&
        report.measurments.filter(m => m.time === MEASURMENT.AT_REPORT_CLOSING).map((measurment, index) =>
          <MeasurementInForm
            key        = {measurment.id}
            index      = {index}
            measurment = {measurment}
          />
        )
      }

      {!!report.type.custom_fields.length && [SEND_FOR_APPROVAL, APPROVE, CLOSE].includes(step) &&
        <>
          <FormStyle.Header><FontAwesomeIcon icon="layer-group" />{i18n.t('custom_field.custom_fields')}</FormStyle.Header>
          {report.type.custom_fields.map((field, index) => {
            return (fieldVisible(field) && <FieldInput
              key          =  {field.id}
              field        =  {field}
              idName       =  {`fields[${index}].id`}
              name         = {`fields[${index}].value`}
              defaultValue = {report.custom_fields.find(f => f.id === field.id)?.value}
              marginY      = 'M'
              disabled     = {report?.id && !report.permissions.can_update_report}
              required     = {[TYPE_VALUES.ON_CLOSE, TYPE_VALUES.MANDATORY].includes(field.mandatory)}
            />)
          })}
        </>
      }

      {step === SEND_FOR_APPROVAL &&
        <>
          <FormStyle.Header><FontAwesomeIcon icon="user-check" />{i18n.t('report.to_be_validated_by', { name: '' })}</FormStyle.Header>
          <Select
            name         = 'approver'
            search       = {true}
            label        = {i18n.t('report.select_validator')}
            searchUrl    = '/users/search'
            defaultValue = {[]}
            filters      = {[{
              id:      'role',
              name:    'Managers',
              filters: { role: 'manager,local_manager' }
            }]}
            defaultFilter    = 'role'
            format           = {{ content: 'name', value: 'id', details: 'role' }}
            required         = {true}
            marginY          = 'M'
            detailsLocaleKey = 'shared'
          />
        </>
      }

      {step === CLOSE &&
        <>
          {report.permissions.can_choose_closing_date &&
            <div>
              <FormStyle.Header>
                <FontAwesomeIcon icon="calendar-check" />{i18n.t('report.closing_date')}
              </FormStyle.Header>
              <DatePicker
                name = 'ended_at'
                date = {closingDate}
                required
              />
            </div>
          }
          <div>
            <Spacer size='m' />
            <FormStyle.Header>
              <FontAwesomeIcon icon="comment" />{i18n.t('report.closing_comment')}
            </FormStyle.Header>
            <Textarea
              name         = 'closing_comment'
              label        = {i18n.t('report.closing_comment')}
              defaultValue = {report.closing_comment}
              disabled     = {!report.permissions.can_close_report && !report.permissions.can_approve_and_close}
              marginY      = 'M'
              required     = {report.permissions.require_closing_comment}
            />
          </div>

          {!!report.maintenance_plan &&
            (report.maintenance_plan.mode === 'recurrency' || report.maintenance_plan.auto_generate) &&
            <>
              <FormStyle.Header>
                <FontAwesomeIcon icon="oil-can" />{i18n.t('report.next_maintenance')}
              </FormStyle.Header>
              <Callout
                type    = {'warning'}
                icon    = {<FontAwesomeIcon icon="info-circle" />}
                marginY = 'M'
                title   = {i18n.t('maintenance.maintenance_recurrence')}
              >
                {report.maintenance_plan.mode === 'interval'
                  ? i18n.t('report.maintenance_recurrency', { recurrency: report.maintenance_plan.sentence, done_date: new Intl.DateTimeFormat(i18n.locale).format(new Date(report.done_at)) })
                  : report.maintenance_plan.sentence
                }
              </Callout>
              {report.next_maintenance.id
                ?
                <Button
                  border = 'var(--rep-primary)'
                  hover  = 'var(--rep-primary-light)'
                  icon   = {<FontAwesomeIcon icon="receipt" />}
                  click  = {() => window.open(`/reports/${report.next_maintenance.id}`)}
                >
                  {i18n.t('report.maintenance_already_generated', { date: new Intl.DateTimeFormat(i18n.locale).format(new Date(report.next_maintenance.date)) })}
                </Button>
                : <>
                  <DatePicker
                    name = 'next_maintenance'
                    date = {report.next_maintenance.date}
                    marginY = 'M'
                    required
                  />
                  <Input
                    type         = {'hidden'}
                    name         = {'exclusion'}
                    defaultValue = {report.next_maintenance.date}
                  />
                </>
              }
            </>
          }
        </>
      }

      {[CANCEL].includes(step) &&
        <div>
          <FormStyle.Header>
            <FontAwesomeIcon icon="comment" />{i18n.t('report.closing_comment')}
          </FormStyle.Header>
          <Textarea
            name     = 'closing_comment'
            label    = {i18n.t('report.closing_comment')}
            marginY  = 'M'
            required = {report.permissions.require_closing_comment}
          />
        </div>
      }
    </Form>
  )
}

export default EndReport
