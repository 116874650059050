import React, { useMemo, useReducer, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Callout     from '@components/Callout'
import Dropdown    from '@components/Dropdown'
import Tag         from '@components/Tag'
import WithTooltip from '@components/WithTooltip'

import PlanForm from '@pages/Maintenances/Form/PlanForm'

import DragDropForm    from './DragDropForm'
import MaintenanceForm from './MaintenanceForm'
import PlanifyForm     from './PlanifyForm'
import ReactivateForm  from './ReactivateForm'

import * as Style from '../style'

import { firstDayOfWeek } from '@utils/time'

import { useGlobalContextState }       from '@context/GlobalContext'
import { useMaintenancesContextState } from '@context/MaintenancesContext'

import { MAINTENANCE_ACTIONS, maintenanceReducer } from '@reducers/maintenanceReducer'

import { IMainLine, ISubLine } from '@interfaces/ICalendar.d'

interface SubLineProps {
  maintenance: ISubLine
  mainline:    IMainLine
  setMainline: React.Dispatch<React.SetStateAction<IMainLine>>
  isSubItem:   boolean
}

const SubLine: React.FC<SubLineProps> = ({
  maintenance,
  mainline,
  setMainline,
  isSubItem,
}) => {

  const { CONSTANTS, i18n, current_company, showModal, closeModal, fetchApi, showAlert, closeAlert, setInfoWindowProps } = useGlobalContextState()
  const { currentWeek, weekCount, isCurrentYear, filters, MAINTENANCE_CONSTANTS } = useMaintenancesContextState()
  const { VIEWS }  = MAINTENANCE_CONSTANTS
  const { REPORT } = CONSTANTS

  const [render, setRender] = useState(true)
  const [hovered, setHovered] = useState(null)

  const [line, setLine]    = useState<ISubLine>(maintenance)
  const [_state, dispatch] = useReducer(maintenanceReducer, {
    fetchApi,
    filters,
    closeModal,
    VIEWS,
    setSubline:  setLine,
    setMainline: setMainline
  })

  const fetchMainLine     = ()   => dispatch({ type: MAINTENANCE_ACTIONS.FETCH_MAINLINE, mainline: mainline })
  const fetchSubLine      = ()   => dispatch({ type: MAINTENANCE_ACTIONS.FETCH_SUBLINE,  line })
  const updateMaintenance = (data, fieldset) => dispatch({
    type:        MAINTENANCE_ACTIONS.UPDATE_MAINTENANCE,
    maintenance: maintenance.data,
    fieldset,
    data,
    callbacks:   [
      () => fetchSubLine(),
      () => fetchMainLine()
    ]
  })
  const updatePlan    = data => dispatch({
    type:      MAINTENANCE_ACTIONS.UPDATE_PLAN,
    data,
    callbacks: [
      () => fetchMainLine(),
      () => fetchSubLine(),
    ]
  })
  const archiveMaintenance = () => dispatch({
    type:      MAINTENANCE_ACTIONS.ARCHIVE_MAINTENANCE,
    maintenance,
    callbacks: [
      () => fetchSubLine(),
      () => fetchMainLine()
    ]
  })
  const unarchiveMaintenance = (data, fieldset) => {
    dispatch({
      type:      MAINTENANCE_ACTIONS.UNARCHIVE_MAINTENANCE,
      maintenance,
      data,
      fieldset,
      callbacks: [
        () => fetchSubLine(),
        () => fetchMainLine()
      ]
    })
  }
  const destroyMaintenance = () => dispatch({
    type:        MAINTENANCE_ACTIONS.DESTROY_MAINTENANCE,
    maintenance: maintenance.data,
    callbacks:   [
      () => closeAlert(),
      () => fetchMainLine(),
      () => setRender(false),
    ]
  })

  const maintenanceActions = useMemo(() => {
    const maintenanceOptions = []
    if (line.data.permissions.can_update_maintenance) maintenanceOptions.push({
      icon:    <FontAwesomeIcon icon="pen" />,
      color:   'var(--rep-primary)',
      content: i18n.t('maintenance.actions.maintenance_edit'),
      click:   () => showModal({
        title:   `[${line.data.name}] ${line.data.maintainable_name}`,
        content: <MaintenanceForm
          maintenance       = {line.data}
          updateMaintenance = {updateMaintenance}
        />
      })
    })
    if (filters.view == VIEWS.MAINTAINABLES) maintenanceOptions.push({
      icon:      <FontAwesomeIcon icon="pen-to-square" />,
      color:     'var(--rep-primary)',
      content:   i18n.t('maintenance.actions.plan_edit'),
      separator: true,
      click:     () => showModal({
        title:   i18n.t('maintenance.actions.plan_edit', {name: line.data.name }),
        content: <PlanForm
          item       = {line.data}
          plan_id    = {line.data.plan_id}
          updatePlanProps = {updatePlan}
        />,
        asWizard: true
      })
    })
    if (line.data.permissions.can_archive_maintenance) maintenanceOptions.push({
      icon:    <FontAwesomeIcon icon="folder" />,
      color:   'var(--rep-warning)',
      content: i18n.t('maintenance.actions.archive'),
      click:   archiveMaintenance
    })
    if (line.data.permissions.can_unarchive_maintenance) maintenanceOptions.push({
      icon:    <FontAwesomeIcon icon="folder-open" />,
      color:   'var(--rep-warning)',
      content: i18n.t('maintenance.actions.unarchive'),
      click:   () => showModal({
        title:   i18n.t('maintenance.actions.generate_maintenance_for_asset', { maintenance: line.data.name, asset: line.data.maintainable_name }),
        content: <ReactivateForm
          maintenance  = {line}
          callback     = {unarchiveMaintenance}
        />
      })
    })
    if (line.data.permissions.can_destroy_maintenance) maintenanceOptions.push({
      icon:    <FontAwesomeIcon icon="trash-can" />,
      color:   'var(--rep-danger)',
      content: i18n.t('actions.destroy'),
      click:   () => showAlert(
        <Callout type="danger" icon={<FontAwesomeIcon icon="triangle-exclamation" />}>
          {i18n.t('maintenance.actions.delete_maintenance')}
        </Callout>,
        [{
          content:    i18n.t('actions.delete'),
          color:      'white',
          background: 'var(--rep-danger)',
          icon:       <FontAwesomeIcon icon="trash-can" />,
          click:      destroyMaintenance
        }]
      )
    })

    return maintenanceOptions
  }, [line])

  const planifyReportForm = ({ date, dateFrom }: { date: string, dateFrom?: string }) => {
    if (!line.data.permissions.can_create_maintenance_report) return
    showModal({
      title:   i18n.t('maintenance.actions.generate_maintenance_for_asset', { maintenance: line.data.name, asset: line.data.maintainable_name }),
      content: <PlanifyForm
        maintenance    = {line}
        date           = {date}
        from           = {dateFrom}
        fetchMainLine  = {fetchMainLine}
        fetchSubLine   = {fetchSubLine}
        cancelPrevious = {maintenance.data.mode === 'interval'}
      />
    })
  }

  const changeWeek = ({ date, id }) => {
    showModal({
      title:   i18n.t('maintenance.actions.generate_maintenance_for_asset', { maintenance: line.data.name, asset: line.data.maintainable_name }),
      content: <DragDropForm
        maintenance   = {line}
        reportId      = {id || ''}
        date          = {date}
        fetchMainLine = {fetchMainLine}
        fetchSubLine  = {fetchSubLine}
      />
    })
  }

  const dragStart = event => {
    if (!line.data.permissions.can_replanify_maintenance) return
    event.dataTransfer.setData('reportId', event.target.dataset.report)
    event.dataTransfer.setData('dateFrom', event.target.dataset.date)
    event.dataTransfer.setData('from', event.target.dataset.week)
  }

  const dragOver = e => {
    if (!line.data.permissions.can_replanify_maintenance) return
    e.stopPropagation()
    e.preventDefault()

    e.currentTarget.style.backgroundColor = 'var(--rep-neutral-primary-light)'
  }

  const dragLeave = e => {
    if (!line.data.permissions.can_replanify_maintenance) return
    const to = e.currentTarget.dataset.week
    e.currentTarget.style.backgroundColor = Number.parseInt(to, 10) === currentWeek ? 'var(--rep-neutral-primary-light)' : 'transparent'
  }

  const dropTicket = event => {
    if (!line.data.permissions.can_replanify_maintenance) return
    const from = event.dataTransfer.getData('from')
    const to       = event.currentTarget.dataset.week
    const reportId = event.dataTransfer.getData('reportId')
    const dateFrom = event.dataTransfer.getData('dateFrom')

    if (from === to) return

    const targetDate = firstDayOfWeek(1, filters.year, to)
    targetDate.setDate(targetDate.getDate() + (new Date(dateFrom).getDay() - 1))

    event.currentTarget.style.backgroundColor = Number.parseInt(to, 10) === currentWeek ? 'var(--rep-neutral-primary-light)' : 'transparent'
    if (reportId === 'created') {
      planifyReportForm({ date: targetDate, dateFrom: dateFrom })
    } else {
      changeWeek({ date: targetDate, id: reportId })
    }
  }

  const isLate = report => report.status !== REPORT.STATUS.CLOSED && new Date(report.expected_date).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)

  const reportTooltip = report => {
    return <div>
      <div><FontAwesomeIcon icon="hashtag" /> {report.report_number}</div>
      <div><FontAwesomeIcon icon="circle-info" /> {report.title}</div>
      <div><FontAwesomeIcon icon="clock" /> {report.total_duration}</div>
      <div><FontAwesomeIcon icon="user-gear" /> {i18n.t(`report.status.${report.status}`)}</div>
      <div><FontAwesomeIcon icon="calendar-day" /> {i18n.t('report.expected_for_date', { date: new Date(report.expected_date).toLocaleDateString(i18n.locale) })}</div>
      {report.done_at && <div><FontAwesomeIcon icon="clipboard-check" />  {i18n.t('report.done_on', { date: new Date(report.done_at).toLocaleDateString(i18n.locale) })}</div>}
      {report.ended_at && <div><FontAwesomeIcon icon="lock" /> {i18n.t('report.closed_on', { date: new Date(report.ended_at).toLocaleDateString(i18n.locale) })}</div>}
    </div>
  }

  const openDetails = (object, type) => {
    current_company.beta_access
      ? setInfoWindowProps({ item: object, type: type, target: 'page'})
      : window.open(`/${type}/${object.id}`, '_blank', 'noopener,noreferrer')
  }

  if (!render) return null

  return (
    <Style.CalendarLine
      id         = {line.data.id}
      length     = {weekCount + 1}
      background = {line.data.archived ? 'var(--rep-neutral-primary-light)' : 'var(--rep-neutral-light)'}
      noAlternateBackground
      clickable
    >
      <Style.CalendarName
        hideShadow = {true}
        borderLeft = {isSubItem ? 8 : 4}
        background = {line.data.archived ? 'var(--rep-neutral-primary-light)' : line.data.late ? 'var(--rep-danger-light)' : 'var(--rep-neutral-light)'}
        color      = {line.data.archived ? 'var(--rep-neutral-primary)' : line.data.late ? 'var(--rep-danger)' : 'var(--rep-neutral-primary)' }
      >
        {filters.view === VIEWS.PLANS
          ? (line.data.class_plural === 'amenities'
            ? <FontAwesomeIcon icon="gears"/>
            : <FontAwesomeIcon icon="location-dot" />)
          : line.data.legal
            ? <WithTooltip content={i18n.t('maintenance.legal_control')}>
              <FontAwesomeIcon icon="medal" color="var(--rep-warning)"/>
            </WithTooltip>
            : <div></div>
        }
        <Style.CalendarControlName>
          {line.data.archived &&
            <WithTooltip content={i18n.t('maintenance.archived')}>
              <FontAwesomeIcon icon="folder" color="var(--rep-warning)" style={{ marginRight: '4px' }}/>
            </WithTooltip>
          }
          <div className="mainline--name">
            <WithTooltip title={filters.view === VIEWS.MAINTAINABLES && line.data.name} content={filters.view === VIEWS.MAINTAINABLES ? line.data.sentence : line.data.path_string}>
              {filters.view === VIEWS.MAINTAINABLES && line.data.name}
              {filters.view === VIEWS.PLANS &&
              <div onClick={() => openDetails({ id: line.data.maintainable_id }, line.data.class_plural)}>
                {line.data.maintainable_name}
              </div>
              }
            </WithTooltip>
          </div>
        </Style.CalendarControlName>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Dropdown
            icon    = {<FontAwesomeIcon icon="cog" color={line.data.archived ? 'var(--rep-neutral-primary)' : line.data.late ? 'var(--rep-danger)' : 'var(--rep-primary)'}/>}
            options = {maintenanceActions}
          />
          {}
          {filters.view == 'plans' &&
            <FontAwesomeIcon
              icon="window-restore"
              color="var(--rep-primary)"
              onClick={() => openDetails({ id: line.data.maintainable_id }, line.data.class_plural)}
            />
            // <Tag
            //   tooltipContent = {i18n.t('maintenance.calendar.visualize_asset')}
            //   icon           = {<FontAwesomeIcon icon="window-restore" />}
            //   color          = 'var(--rep-primary)'
            //   click          = {() => setInfoWindowProps({ id: line.data.maintainable_id, resourceType: line.data.class_plural === 'units' ? 'unit' : 'amenity' })}
            // />
          }
        </div>
      </Style.CalendarName>
      {Array.from({ length: weekCount }, (_x, weekNb) =>
        <Style.CalendarBox
          key           = {weekNb}
          data-week     = {weekNb + 1}
          currentWeek   = {isCurrentYear && ((weekNb + 1) === currentWeek)}
          onDragStart   = {dragStart}
          onDragOver    = {dragOver}
          onDragLeave   = {dragLeave}
          onDrop        = {dropTicket}
          onMouseOver   = {() => setHovered(weekNb)}
          onMouseLeave   = {() => setHovered(null)}
          border        = 'var(--rep-neutral-primary-light)'
          noAlternateBackground
        >
          {(weekNb + 1) === currentWeek &&
            line.data.late &&
            line.data.mode === 'interval' &&
            !line.data.recurrence.auto_generate &&
            line.data.permissions.can_create_maintenance_report &&
            !line.planned[weekNb + 1]?.length &&
            <Tag
              hover          = {currentWeek > (weekNb + 1) ? 'var(--rep-danger-light)' : 'var(--rep-warning-light)'}
              color          = {currentWeek > (weekNb + 1) ? 'var(--rep-danger)' : 'var(--rep-warning)'}
              click          = {() => planifyReportForm({ date: new Date().toISOString() })}
              tooltipContent = {i18n.t('maintenance.calendar.generate_for_today')}
            >
              <FontAwesomeIcon icon="circle" size='xs'/>
            </Tag>
          }

          {(line.planned[weekNb + 1] || []).concat(line.expected[weekNb + 1] || [])
            .sort((a, b) => (a?.expected_date || a) > (b?.expected_date || b))
            .map(report =>
              report?.expected_date
                ? <Style.CalendarReportBox
                  key         = {report.id}
                  data-report = {report.id}
                  data-week   = {weekNb + 1}
                  data-date   = {report.expected_date}
                  onDragStart = {dragStart}
                  draggable   = {![REPORT.STATUS.CLOSED, REPORT.STATUS.CANCELED].includes(report.status)}
                >
                  <Tag
                    click          = {() => openDetails({ id: report.id }, 'reports')}
                    tooltipContent = {reportTooltip(report)}
                    hover          = {isLate(report) ? 'var(--rep-danger-light)' : report.status === REPORT.STATUS.NEW ? 'var(--rep-warning-light)' : report.status === REPORT.STATUS.CLOSED ? 'var(--rep-success-light)' : 'var(--rep-accent-light)' }
                  >
                    <FontAwesomeIcon
                      icon  = {report.status === REPORT.STATUS.CANCELED ? ['far', 'circle-xmark'] : 'circle'}
                      size  = 'xs'
                      color = {
                        report.status === REPORT.STATUS.CANCELED
                          ? 'var(--rep-danger)'
                          : (report.done_at && report.status !== REPORT.STATUS.CLOSED)
                            ? 'var(--rep-primary)'
                            : isLate(report)
                              ? 'var(--rep-danger)'
                              : report.status === REPORT.STATUS.NEW
                                ? 'var(--rep-warning)'
                                : report.status === REPORT.STATUS.CLOSED
                                  ? 'var(--rep-success)'
                                  : 'var(--rep-accent)'
                      }
                    />
                  </Tag>
                </Style.CalendarReportBox>
                : <Style.CalendarReportBox
                  key         = {report.id}
                  data-report = 'created'
                  data-week   = {weekNb + 1}
                  data-date   = {new Date(report).toISOString()}
                  onDragStart = {dragStart}
                  draggable   = {![REPORT.STATUS.CLOSED, REPORT.STATUS.CANCELED].includes(report.status)}
                >
                  <Tag
                    key            = {report}
                    hover          = {currentWeek > (weekNb + 1) ? 'var(--rep-danger-light)' : 'var(--rep-warning-light)'}
                    color          = {currentWeek > (weekNb + 1) ? 'var(--rep-danger)' : 'var(--rep-warning)'}
                    click          = {() => planifyReportForm({ date: report })}
                    tooltipContent = {
                      line.data.permissions.can_create_maintenance_report
                        ? i18n.t('maintenance.calendar.generate_for_x', { date: new Date(report).toLocaleDateString(i18n.locale) })
                        : i18n.t('maintenance.calendar.recurrency_planned_for_x', { date: new Date(report).toLocaleDateString(i18n.locale) })
                    }
                  >
                    <FontAwesomeIcon icon='circle' size='xs'/>
                  </Tag>
                </Style.CalendarReportBox>
            )}

          {
            !!hovered && (hovered === weekNb) &&
              <Tag
                hover          = {currentWeek > (weekNb + 1) ? 'var(--rep-danger-light)' : 'var(--rep-warning-light)'}
                color          = {currentWeek > (weekNb + 1) ? 'var(--rep-danger)' : 'var(--rep-warning)'}
                click          = {() => planifyReportForm({ date: new Date(filters.year, 0, (1 + (weekNb) * 7)) })}
                tooltipContent = {
                  line.data.permissions.can_create_maintenance_report
                    ? i18n.t('maintenance.calendar.generate_for_x', { date: new Date(filters.year, 0, (1 + (weekNb) * 7)).toLocaleDateString(i18n.locale) })
                    : ''
                }
              >
                <FontAwesomeIcon icon={['far', 'circle']} size='xs'/>
              </Tag>
          }

        </Style.CalendarBox>
      )}
    </Style.CalendarLine>
  )
}

export default SubLine
