import styled from 'styled-components'

export const InputContainer = styled.div`
  max-width:  ${props => props.width ? props.width : 'unset'};
  position:   relative;
  margin-top: ${props => {
    switch (props.marginY) {
      case 'S': return 'var(--rep-spacer-s)'
      case 'M': return 'var(--rep-spacer-m)'
      case 'L': return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default: return '0'
    }
  }};
  margin-bottom: ${props => {
    switch (props.marginY) {
      case 'S': return 'var(--rep-spacer-s)'
      case 'M': return 'var(--rep-spacer-m)'
      case 'L': return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default: return '0'
    }
  }};
  flex-grow:  1;
  display: ${props => props.hidden ? 'none' : 'block'};
`

export const Input = styled.input`
  padding:                  16px 8px;
  padding-left:             ${props => props.icon ? '2.5rem' : '8px'} !important;
  width:                    100%;
  outline:                  none;
  border:                   1px solid ${props => props.required && !props.hasValue ? 'var(--rep-warning)' : 'var(--rep-primary-light)'};
  transition:               .3s ease;
  box-sizing:               border-box;
  color:                    var(--rep-neutral-primary);
  border-radius:            4px;
  -webkit-text-size-adjust: none;
  text-size-adjust:         none;
  font-size:                1rem;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${props => props.required && !props.hasValue ? 'var(--rep-warning)' : 'var(--rep-neutral)'};
  }
  :-ms-input-placeholder {
    color: ${props => props.required && !props.hasValue ? 'var(--rep-warning)' : 'var(--rep-neutral)'};
  }

  ${props => (props.hasValue) && `
      background:     white;
      border:         1px solid var(--rep-primary-light);
      border-radius:  5px;
  `}

  ${props => (props.focused && props.hasValue) && `
      border: 1px solid var(--rep-primary);
  `}

  &[type=submit] {
    background: ${props => props.background};
    color:      ${props => props.color};
    transition: .3s ease;
    &:hover {
      background: ${props => props.color};
      color:      var(--rep-neutral-light);
      border:     1px solid ${props => props.color};
    }
  }

  background: ${props => props.disabled || props.readOnly ? 'var(--rep-neutral-light)' : null};

  @media only screen and (hover: none) and (pointer: coarse) {
    font-size: 11px;
    &[type=submit] { font-size: 16px !important; }
    &:focus { font-size: 16px }
  }
`

export const InputLabel = styled.label`
  position:     absolute;
  color:        ${props => props.active ? 'var(--rep-neutral)' : (props.required ? 'var(--rep-warning)' : 'var(--rep-neutral)')};
  font-weight:  ${props => props.active ? '400' : '200'};
  font-size:    ${props => props.active ? '.8rem' : '1rem'};
  opacity:      ${props => props.active ? '100%' : '50%'};
  top:          ${props => props.active ? '-.5rem' : 'calc(50% - 1rem)'};
  left:         8px;
  transition:   .3s ease;
  font-weight:  400;
  padding:      4px;
  background:   ${props => (!props.disabled && !props.readOnly) && props.active ? 'white' : 'transparent'};
  border-radius: 5px;
`

export const LockIcon = styled.div`
  position: absolute;
  right:    8px;
  top:      8px;
  color:    var(--rep-primary);
`

export const InputHint = styled.div`
  position:        absolute;
  right:           8px;
  top:             .8rem;
  color:         #52acf3;
  background:      var(--rep-primary-light);
  display:         flex;
  justify-content: center;
  align-items:     center;
  padding:         6px;
  border-radius:   4px;
  font-weight:     700;
  &:hover { cursor: default }
`

export const Icon = styled.div`
  position:  absolute;
  font-size: 1rem;
  top:       calc(50% - .8rem);
  left:      16px;
`
