import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import toast from 'react-hot-toast'

const FETCH            = 'COST_FETCH'
const CREATE           = 'COST_CREATE'
const UPDATE           = 'COST_UPDATE'
const CHANGE_STATUS    = 'COST_CHANGE_STATUS'
const ALL_COST_APPROVE = 'COST_ALL_COST_APPROVE'
const DESTROY          = 'COST_DESTROY'

export const COST_ACTIONS = {
  FETCH:            FETCH,
  CREATE:           CREATE,
  UPDATE:           UPDATE,
  CHANGE_STATUS:    CHANGE_STATUS,
  ALL_COST_APPROVE: ALL_COST_APPROVE,
  DESTROY:          DESTROY,
}

export function costReducer(state, action) {

  const { API, fetchApi, setAnimation, updateMethods, closeModal } = state

  const callbacks   = action.callbacks || []
  const fieldset    = action.fieldset
  const contentType = action.contentType ? action.contentType : 'application/json'

  // Will initialize the fetch functions with the updators to refresh data
  switch (action.type) {
    // case FETCH:
    //   fetchApi({
    //     url:       `/reports/${report.id}/rep_costs.json`,
    //     method:    'GET',
    //     callback:  updateMethods.costs,
    //     followUps: [...callbacks]
    //   })
    //   return state

    case CREATE:
      fetchApi({
        url:       `/reports/${action.report.id}/offer_lines/rep_create.json`,
        method:    'POST',
        contentType,
        body:      action.data,
        fieldset,
        callback:  data => updateMethods.addCost(data.response),
        followUps: [...callbacks, closeModal]
      })
      return state

    case UPDATE:
      fetchApi({
        url:       `/reports/${action.report.id}/offer_lines/rep_update`,
        method:    'PATCH',
        contentType,
        body:      action.data,
        fieldset,
        callback:  data => updateMethods.cost(data.response),
        followUps: [...callbacks, closeModal]
      })
      return state

    case CHANGE_STATUS:
      fetchApi({
        url:          `/offer_lines/${action.cost.id}/change_status.json`,
        method:       'PATCH',
        body:         { status: action.status },
        endAnimation: action.setAnimation,
        callback:     data => updateMethods.cost(data.response),
        followUps:    [
          ...callbacks,
          () => API.fetchReport({ costs: true }).then(updateMethods.report)
        ]
      })
      return state

    case ALL_COST_APPROVE:
      const costIds = action.report.offer_lines.filter(c => c.status === 'to_approve').map(c => c.id)
      fetchApi({
        url:       `/reports/${action.report.id}/offer_lines/rep_approve_all.json`,
        method:    'PATCH',
        body:      { cost_ids: costIds },
        followUps: [
          ...callbacks,
          () => API.fetchReport().then(updateMethods.report)
        ],
        withLoading: true
      })
      return state

    case DESTROY:
      setAnimation(action.animation)
      fetchApi({
        url:          `/reports/${action.report.id}/offer_lines/${action.cost.id}/rep_destroy.json`,
        method:       'DELETE',
        endAnimation: setAnimation,
        callback:     data => updateMethods.removeCost(data.response),
        followUps:    callbacks
      })
      return state

    default:
      if (window.env !== 'production') toast.error('Reducer action not defined', { icon: <FontAwesomeIcon icon="face-tired" />, iconTheme: { primary: 'var(--rep-danger)', secondary: 'var(--rep-danger-light)' } })
      return state
  }
}
