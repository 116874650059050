import React, { useReducer } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button          from '@components/Button'
import Form            from '@components/Form/Form'
import Input           from '@components/Form/Input'
import MeasureTypeForm from './MeasureType/Form'
import Select          from '@components/Form/Select'
import Spacer          from '@components/Spacer'
import Textarea        from '@components/Form/Textarea'

import { useGlobalContextState } from '@context/GlobalContext'

import { MEASURE_ACTIONS, measureReducer } from '@reducers/measureReducer'

import IAmenity         from '@interfaces/IAmenity.d'
import IRequestResponse from '@interfaces/IRequestResponse.d'

interface MeasureFormProps {
  equipment:     IAmenity,
  updateMethods: {
    addMeasurment: (data: IRequestResponse) => void
  }
}
const MeasureForm: React.FC<MeasureFormProps> = ({
  equipment,
  updateMethods
}) => {

  const { i18n, fetchApi, closeModal, current_company, setInfoWindowProps } = useGlobalContextState()

  const [_measureState, dispatch]   = useReducer(measureReducer, { fetchApi, updateMethods })

  const createCounter = (data, fieldset) => dispatch({
    type:      MEASURE_ACTIONS.ADD_MEASURE,
    data,
    fieldset,
    callbacks: [closeModal]
  })

  const createMeasureType = (data) => {
    dispatch({
      type:      MEASURE_ACTIONS.CREATE_TYPE,
      data,
      callbacks: [() => setInfoWindowProps({ item: null, type: 'form', target: 'modal' })]
    })
  }

  return (
    <Form callback={createCounter}>

      <Spacer />
      <Input
        type         = "hidden"
        name         = "amenity_id"
        defaultValue = {equipment?.id}
      />

      <Select
        name             = 'measurment_type'
        defaultValue     = {[]}
        label            = {i18n.t('measurment_type.measurment_type')}
        searchUrl        = '/measurment_types/search'
        format           = {{ content: 'name', value: 'id', details: 'value_type' }}
        detailsLocaleKey = 'measurment.types'
        placeholder      = {i18n.t('measurment_type.measurment_type')}
        search
        required
      />
      {current_company.beta_access &&
      <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
        <Button
          icon    = {<FontAwesomeIcon icon='plus' />}
          color   = 'var(--rep-success)'
          click   = {() => setInfoWindowProps({
            item:   <MeasureTypeForm create={createMeasureType} />,
            type:   'form',
            target: 'modal',
            title:  i18n.t('measurment_type.actions.create')
          })}
          marginY = 'S'
        >
          Créer un nouveau type
        </Button>
      </div>
      }

      <Spacer />

      <Input
        type  = 'text'
        name  = 'name'
        label = {i18n.t('shared.name')}
      />

      <Textarea
        name    = "description"
        label   = {i18n.t('shared.description')}
        marginY = 'M'
      />
    </Form>
  )
}

export default MeasureForm
