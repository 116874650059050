import React from 'react'
import toast from 'react-hot-toast'

const FETCH_TEMPLATE            = 'FORM_FETCH_TEMPLATE'
const CREATE_TEMPLATE           = 'FORM_CREATE_TEMPLATE'
const FETCH_REPLY               = 'FORM_FETCH_REPLY'
const ANSWER_QUESTION           = 'FORM_ANSWER_QUESTION'

export const FORM_ACTIONS = {
  FETCH_TEMPLATE:  FETCH_TEMPLATE,
  CREATE_TEMPLATE: CREATE_TEMPLATE,
  FETCH_REPLY:     FETCH_REPLY,
  ANSWER_QUESTION: ANSWER_QUESTION,
}

export function formReducer(state, action) {

  const { API, fetchApi, setAnimation, updateMethods, closeModal } = state

  const callbacks   = action.callbacks || []
  const fieldset    = action.fieldset
  const contentType = action.contentType ? action.contentType : 'application/json'

  // Will initialize the fetch functions with the updators to refresh data
  switch (action.type) {
    case FETCH_TEMPLATE:
      fetchApi({
        url:       `/form_templates/${action.template_id}/rep_show`,
        // filters:   { questions: !!action.filters?.questions },
        followUps: callbacks
      })
      return state

    case CREATE_TEMPLATE:
      fetchApi({
        type:    REPORT_ACTIONS.CREATE,
        filters: filterString,
        report:  report,
      })
      return state

    case FETCH_REPLY:
      fetchApi({
        url:       `/form_replies/${action.reply.id}`,
        followUps: callbacks
      })
      return state

    case ANSWER_QUESTION:
      fetchApi({
        url:       `/form_replies/${action.reply.id}/answer.json`,
        method:    'PATCH',
        body:      JSON.stringify({ answer: action.answer }),
        // callback:  data => updateMethods.form(data.response, action.task),
        followUps: [...callbacks]
      })
      return state
  }
}
