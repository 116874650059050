import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['totalQuantity']

  updateTotal(e) {
    const difference      = e.currentTarget.value
    const currentQuantity = this.totalQuantityTarget.dataset.currentQuantity

    // Rounding to hide precision errors
    this.totalQuantityTarget.innerText = Math.round((parseFloat(currentQuantity) + parseFloat(difference)) * 100) / 100
  }

}
