import React, { useCallback, useEffect, useState } from 'react'

import Button   from '@components/Button'
import Form     from '@components/Form/Form'
import Input    from '@components/Form/Input'
import Select   from '@components/Form/Select'
import Spacer   from '@components/Spacer'
import Textarea from '@components/Form/Textarea'

import { useGlobalContextState } from '@context/GlobalContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MultiselectItem from '@components/Form/Select/MultiselectItem'

import * as FormStyle from '@form/FormStyles'

interface MeasureTypeFormProps {
  measureType: IMeasureType,
  create:      (any) => void
}

const MeasureTypeForm: React.FC<MeasureTypeFormProps> = ({ measureType, create }) => {

  const { i18n } = useGlobalContextState()

  const [showRule, setShowRule] = useState(false)
  const [rules,    setRules]    = useState(measureType?.rules || [])
  const [type,     setType]     = useState(measureType?.type || '')

  const [ruleType,            setRuleType]            = useState('')
  const [ruleName,            setRuleName]            = useState('')
  const [ruleDesc,            setRuleDesc]            = useState('')
  const [ruleLimit,           setRuleLimit]           = useState(null)
  const [ruleMargin,          setRuleMargin]          = useState(null)
  const [ruleTaskType,        setRuleTaskType]        = useState('')
  const [ruleTaskTitle,       setRuleTaskTitle]       = useState('')
  const [ruleTaskDesc,        setRuleTaskDesc]        = useState('')
  const [ruleMaintenancePlan, setRuleMaintenancePlan] = useState(null)

  const uniqueId = useCallback(() => window.btoa(Math.random().toString()).substring(10, 15), [rules])

  useEffect(() => {
    setRuleTaskTitle('')
    setRuleTaskDesc('')
    setRuleMaintenancePlan(null)
  }, [ruleTaskType])

  const saveRule = () => {
    const rule = {
      id:                  uniqueId(),
      name:                ruleName,
      description:         ruleDesc,
      limit:               ruleLimit,
      margin:              ruleMargin,
      task_type:           ruleTaskType.value,
      rule_type:           ruleType.value,
      task_title:          ruleTaskTitle,
      task_description:    ruleTaskDesc,
      maintenance_plan_id: ruleMaintenancePlan?.id
    }

    setRules([...rules, rule])
    setRuleType('')
    setRuleName('')
    setRuleDesc('')
    setRuleLimit(null)
    setRuleMargin(null)
    setRuleTaskType('')
    setShowRule(false)
    setRuleTaskTitle('')
    setRuleTaskDesc('')
    setRuleMaintenancePlan(null)
  }

  return (
    <Form
      hideSumbit={showRule}
      callback={create}
      // addFormData={addFormData}
    >
      <Spacer />

      <Input
        type  = 'text'
        name  = 'name'
        label = {i18n.t('shared.name')}
        required
      />

      <Textarea
        name    = "description"
        label   = {i18n.t('shared.description')}
        marginY = 'M'
      />

      <Select
        name             = 'value_type'
        options          = {[
          { content: i18n.t('measurment_type.value_types.number'),    value: 'number'   },
          { content: i18n.t('measurment_type.value_types.free_text'), value: 'free_text'   },
          { content: i18n.t('measurment_type.value_types.location'),  value: 'location' }
        ]}
        defaultValue     = {[type]}
        callback         = {setType}
        label            = {i18n.t('measurment_type.value_type')}
        required
      />

      {rules.map((rule, key) =>
        <MultiselectItem
          key          = {key}
          name         = {rule.task_type === 'send_email' ?
            <><FontAwesomeIcon icon={'envelope'} /> {i18n.t('measurment_type.task_types.send_email')} </>
            : rule.task_type === 'report' ?
              <><FontAwesomeIcon icon="ticket" /> {i18n.t('measurment_type.task_types.report')} </> :
              rule.task_type === 'maintenance' ?
                <><FontAwesomeIcon icon="envelopes-bulk" /> {i18n.t('measurment_type.task_types.maintenance')} </> :
                <div></div>}
          removeAction = {() => setRules(rules.filter(r => r.id !== rule.id))}
        >
          <div style={{ fontWeight: 'bold' }}>{rule.name}</div>
          <Spacer size='s' />
          <div>{rule.description}</div>
          <Spacer size='s' />

          {rule.rule_type === 'bigger_than' ?
            i18n.t('measurment_type.rule_types_long.bigger_than', { limit: rule.limit, margin: rule.margin ? i18n.t('measurment_type.rule_types_long.minus_margin', { margin: rule.margin }) : '' })
            : rule.rule_type === 'smaller_than' ?
              i18n.t('measurment_type.rule_types_long.smaller_than', { limit: rule.limit, margin: rule.margin ? i18n.t('measurment_type.rule_types_long.minus_margin', { margin: rule.margin }) : '' })
              : rule.rule_type === 'difference_from_previous' ?
                i18n.t('measurment_type.rule_types_long.difference_from_previous', { limit: rule.limit, margin: rule.margin ? i18n.t('measurment_type.rule_types_long.minus_margin', { margin: rule.margin }) : '' })
                : rule.rule_type === 'fixed_steps' ?
                  i18n.t('measurment_type.rule_types_long.fixed_steps', { limit: rule.limit, margin: rule.margin ? i18n.t('measurment_type.rule_types_long.minus_margin', { margin: rule.margin }) : '' })
                  :<div></div>}

          <Input type="hidden" name={`rules[${key}].name`}                defaultValue={rule.name} />
          <Input type="hidden" name={`rules[${key}].description`}         defaultValue={rule.description} />
          <Input type="hidden" name={`rules[${key}].limit`}               defaultValue={rule.limit} />
          <Input type="hidden" name={`rules[${key}].margin`}              defaultValue={rule.margin} />
          <Input type="hidden" name={`rules[${key}].task_type`}           defaultValue={rule.task_type} />
          <Input type="hidden" name={`rules[${key}].rule_type`}           defaultValue={rule.rule_type} />
          <Input type="hidden" name={`rules[${key}].task_title`}          defaultValue={rule.task_title} />
          <Input type="hidden" name={`rules[${key}].task_description`}    defaultValue={rule.task_description} />
          <Input type="hidden" name={`rules[${key}].maintenance_plan_id`} defaultValue={rule.maintenance_plan_id} />
        </MultiselectItem>
      )}

      {type.value === 'number' &&
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              marginY = 'M'
              icon    = {<FontAwesomeIcon icon="circle-plus" />}
              color   = 'var(--rep-primary)'
              click   = {() => setShowRule(!showRule)}
            >
              {i18n.t('measurment_type.actions.add_rule')}
            </Button>
          </div>

          {showRule &&
            <div>
              <FormStyle.Header>
                <FontAwesomeIcon icon="circle-info" />
                {i18n.t('shared.information')}
              </FormStyle.Header>
              <Input
                type         = 'text'
                name         = 'name'
                label        = {i18n.t('shared.name')}
                defaultValue = {ruleName}
                change       = {e => setRuleName(e.currentTarget.value)}
                marginY      = 'M'
              />

              <Textarea
                name         = "description"
                label        = {i18n.t('shared.description')}
                defaultValue = {ruleDesc}
                change       = {e => setRuleDesc(e.currentTarget.value)}
                marginY      = 'M'
              />

              <FormStyle.Header>
                <FontAwesomeIcon icon="gauge" />
                {i18n.t('measurment_type.limits')}
              </FormStyle.Header>

              <Select
                name         = 'rule_type'
                options      = {[
                  { content: i18n.t('measurment_type.rule_types_desc.bigger_than'), value: 'bigger_than' },
                  { content: i18n.t('measurment_type.rule_types_desc.smaller_than'), value: 'smaller_than' },
                  { content: i18n.t('measurment_type.rule_types_desc.difference_from_previous'), value: 'difference_from_previous' },
                  { content: i18n.t('measurment_type.rule_types_desc.fixed_steps'), value: 'fixed_steps' }
                ]}
                defaultValue = {[ruleType]}
                label        = {i18n.t('measurment_type.condition')}
                callback     = {setRuleType}
                required
              />

              <FormStyle.Group marginY='M'>
                <Input
                  type         = "number"
                  name         = 'limit'
                  defaultValue = {ruleLimit}
                  change       = {e => setRuleLimit(Number.parseInt(e.currentTarget.value, 10))}
                  label        = {i18n.t('measurment_type.limit')}
                />

                <Input
                  type         = "number"
                  name         = 'margin'
                  defaultValue = {ruleMargin}
                  change       = {e => setRuleMargin(Number.parseInt(e.currentTarget.value, 10))}
                  label        = {i18n.t('measurment_type.margin')}
                />
              </FormStyle.Group>

              <Spacer size='l'/>

              <FormStyle.Header>
                <FontAwesomeIcon icon="rocket" />
                {i18n.t('measurment_type.event')}
              </FormStyle.Header>
              <Select
                label            = {i18n.t('measurment_type.event_type')}
                name             = 'task_type'
                options          = {[
                  { content: i18n.t('measurment_type.task_type_long.send_email'), value: 'send_email' },
                  { content: i18n.t('measurment_type.task_type_long.report'), value: 'report' },
                  { content: i18n.t('measurment_type.task_type_long.maintenance'), value: 'maintenance' }
                ]}
                defaultValue     = {[ruleTaskType]}
                placeholder      = {i18n.t('measurment_type.value_type')}
                callback         = {setRuleTaskType}
                marginY          = 'M'
                required
              />

              {ruleTaskType.value === 'report' &&
                <>
                  <FormStyle.Group align='center'>
                    <FontAwesomeIcon icon="arrow-turn-up" rotation={90} color="var(--rep-neutral-primary)" />
                    <Input
                      type         = 'text'
                      name         = 'task_title'
                      label        = {i18n.t('report.title_full_text')}
                      defaultValue = {ruleTaskTitle}
                      change       = {e => setRuleTaskTitle(e.currentTarget.value)}
                      marginY      = 'S'
                    />
                  </FormStyle.Group>

                  <FormStyle.Group align='center'>
                    <FontAwesomeIcon icon="arrow-turn-up" rotation={90} color="var(--rep-neutral-primary)" />
                    <Textarea
                      name         = "task_description"
                      label        = {i18n.t('shared.description')}
                      defaultValue = {ruleTaskDesc}
                      change       = {e => setRuleTaskDesc(e.currentTarget.value)}
                      marginY      = 'S'
                    />
                  </FormStyle.Group>
                </>
              }

              {ruleTaskType.value === 'maintenance' &&
                <FormStyle.Group align='center'>
                  <FontAwesomeIcon icon="arrow-turn-up" rotation={90} color="var(--rep-neutral-primary)" />
                  <Select
                    name             = 'maintenance_plan_id'
                    label            = {i18n.t('maintenance.maintenance_plan')}
                    marginY          = 'S'
                    defaultValue     = {[{ name: ruleMaintenancePlan?.name, value: ruleMaintenancePlan?.id }]}
                    callback         = {selected => setRuleMaintenancePlan(selected.object)}
                    emptyCallback    = {selected => setRuleMaintenancePlan(selected.object)}
                    searchUrl        = '/maintenance_plans/search'
                    format           = {{ content: 'name', value: 'id'}}
                    tooltip          = {i18n.t('measurment_type.tooltip.maintenance_plan')}
                    search
                    withEmpty
                  />
                </FormStyle.Group>
              }

              <Button
                background = "var(--rep-success-light)"
                color      = "var(--rep-success)"
                size       = "L"
                align      = 'center'
                marginY    = 'M'
                click      = {saveRule}
                fullWidth
              >
                {i18n.t('measurment_type.actions.save_rule')}
              </Button>
            </div>
          }
        </>
      }

      <Spacer />
    </Form>
  )
}

export default MeasureTypeForm
