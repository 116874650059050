import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Accordion     from '@components/Accordion'
import Avatar        from '@components/Avatar'
import Button        from '@components/Button'
import Callout       from '@components/Callout'
import Checkbox      from '@components/Form/Checkbox'
import DatePicker    from '@components/Form/DatePicker'
import FileUploader  from '@components/Form/FileUploader'
import Input         from '@form/Input'
import Select        from '@components/Form/Select'
// import Tag           from '@components/Tag'
import ThumbnailGrid from '@components/Form/FileUploader/ThumbnailGrid'

import * as Style from './style'

import FormAnswerProps from './types.d'

import { useGlobalContextState } from '@context/GlobalContext'

import { FORM_ACTIONS, formReducer } from '@reducers/formReducer'
import Tag from '@components/Tag'
import WithTooltip from '@components/WithTooltip'

/** @description FormAnswer component. Display the current answer and updates it */
const FormAnswer: React.FC<FormAnswerProps> = ({
  form,
  question,
  fetchReply,
  setForm,
  updateMethods,
  openQuestion
  // answerQuestion,
}) => {

  const { i18n, CONSTANTS, fetchApi } = useGlobalContextState()
  const { FORM_ANSWER }                = CONSTANTS

  const [_formState, formDispatch] = useReducer(formReducer, { fetchApi, updateMethods })

  // const [question,  setquestion]  = useState(question)
  const [documents,        setDocuments]        = useState(question.documents || [])
  const [showCommentField, setShowCommentField] = useState(!!question.comment || question.comment_field === FORM_ANSWER.FIELDS.MANDATORY)
  // const [value,            setValue]            = useState(question.answer)
  const [commentTimeoutId, setCommentTimeoutId] = useState(null)
  const [inputTimeoutId,   setInputTimeoutId]   = useState(null)
  const [open, setOpen] = useState(question.comment_field === FORM_ANSWER.FIELDS.MANDATORY && !question.comment)

  useEffect(() => {
    setOpen(openQuestion)
  }, [openQuestion])

  const answerQuestion = (answer, reply, callbacks) => formDispatch({
    type: FORM_ACTIONS.ANSWER_QUESTION,
    answer,
    reply,
    // task,
    callbacks
  })
  // const inputRef = useRef(false)

  const handleInput = (event, timeoutState, setTimeoutState, callback) => {
    if (timeoutState) {
      clearTimeout(timeoutState)
      setTimeoutState(setTimeout(() => {
        callback(event.target.value)
      }, 1000))
    } else {
      setTimeoutState(setTimeout(() => null, 1000))
      callback(event.target.value)
    }
  }

  // useEffect(() => {
  //   console.log('value', value)
  //   console.log('input ref', inputRef.current)
  //   if (inputRef?.current) {
  //     !!submitForm && submitForm(value)
  //   }
  //   inputRef.current = true
  // }, [value])

  // const updateQuestion = (data) => {
  //   const updateQuestion = data.questions.find(q => q.id === question.id)
  //   // setquestion(updateQuestion)
  // }

  const updateDocumentMethods = {
    addDocument: data => {
      setDocuments(documents => [...documents, data])
      setOpen(true)
      fetchReply(form)
    },
    removeDocument: data => {
      setDocuments(documents => documents.filter(d => d.id !== data.id))
      fetchReply(form)
    }
  }

  const answerFn = (value) => {
    switch(question.answer_type) {
      case FORM_ANSWER.TYPES.DATE:
        if (value === (question.answer && !question.not_applicable ? new Date(question.answer) : null)) return
        submitForm(value)
        break
      case FORM_ANSWER.TYPES.DATE_TIME:
        if (value === (question.answer && !question.not_applicable ? new Date(question.answer) : null)) return
        submitForm(value)
        break
      default:
        submitForm(value)
    }
  }

  const submitForm = value => {
    if (!form.permissions.update_answer)  return
    if (question.answer === FORM_ANSWER.NA) return
    if ((question.answer === value) || (question.answer === '' && value === null)) return

    const newAnswer = { ...question, answer: value }
    answerQuestion(newAnswer, form, [data => setForm(prev => ({...prev, questions: prev.questions.map(q => q.id === question.id ? data.response : q) })), () => setOpen(false), () => fetchReply(form)])
  }

  const submitComment = value => {
    if (!form.permissions.update_answer)  return
    if (question.comment === value) return

    const newAnswer = { ...question, comment: value }
    answerQuestion(newAnswer, form, [data => setForm(prev => ({...prev, questions: prev.questions.map(q => q.id === question.id ? data.response : q) })), () => fetchReply(form)])
  }

  const submitNonApplicable = () => {
    if (!form.permissions.update_answer) return

    const newAnswer = { ...question, answer: question.answer === FORM_ANSWER.NA ? '' : FORM_ANSWER.NA }
    answerQuestion(newAnswer, form, [data => setForm(prev => ({...prev, questions: prev.questions.map(q => q.id === question.id ? data.response : q) })), () => fetchReply(form)])
  }

  const answerTypeTag = useMemo(() => {
    switch(question.answer_type) {
      case FORM_ANSWER.TYPES.BOOLEAN:
        return ['far', 'question-circle']
        break
      case FORM_ANSWER.TYPES.DATE:
        return ['far', 'calendar']
        break
      case FORM_ANSWER.TYPES.DATE_TIME:
        return ['far', 'clock']
        break
      case FORM_ANSWER.TYPES.NUMBER:
        return 'hashtag'
        break
      case FORM_ANSWER.TYPES.SELECT:
        return 'list'
        break
      case FORM_ANSWER.TYPES.TEXT:
        return 'font'
        break
    }
  }, [question])

  const answerParsed = useMemo(() => {
    switch(question.answer_type) {
      case FORM_ANSWER.TYPES.DATE:
        return question.answer && question.answer !== FORM_ANSWER.NA ? ` : ${new Date(question.answer).toLocaleDateString(i18n.locale)}` : null
      case FORM_ANSWER.TYPES.DATE_TIME:
        return question.answer && question.answer !== FORM_ANSWER.NA ? ` : ${new Date(question.answer).toLocaleDateString(i18n.locale)} ${new Date(question.answer).toLocaleTimeString(i18n.locale, { hour: '2-digit', minute: '2-digit' })}` : null
      case FORM_ANSWER.TYPES.BOOLEAN:
        return null
      default:
        return question.answer && question.answer !== FORM_ANSWER.NA ? ` : ${question.answer}`: null
    }
  }, [question])

  const noToggleRef = useRef(null)

  const questionComponent = useMemo(() => {
    switch (question.answer_type) {
      case FORM_ANSWER.TYPES.BOOLEAN:
        return <Checkbox
          name         = {`question-${question.id}`}
          label        = {question.question}
          forwardRef   = {noToggleRef}
          marginY      = 'M'
          callback     = {response => answerFn(response.isCheck)}
          defaultValue = {question.answer === FORM_ANSWER.NA ? false : question.answer}
          disabled     = {question.answer === FORM_ANSWER.NA || !form.permissions.update_answer}
          color        = 'var(--rep-primary)'
          markColor    = 'var(--rep-primary)'
          background   = 'var(--rep-primary-light)'
          border       = 'var(--rep-primary)'
        />
        break

      case FORM_ANSWER.TYPES.TEXT:
        return <Input
          type         = "text"
          name         = {`question-${question.id}`}
          forwardRef   = {noToggleRef}
          marginY      = 'M'
          blur         = {event => handleInput(event, inputTimeoutId, setInputTimeoutId, answerFn)}
          defaultValue = {question.answer === FORM_ANSWER.NA ? null : question.answer}
          disabled     = {question.answer === FORM_ANSWER.NA || !form.permissions.update_answer}
          placeholder  = {i18n.t('form.answer')}
          marginY      = 'S'
        />
        break

      case FORM_ANSWER.TYPES.NUMBER:
        return <Input
          type         = "number"
          name         = {`question-${question.id}`}
          forwardRef   = {noToggleRef}
          marginY      = 'M'
          blur         = {event => handleInput(event, inputTimeoutId, setInputTimeoutId, answerFn)}
          defaultValue = {question.answer === FORM_ANSWER.NA ? '' : question.answer}
          disabled     = {question.answer === FORM_ANSWER.NA || !form.permissions.update_answer}
          placeholder  = {i18n.t('form.answer')}
        />
        break

      case FORM_ANSWER.TYPES.SELECT:
        return <Select
          name          = {`question-${question.id}`}
          forwardRef    = {noToggleRef}
          marginY       = 'M'
          placeholder   = {i18n.t('form.actions.select_answer')}
          callback      = {(response) => answerFn(response.object.value)}
          emptyCallback = {(_response) => answerFn(null)}
          defaultValue  = {question.answer ? question.answer === FORM_ANSWER.NA ? [null] : [{ content: question.answer, value: question.answer }] : []}
          disabled      = {question.answer === FORM_ANSWER.NA || !form.permissions.update_answer}
          format        = {{ content: 'content', value: 'value' }}
          options       = {question.answer_options.map((option) => ({ content: option, value: option }))}
          marginY       = 'L'
          withEmpty
        />
        break

      case FORM_ANSWER.TYPES.DATE:
        return <DatePicker
          name       = {`question-${question.id}`}
          forwardRef = {noToggleRef}
          date       = {question.answer === FORM_ANSWER.NA ? null : question.answer}
          callback   = {response => answerFn(response)}
          disabled   = {question.answer === FORM_ANSWER.NA || !form.permissions.update_answer}
          marginY    = 'M'
          inline
          allowClear
        />
        break

      case FORM_ANSWER.TYPES.DATE_TIME:
        return <DatePicker
          name         = {`question-${question.id}`}
          callback     = {response => answerFn(response)}
          date         = {question.answer === FORM_ANSWER.NA ? null : question.answer}
          forwardRef   = {noToggleRef}
          marginY      = 'M'
          disabled     = {question.answer === FORM_ANSWER.NA || !form.permissions.update_answer}
          timeAsSelect = {false}
          inline
          allowClear
          showTime
        />
        break
    }
  }, [question])

  return (
    <div style={{ width: '100%', margin: '0px 0 24px 0' }}>
      <Accordion
        togglable   = {!!documents.length || question.complete}
        marginY     = 'S'
        open        = {open}
        noToggleRef = {noToggleRef}
        header      = {
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'space-between', flexGrow: '1', width: '100%' }}>
              <div style={{ fontWeight: 'bold', fontSize: '1rem', margin: '4px 0' }}>
                {/* <Tag
                  icon           = {<FontAwesomeIcon icon={question.answer === FORM_ANSWER.NA ? 'eye-slash' : question.complete ? 'check' : answerTypeTag} />}
                  tooltipTitle   = {i18n.t('custom_field.reply_type')}
                  tooltipContent = {i18n.t(`form_reply.types.${question.answer_type}`)}
                  color          = {question.answer === FORM_ANSWER.NA ? 'var(--rep-neutral)' : question.complete ? 'var(--rep-success)' : 'var(--rep-black)'}
                > */}
                <div color={question.answer === FORM_ANSWER.NA ? 'var(--rep-neutral)' : question.complete ? 'var(--rep-success)' : 'var(--rep-black)'}>
                  <WithTooltip content={i18n.t(`form_reply.types.${question.answer_type}`)}>
                    {<FontAwesomeIcon icon={question.answer === FORM_ANSWER.NA ? 'eye-slash' : question.complete ? 'check' : answerTypeTag} />}
                  </WithTooltip> {question.question}
                  {!!question.answer && <strong>{answerParsed}</strong>}
                </div>
                {/* </Tag> */}
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                {/* {isDesktop && question.photo_field === FORM_ANSWER.FIELDS.MANDATORY && documents.length === 0 &&
                  <Tag
                    color   = "var(--rep-warning)"
                    icon    = {<FontAwesomeIcon icon="exclamation-triangle" />}
                    marginY = "S"
                  >
                    {i18n.t('form.tooltip.photo.mandatory')}
                  </Tag>
                } */}
                {question.comment_field === FORM_ANSWER.FIELDS.MANDATORY && !question.comment &&
                  <Tag
                    color          = "var(--rep-warning)"
                    background     = "var(--rep-warning-light)"
                    icon           = {<FontAwesomeIcon icon='comment-medical' />}
                    marginY        = "S"
                    tooltipContent = {i18n.t('form.tooltip.comment.mandatory')}
                  />
                }
                {question.photo_field === FORM_ANSWER.FIELDS.MANDATORY && documents.length === 0 &&
                  <Tag
                    color          = "var(--rep-warning)"
                    background     = "var(--rep-warning-light)"
                    icon           = {<FontAwesomeIcon icon={['far', 'image']} />}
                    marginY        = "S"
                    tooltipContent = {i18n.t('form.tooltip.photo.mandatory')}
                  />
                }
                {!!question.answer && !!question.user &&
                  <Avatar
                    firstName      = {question.user.first_name}
                    lastName       = {question.user.last_name}
                    tooltipContent = {i18n.t('form_reply.answered_by_on', { user: `${question.user.first_name} ${question.user.last_name}`, date: new Date(question.answered_at).toLocaleDateString(i18n.locale) })}
                  />
                }
              </div>
            </div>
            {!question.not_applicable && !question.complete && <hr style={{ width: '100%', border: '1px solid var(--rep-primary-light)', margin: '8px 0' }} />}
            {!question.not_applicable && !question.complete && questionComponent}
            {!question.answer && !!question.hint && !question.not_applicable &&
            <Callout
              background = 'var(--rep-neutral-light)'
              border     = 'none'
              color      = 'var(--rep-neutral-primary)'
              icon       = {<FontAwesomeIcon icon="question-circle" />}
              marginY    = 'M'
            >
              {question.hint}
            </Callout>
            }
          </div>
        }
        colorHeader = {question.answer === FORM_ANSWER.NA ? 'var(--rep-neutral)'        : question.complete ? 'var(--rep-success)' : 'var(--rep-neutral-primary)'}
        border      = {question.answer === FORM_ANSWER.NA ? 'var(--rep-neutral-middle)' : question.complete ? 'var(--rep-success-middle)': 'var(--rep-primary-light)'}
      >
        <Style.QuestionContainer>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'space-between', flexGrow: '1' }}>
            {/* <Style.Question
            color  = {question.complete ? 'var(--rep-success)' : 'var(--rep-neutral)'}
            border = {question.complete ? 'var(--rep-success-middle)' : question.answer === FORM_ANSWER.NA ? 'var(--rep-neutral-middle)' : 'transparent'}
          >
            {question.question}
          </Style.Question> */}

          </div>
          {/* {questionComponent} */}

          {/* <Style.Container
        // notApplicable = {question.answer === FORM_ANSWER.NA}
        onSubmit      = {e => e.preventDefault()}
        valid         = {question.complete}
      > */}
          {!question.not_applicable && question.complete && questionComponent}

          {question.answer && !!question.hint && !question.not_applicable &&
            <Callout
              background = 'var(--rep-neutral-light)'
              border     = 'none'
              color      = 'var(--rep-neutral-primary)'
              icon       = {<FontAwesomeIcon icon="question-circle" />}
              marginY    = 'M'
            >
              {question.hint}
            </Callout>
          }
          {question.photo_field !== FORM_ANSWER.FIELDS.HIDDEN && documents.length > 0 &&
            <ThumbnailGrid
              destroyable      = {true}
              files            = {documents}
              gridSize         = {4}
              thumbnailDestroy = {true}
              updateMethods    = {updateDocumentMethods}
            />
          }
        </Style.QuestionContainer>
      </Accordion>
      {showCommentField &&
        <Input
          blur         = {event => handleInput(event, commentTimeoutId, setCommentTimeoutId, submitComment)}
          defaultValue = {question.comment}
          disabled     = {question.answer === FORM_ANSWER.NA || !form.permissions.update_answer}
          label        = {i18n.t('form_reply.comment')}
          marginY      = 'M'
          name         = "comment"
          required     = {question.comment_field === FORM_ANSWER.FIELDS.MANDATORY}
          type         = 'text'
        />
      }
      <Style.BtnContainer>
        {question.na_field !== FORM_ANSWER.FIELDS.HIDDEN &&
            <Button
              icon  = {<FontAwesomeIcon icon="eye-slash" />}
              click = {submitNonApplicable}
              hover = 'var(--rep-primary-light)'
              background = {question.answer === FORM_ANSWER.NA && 'var(--rep-neutral-light)'}
              border     = {question.answer === FORM_ANSWER.NA && 'var(--rep-neutral-middle)'}
              color      = {question.answer === FORM_ANSWER.NA && 'var(--rep-neutral-primary)'}
            >
              {i18n.t('form_reply.not_applicable')}
            </Button>
        }
        {question.comment_field === FORM_ANSWER.FIELDS.OPTIONAL &&
          <Button
            icon  = {<FontAwesomeIcon icon="comment-medical" />}
            color = {(question.comment_field === FORM_ANSWER.FIELDS.MANDATORY && !question.comment) ? 'var(--rep-warning)' : 'var(--rep-primary)'}
            click = {() => setShowCommentField(!showCommentField)}
            hover = {(question.comment_field === FORM_ANSWER.FIELDS.MANDATORY && !question.comment) ? 'var(--rep-warning-light)' : 'var(--rep-primary-light)'}
          >
            {i18n.t('form.comment')}
          </Button>
        }
        {question.photo_field !== FORM_ANSWER.FIELDS.HIDDEN &&
          <FileUploader
            asDropZone      = {false}
            closeModalAfter = {false}
            files           = {documents}
            name            = 'document'
            objectId        = {question.id}
            objectType      = 'form_question_reply'
            updateMethods   = {updateDocumentMethods}
            color           = {(question.photo_field === FORM_ANSWER.FIELDS.MANDATORY && !documents.length) ? 'var(--rep-warning)' : 'var(--rep-primary)'}
            hover           = {(question.photo_field === FORM_ANSWER.FIELDS.MANDATORY && !documents.length) ? 'var(--rep-warning-light)' : 'var(--rep-primary-light)'}
            destroyable
            thumbnailDestroy
          />
        }
      </Style.BtnContainer>
    </div>
  )
}

export default FormAnswer
