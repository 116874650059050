import styled from 'styled-components'

export const InfoWindow = styled.div`
  /* display:        ${props => props.displayed ? 'block' : 'none'}; */
  position:       ${props => props.isDesktop ? 'initial' : props.displayed? 'fixed' : 'block' };
  top:            ${props => !props.isDesktop ? 'unset' : 0 };
  height:         calc(99vh - 4px);
  margin-top:     ${props => props.isDesktop ? '1vh' : '0'};
  margin-right:   8px;
  border:         ${props => props.displayed ? '1px solid var(--rep-neutral-primary-light)' : 'none'};
  overflow-y:     scroll;
  /* flex:           1 0 ${props => props.displayed ? (!props.isDesktop ? '100vw' : props.width ? `${props.width}px` : '33vw') : 0}; */
  flex:           1 0 ${props => props.displayed ? props.width ? `${props.width}px` : '33vw' : '0px'};
  width:          ${props => props.isDesktop ? 'initial' : '100vw'};
  box-sizing:     border-box;
  background:   white;
  transition:     width .5s ease, flex .3s ease;
  padding:        0px ${props => props.displayed ? (props.isDesktop ? '24px' : '16px') : 0};
  border-radius:  5px;
  scrollbar-color: var(--rep-neutral-middle) white;
  box-shadow:     ${props => !props.fromModal && props.displayed ? '0px 5px 5px var(--rep-neutral-primary-light)' : 'none'};
  ${props => props.fromModal &&
    `
      display:  ${props.displayed ? props.isDesktop ? 'block' : 'none' : 'none'};
      position: fixed;
      // padding:  24px;
      width:    40%;
      right:    0;
      z-index:  2600;
    `
};
${props => !props.isDesktop && props.displayed && `
  flex:       0 0 0;
  position:   absolute;
  left:       0;
  top:        0;
  z-index:    1500;
  height:     100dvh;
  transition: width 0, flex 0;
`}
`

export const InfoWindowResizer = styled.div`
  height:       100vh;
  color: var(--rep-neutral-primary-middle);
  flex:         0 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  &:hover {
    cursor: col-resize;
  }
`

export const InfoWindowOverlay = styled.div`
  display:      ${props => props.displayed ? 'block' : 'none' };
  position:     absolute;
  background: black;
  opacity:      65%;
  height:       100vh;
  width:        100vw;
  top:          0;
  left:         0;
  z-index:      2000;
  transition:   .3s ease;
`

export const InfoLine = styled.div`
  display:         flex;
  align-items:     center;
  justify-content: ${props => props.isDesktop ? 'flex-start' : 'space-between'};
  margin:          8px 0;
  color:           var(--rep-black);
  gap:             8px;
`

export const InfoLineTitle = styled.div`
  min-width:   150px;
  max-width:   ${props => props.isDesktop ? '30%' : 'unset'};
  width:       ${props => props.isDesktop ? '200px' : 'unset'};
  font-weight: 500;
  color:       var(--rep-neutral-primary);
`

export const InfoLineContent = styled.div`
  display: flex;
  justify-content: space-between;
  /* gap: 8px; */
  width: 100%;
`

export const InfoHeader = styled.div`
  position:         sticky;
  top:              0;
  background:     white;
  z-index:          100;
  /* display:          flex; */
  /* justify-content:  space-between; */
  /* padding-bottom:   4px;
  padding-top:      8px; */
  padding: 4px 0;
  /* margin: 4px 0; */
  /* border-bottom: 1px solid var(--rep-neutral-middle); */
  border-bottom: 1px solid var(--rep-neutral-primary-light);
  /* box-shadow: 2px 0 2px var(--rep-neutral-primary-light); */
`

export const InfoContent = styled.div`
  padding: 8px 0;
`

export const TitleActionsContainer = styled.div`
  display:         flex;
  gap:             8px;
  justify-content: flex-end;
  align-items:     center;
  margin:          16px 0;
  line-height:     1.3;
`

export const CloseIcon = styled.div`
  background:       var(--rep-neutral-light);
  color:            var(--rep-neutral-primary);
  width:            30px;
  height:           30px;
  /* border-radius:    15px; */
  border-radius:    4px;
  display:          flex;
  justify-content:  center;
  align-items:      center;
  align-self: center;
  &:hover {
    cursor:   pointer;
    opacity:  .8;
  }
`

export const BackIcon = styled.div`
  /* position:         absolute;
  left:             16px;
  top:              16px; */
  background:       var(--rep-neutral-light);
  color:            var(--rep-neutral);
  width:            30px;
  height:           30px;
  /* border-radius:    15px; */
  border-radius:    4px;
  display:          flex;
  justify-content:  center;
  align-items:      center;
  &:hover {
    opacity:  .8;
    cursor:   pointer;
  }
`

export const Header = styled.div`
  font-size: 1.5rem;
  color: ${props =>  props.danger ? 'var(--rep-danger)' : props.archived ? 'var(--rep-warning)' : 'var(--rep-black)'};
  font-weight: 500;
  line-height: 1em;
  margin: 8px 0;
`

export const HeaderActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: ${props => props.justify ? props.justify : 'flex-start'};
  /* padding: 8px 0; */
`

export const Subheader = styled.div`
  font-size: 1.2em;
  color: var(--rep-neutral-primary);
  padding: 8px 0;
`
export const Loader = styled.div`
  display:         flex;
  justify-content: center;
  align-items:     center;
  height:          90vh;
  font-size:       2rem;
  color:           var(--rep-neutral-primary);
`

export const TextWithFormatting = styled.p`
  white-space:     pre-line;
  max-height:      200px;
  overflow:        auto;
  scrollbar-color: var(--rep-neutral-middle) white;
`
