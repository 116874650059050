import { Controller } from '@hotwired/stimulus'
// import { FullCalender } from '@fullcalendar/core';
import { Draggable } from '@fullcalendar/interaction'
// import * as $ from 'jquery/dist/jquery'
export default class extends Controller {

  static targets = [
    'taskList',
    'pageDiv',
    'newReports',
    'location',
    'responsible',
    'expertise',
    'performer'
  ]

  get csrfToken() { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

  connect() {
    this.calendarEl = window.calendar
    this.Draggable = FullCalendar.Draggable
    new Draggable(this.newReportsTarget, {
      itemSelector: '.notif-item',
      eventData:    function (eventEl) {
        return {
          extendedProps: {
            status_color: eventEl.dataset.colorstatus,
            location:     eventEl.dataset.location,
            logo_class:   eventEl.dataset.logoclass,
            elemid:       eventEl.dataset.elemid,
            to_do_id:     eventEl.dataset.todoid,
            task_type:    eventEl.dataset.type,
            amenity:      eventEl.dataset.amenity,
            task_number:  eventEl.dataset.tasknumber,
            unitId:       eventEl.dataset.unitid,
            userId:       eventEl.dataset.userid
          },
          title: eventEl.dataset.title
        }
      }
    })
    const nav = this.pageDivTarget.querySelector('nav')
    if (nav) {
      var ul = document.createElement('ul')
      ul.classList.add('pagination')
      nav.appendChild(ul)

      this.pageDivTarget.querySelectorAll('span').forEach((span) => {
        var li = document.createElement('li')
        li.innerHTML = span.innerHTML
        ul.appendChild(li)
        if (li.querySelector('a')) {
          var link = li.querySelector('a')
          var nextPage = link.getAttribute('href').split('page=')[1]
          link.setAttribute('data-page', nextPage)
          link.removeAttribute('href')
          link.classList.add('page-link')
          link.setAttribute('data-action', 'click->fullcalendar--paginate-to-dispatch#goToPage')
          switch (link.innerText) {
            case 'first':
              link.innerHTML = `<i class="fas fa-angle-double-left" data-page=${nextPage}></i>`
              break
            case 'prev':
              link.innerHTML = `<i class="fas fa-angle-left" data-page=${nextPage}></i>`
              break
            case 'next':
              link.innerHTML = `<i class="fas fa-angle-right" data-page=${nextPage}></i>`
              break
            case 'last':
              link.innerHTML = `<i class="fas fa-angle-double-right" data-page=${nextPage}></i>`
              break
            default:
              break
          }
        }
        else {
          var link_2 = document.createElement('a')
          link_2.classList.add('page-link')
          link_2.innerHTML = li.innerHTML
          link_2.removeAttribute('href')
          li.innerHTML = ''
          li.appendChild(link_2)
        }
        li.classList.add('page-item')
        if (span.classList.contains('current')) {
          li.classList.add('active')
        }
        span.remove()
      })
    }
  }

  goToPage(event) {
    const nextPage       = event.target.getAttribute('data-page')
    const company        = this.taskListTarget.dataset.company
    const method         = this.taskListTarget.dataset.list
    const unit_id        = this.locationTarget.value
    const responsible_id = this.responsibleTarget.value
    const expertise_id   = this.expertiseTarget.value
    const performer_id   = this.performerTarget.value
    var unitText         = ''
    var responsibleText  = ''
    var expertiseText    = ''
    var performerText    = ''
    if (unit_id) {
      unitText = `&unit_id=${unit_id}`
    }
    if (responsible_id) {
      responsibleText = `&responsible_id=${responsible_id}`
    }
    if (expertise_id) {
      expertiseText = `&expertise_id=${expertise_id}`
    }
    if (performer_id) {
      performerText = `&performer_id=${performer_id}`
    }
    fetch(`/companies/${company}/${method}.json?page=${nextPage}${unitText}${responsibleText}${expertiseText}${performerText}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken
        },
        method: 'GET'
      }
    ).then(response => response.json())
      .then(data => {
        this.taskListTarget.innerHTML = ''
        data.forEach(todo => {
          var li = document.createElement('li')
          li.classList.add('list-group-item')
          li.classList.add('list-group-item-action')
          li.classList.add('notif-item')
          li.classList.add('agenda_task_list_item')
          li.dataset.type = todo.type
          li.dataset.todoid = todo.id
          li.dataset.elemid = todo.elemid
          li.dataset.location = todo.location
          li.dataset.expertise = todo.expertise_id
          li.dataset.responsible = todo.responsible_id
          li.dataset.logoclass = todo.logo_class
          li.dataset.colorstatus = todo.status_color
          li.dataset.title = todo.title
          li.dataset.amenity = todo.amenity
          li.dataset.root = todo.root_unit_id
          li.dataset.created_since = todo.created_since
          var link = document.createElement('a')
          if (todo.type == 'to_do_item') {
            link.setAttribute('href', `/openModal?div=view-task-content&modal=to_do_items%2Fedit_in_calendar&modal_id=view-task-btn&task_type=ToDoItem&to_do_item=${todo.id}`)
          }
          else if (todo.type == 'report') {
            link.setAttribute('href', `/openModal?div=view-report-content&modal=reports%2Fpartials%2Freport_modal_on_side_calendar&modal_id=view-report-btn&info=show_see_btn&report=${todo.id}`)
          }
          link.setAttribute('data-remote', 'true')
          var div_1 = document.createElement('div')
          div_1.classList.add('card_content')
          var div_2 = document.createElement('div')
          div_2.classList.add('card_title')
          div_2.innerHTML = `<i class="${todo.logo_class}" style='color: ${todo.status_color};'></i> ${todo.title}`
          var div_3 = document.createElement('div')
          div_3.classList.add('card_address', 'simple_text')
          if (todo.report_priority_name) {
            div_3.insertAdjacentHTML('beforeend', `<i class="fas fa-bell"></i> ${todo.report_priority_name} | `)
          }
          if (todo.responsible) {
            div_3.insertAdjacentHTML('beforeend', `<i class="fas fa-users-cog"></i> ${todo.responsible}`)
          }
          div_3.insertAdjacentHTML('beforeend', `<i class="far fa-calendar-alt"></i> ${todo.display_todo_date}</br>
          <i class="fas fa-map-marker-alt"></i> ${todo.location}
          | <i class="fas fa-cogs"></i> ${todo.amenity}</br>`)
          div_3.insertAdjacentHTML('beforeend', `<i class="fas fa-clock"></i> ${todo.created_since}`)

          div_1.appendChild(div_2)
          div_1.appendChild(div_3)
          link.appendChild(div_1)
          li.appendChild(link)
          this.taskListTarget.appendChild(li)
        })

        fetch(`/companies/${company}/${method}.html?page=${nextPage}${unitText}${responsibleText}${expertiseText}`,
          {
            headers: {
              'X-CSRF-Token': this.csrfToken
            },
            method: 'GET'
          })
          .then(response => response.text())
          .then(html => {
            const fragment = document
              .createRange()
              .createContextualFragment(html)
            this.pageDivTarget.innerHTML = ''
            this.pageDivTarget.appendChild(fragment)
            this.createDraggable()
            this.connect()
          })
      })
  }

  createDraggable() {
    var check = new Draggable(this.taskListTarget, {
      itemSelector: '.notif-item',
      eventData:    function (eventEl) {
        return {
          extendedProps: {
            status_color: eventEl.getAttribute('data-colorstatus'),
            location:     eventEl.getAttribute('data-location'),
            logo_class:   eventEl.getAttribute('data-logoclass'),
            todoid:       eventEl.getAttribute('data-todoid'),
            task_type:    eventEl.getAttribute('data-type'),
            amenity:      eventEl.getAttribute('data-amenity')
          },
          title: eventEl.getAttribute('data-title')
        }
      }
    })
    window.calendar.render()
  }

}
