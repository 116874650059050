import React, { useState } from 'react'

import DatePicker   from '@components/Form/DatePicker'
import FileUploader from '@components/Form/FileUploader'
import Form         from '@components/Form/Form'

import { useGlobalContextState } from '@context/GlobalContext'

import IEquipmentUsage from '@interfaces/IEquipmentUsage.d'

interface StopUsageFormProps {
  usage:     IEquipmentUsage
  stopUsage: (data: object, fieldset: React.RefObject<HTMLFieldSetElement>) => void
}

const StopUsageForm: React.FC<StopUsageFormProps> = ({ usage, stopUsage }) => {

  const { i18n } = useGlobalContextState()

  const [files, setFiles] = useState([])

  const addFormData = formData => formData.append('equipment_usage[documents]', files.map(doc => doc.id).toString())

  return(
    <Form
      callback    = {(data, fieldset) => stopUsage({ usage: usage, data: data }, fieldset)}
      addFormData = {addFormData}
    >
      <DatePicker
        name        = "equipment_usage.stop"
        date        = {new Date()}
        label       = {i18n.t('equipment_usage.stop_date')}
        minDateTime = {new Date(usage.start)}
        showTime
      />
      <FileUploader
        name            = "equipment_usage[documents-files]"
        updateMethods   = {{
          addDocument:    file => setFiles(files => [...files, file]),
          removeDocument: file => setFiles(files => files.filter(f => f != file.id))
        }}
        objectType      = "equipment_usage"
        objectId        = {usage.id}
        files           = {files}
        closeModalAfter = {false}
      />
    </Form>
  )
}

export default StopUsageForm
