import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = [
    'taskType',
    'reportFields',
    'maintenanceFields',
    'taskTitle',
    'taskDescription',
    'maintenancePlan'
  ]

  showtasktype() {
    if (this.taskTypeTarget.value === 'maintenance') {
      this.maintenanceFieldsTarget.classList.remove('hidden')
      this.reportFieldsTarget.classList.add('hidden')

      // Set required attributes
      this.taskTitleTarget.removeAttribute('required')
      this.taskDescriptionTarget.removeAttribute('required')
      this.maintenancePlanTarget.setAttribute('required', true)
    } else if (this.taskTypeTarget.value === 'report') {
      this.maintenanceFieldsTarget.classList.add('hidden')
      this.reportFieldsTarget.classList.remove('hidden')

      // Set required attributes
      this.maintenancePlanTarget.removeAttribute('required')
      this.taskTitleTarget.setAttribute('required', true)
      this.taskDescriptionTarget.setAttribute('required', true)
    } else {
      this.maintenanceFieldsTarget.classList.add('hidden')
      this.reportFieldsTarget.classList.add('hidden')

      // Set required attributes
      this.taskTitleTarget.removeAttribute('required')
      this.taskDescriptionTarget.removeAttribute('required')
      this.maintenancePlanTarget.removeAttribute('required')
    }
  }

}
