import React, { useReducer } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button     from '@components/Button'
import EndReport  from '@components/Report/EndReport'
import ReportEdit from '@components/Report/Form'

import { useGlobalContextState } from '@context/GlobalContext'
import { useReportContextState } from '@context/ReportContext'

import {
  COST_ACTIONS, REPORT_ACTIONS, TASK_ACTIONS,
  costReducer, taskReducer
} from '@reducers/index'


const ManagerActions: React.FC<{ options: object }> = ({
  options = {
    edit: true
  }
}) => {

  const {
    showModal,
    i18n,
    isDesktop,
    CONSTANTS,
    fetchApi,
    closeModal,
    initAPI,
  } = useGlobalContextState()

  const {
    report,
    updateMethods,
    reportDispatch
  } = useReportContextState()

  const { COST } = CONSTANTS

  const API = initAPI({ reportId: report.id })

  const [_costState, costDispatch] = useReducer(costReducer, { report, fetchApi, closeModal, API, updateMethods })
  const [_taskState, taskDispatch] = useReducer(taskReducer, { report, fetchApi, closeModal, API, updateMethods })

  const approveAllCosts     = () => costDispatch({ type: COST_ACTIONS.ALL_COST_APPROVE, report })
  const approveAllTasks     = () => taskDispatch({
    type:      TASK_ACTIONS.ALL_TASK_APPROVE,
    report,
    callbacks: [
      () => API.fetchCosts().then(updateMethods.costs)
    ]
  })

  const uncancelReport = () => reportDispatch({
    type:      REPORT_ACTIONS.UNCANCEL,
    report:    report,
    callbacks: [
      () => API.fetchReport().then(updateMethods.report),
      // () => API.fetchTasks().then(updateMethods.tasks),
      // () => API.fetchCosts().then(updateMethods.costs)
    ]
  })

  const openReport          = () => reportDispatch({
    type:      REPORT_ACTIONS.OPEN,
    report:    report,
    callbacks: [
      () => API.fetchReport().then(updateMethods.report),
      // () => API.fetchTasks().then(updateMethods.tasks),
      // () => API.fetchCosts().then(updateMethods.costs)
    ]
  })

  if (!report.permissions) return <div></div>

  return(
    <>
      {report.permissions.can_send_for_approval &&
        <Button
          key   = 'send_for_approval'
          icon  = {<FontAwesomeIcon icon="share-square" />}
          color = 'var(--rep-success)'
          hover = 'var(--rep-success-light)'
          click = {() => showModal({
            title:   i18n.t('report.actions.send_for_approval'),
            content: <EndReport
              step          = 'send_for_approval'
              report        = {report}
              dispatch      = {reportDispatch}
              updateMethods = {updateMethods}
            />
          })}
        >
          {i18n.t('report.actions.send_for_approval')}
        </Button>
      }

      {report.permissions.can_approve_report &&
        <Button
          key   = 'approve_report'
          color = 'var(--rep-success)'
          hover = 'var(--rep-success-light)'
          icon  = {<FontAwesomeIcon icon="check-double" />}
          click = {() => showModal({
            title:   i18n.t('report.actions.approve_report'),
            content: <EndReport
              step          = 'approve'
              report        = {report}
              dispatch      = {reportDispatch}
              updateMethods = {updateMethods}
            />
          })}
        >
          {i18n.t('report.actions.approve_report')}
        </Button>
      }

      {report.permissions.can_approve_report &&
        <Button
          key   = 'open_report'
          icon  = {<FontAwesomeIcon icon="times" />}
          click = {openReport}
          color = 'var(--rep-danger)'
          hover = 'var(--rep-danger-light)'
        >
          {i18n.t('report.actions.reject_report')}
        </Button>
      }

      {report.permissions.can_approve_and_close &&
        <Button
          key   = 'approve_close_report'
          icon  = {<FontAwesomeIcon icon="rocket" />}
          color = 'var(--rep-success)'
          hover = 'var(--rep-success-light)'
          click = {() => showModal({
            title:   i18n.t('report.actions.approve_and_close_report'),
            content: <EndReport
              step          = 'close'
              report        = {report}
              dispatch      = {reportDispatch}
              updateMethods = {updateMethods}
            />
          })}
        >
          {i18n.t('report.actions.approve_and_close_report')}
        </Button>
      }

      {report.permissions.can_task_batch_approval &&
        <Button
          key   = 'batch_approve_tasks'
          icon  = {<FontAwesomeIcon icon="check-double" />}
          click = {approveAllTasks}
          color = 'var(--rep-success)'
          hover = 'var(--rep-success-light)'
        >
          {i18n.t('report.actions.approve_all_tasks')}
        </Button>
      }

      {report.permissions.can_cost_batch_approval &&
          !!report.offer_lines.filter(c => c.status === COST.STATUS.TO_APPROVE).length &&
        <Button
          key   = 'batch_approve_costs'
          icon  = {<FontAwesomeIcon icon="check" />}
          click = {approveAllCosts}
          color = 'var(--rep-success)'
          hover = 'var(--rep-success-light)'
        >
          {i18n.t('report.actions.approve_all_costs')}
        </Button>
      }

      {report.permissions.can_close_report &&
        <Button
          key   = 'close_report'
          icon  = {<FontAwesomeIcon icon="rocket" />}
          color = 'var(--rep-success)'
          hover = 'var(--rep-success-light)'
          click = {() => showModal({
            title:   i18n.t('report.actions.close_report'),
            content: <EndReport
              step          = 'close'
              report        = {report}
              dispatch      = {reportDispatch}
              updateMethods = {updateMethods}
            />
          })}
        >
          {i18n.t('report.actions.close_report')}
        </Button>
      }


      {report.permissions.can_update_report && isDesktop && options?.edit &&
        <Button
          key    = 'edit_report'
          icon   = {<FontAwesomeIcon icon="edit" />}
          color  = 'var(--rep-primary)'
          hover  = 'var(--rep-primary-light)'
          click  = {() => showModal({
            title:   i18n.t('report.edit_report'),
            content: <ReportEdit
              report        = {report}
              updateMethods = {updateMethods}
            />
          })}
        >
          {(report.permissions.can_prefilter_report && !report.permissions.can_manage_report && !report.responsible)
            ? i18n.t('actions.assign')
            : i18n.t('actions.edit')}
        </Button>
      }

      {report.permissions.can_cancel_report &&
        <Button
          key    = 'cancel_report'
          icon   = {<FontAwesomeIcon icon="trash-alt" />}
          color  = 'var(--rep-warning)'
          hover  = 'var(--rep-warning-light)'
          click  = {() => showModal({
            title:   i18n.t('report.actions.cancel_report'),
            content: <EndReport
              step          = 'cancel'
              report        = {report}
              dispatch      = {reportDispatch}
              updateMethods = {updateMethods}
            />
          })}
        >
          {i18n.t('report.actions.cancel_report')}
        </Button>
      }

      {report.permissions.can_uncancel_report && isDesktop &&
        <Button
          icon  = {<FontAwesomeIcon icon="clock-rotate-left" />}
          color = 'var(--rep-warning)'
          hover = 'var(--rep-warning-light)'
          click = {uncancelReport}
        >
          {i18n.t('actions.reactivate')}
        </Button>
      }

      {report.permissions.can_open_report &&
        <Button
          key   = 'open_report'
          icon  = {<FontAwesomeIcon icon="folder-open" />}
          click = {openReport}
          color = 'var(--rep-primary)'
          hover = 'var(--rep-primary-light)'
        >
          {i18n.t('report.actions.open_report')}
        </Button>
      }
    </>
  )
}

export default ManagerActions
