import styled from 'styled-components'

export const Header = styled.div`
  font-size:      .7rem;
  font-weight:    500;
  color:          var(--rep-neutral-primary);
  margin:         0;
  /* background:     var(--rep-primary-light); */
  /* border-left: 4px solid var(--rep-neutral-primary-middle);
  border-bottom: 1px solid var(--rep-neutral-primary-light);
  border-top: 1px solid var(--rep-neutral-primary-light);
  border-right: 1px solid var(--rep-neutral-primary-light); */
  border-bottom: 1px dotted var(--rep-neutral-primary-middle);
  /* width: 100%; */
  user-select:    none;
  font-size: 1.1em;
  font-weight: 520;
  /* border-radius:  5px; */
  padding:        4px 0 8px 0;
  /* padding-bottom: 12px;
  padding-top: 12px; */
  text-transform: uppercase;
  /* display:        flex;
  align-items: center; */
  display:  inline-block;
  margin-top: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return 'var(--rep-spacer-s)'
    }
  }};
  margin-bottom: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return 'initial'
    }
  }};
  svg { margin-right: 4px }
`

export const Group = styled.div`
  display:         flex;
  justify-content: ${props => props.justify ? props.justify : 'space-between'};
  align-items:     ${props => props.align ? props.align : 'stretch'};
  width:           100%;
  gap:             8px;
  position:        relative;
  margin:          8px 0;
  flex-wrap:       ${props => props.wrap ? props.wrap : 'wrap'};
  margin-top: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return 'var(--rep-spacer-s)'
    }
  }};
  margin-bottom: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return 'initial'
    }
  }};
  & > label {
    position:      absolute;
    top:           -4px;
    left:          12px;
    z-index:       200;
    color:         var(--rep-neutral);
    font-size:     .7rem;
  }
`

export const Divider = styled.div`
  border-top: .5px dashed var(--rep-neutral-primary);
  width:      100%;
  margin-top: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return 'var(--rep-spacer-s)'
    }
  }};
  margin-bottom: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return 'initial'
    }
  }};
`

export const Required = styled.div`
  display:          flex;
  justify-content:  flex-end;
  color:            var(--rep-warning);
  text-transform:   uppercase;
  font-size:        8px;
  position:         absolute;
  right:            28px;
  top:              ${props => props.middle ? 0 : '-8px'};
  background:       var(--rep-warning-light);
  padding:          2px 4px;
  border-radius:    4px;
  z-index:          200;
  margin:           ${props => props.margin ? props.margin : 0};
`

export const Tooltip = styled.div`
  display:          flex;
  justify-content:  flex-end;
  color:            var(--rep-neutral);
  text-transform:   uppercase;
  font-size:        1em;
  position:         absolute;
  right:            4px;
  top:              ${props => props.middle ? 0 : '-1em'};
  background:       var(--rep-neutral-light);
  padding:          2px 4px;
  border-radius:    16px;
  z-index:          200;
  margin:           ${props => props.margin ? props.margin : 0};
  transition: .2s ease;
  &:hover {
    cursor:    pointer;
  }
`

export const UnitArrow = styled.div`
  color:           var(--rep-primary);
  display:         flex;
  justify-content: center;
`

export const Label = styled.label`
  display:        block;
  color:          var(--rep-neutral-primary);
  text-transform: uppercase;
  font-size:      .7rem;
  padding-bottom: 4px;
  border-bottom:  ${props => props.noBorderBottom ? 'none' : '.5px dashed var(--rep-neutral-primary)'};
  margin-left:    4px;
  margin-top:     ${props => props.noMarginTop ? 0 : '16px'};
`

export const DatePickerForm = styled.div`
  display:     flex;
  align-items: center;
  gap:         8px;
  flex-wrap:   wrap;
`

export const TextBlock = styled.div`
  position:      relative;
  border:        1px solid ${props => props.border ? props.border : 'var(--rep-neutral-primary-light)'};
  border-radius: 5px;
  padding:       4px 8px;
  align-self:    stretch;
  display:       flex;
  align-items:   center;
  color:         var(--rep-neutral-primary);
  width:         ${props => props.fullWidth ? '100%' : 'fit-content'};
  font-weight:   500;
  margin-top:    ${props => {
    switch (props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return 'initial'
    }
  }};
  margin-bottom: ${props => {
    switch (props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return 'initial'
    }
  }};
`

export const BorderedBox = styled.div`
  border:        1px solid var(--rep-primary-middle);
  padding:       8px;
  border-radius: 5px;
  margin:        8px 0;
`

export const Loader = styled.div`
  display:         flex;
  justify-content: center;
  padding:         16px;
  align-items:     center;
  background:      white;
  color:           var(--rep-neutral-primary);
  font-size:       1rem;
  border-bottom-left-radius:  5px;
  border-bottom-right-radius: 5px;
`

export const FloatingHeader = styled.div`
  position:    sticky;
  top:         100px;
  width:       100%;
  padding:     8px 0;
  z-index:     1000;
  background: white;
  color:       var(--rep-success);
  text-align:  right;
  font-weight: 600;
`

export const EquipmentDisplayer = styled.div`
  display:         flex;
  flex-direction:  column;
  gap:             4px;
  justify-content: center;
  align-items:     center;
  margin:          8px 0;
  padding:         16px;
  background:      ${props => props.selected ? 'var(--rep-neutral-light)' : props.required ? 'var(--rep-warning-light)' : 'var(--rep-neutral-light)'};
  color:           ${props => props.selected ? 'var(--rep-neutral)'       : props.required ? 'var(--rep-warning)'       : 'var(--rep-neutral-primary)'};
  border-radius:   4px;
  position:        relative;
  border:          1px solid transparent;
  transition:      border .3s ease;
  &:hover {
    border: 1px solid ${props => props.selected ? 'var(--rep-neutral-middle)' : props.required ? 'var(--rep-warning-middle)' : 'transparent'};
  }
`
