import styled from 'styled-components'

export const Checkbox = styled.div`
  display:     flex;
  align-items: center;
  width:       100%;
  padding:     4px;
  color:  ${props => props.disabled ? 'var(--rep-neutral)' : props.valid ? 'var(--rep-success)' : 'var(--rep-primary)'};
  &:hover { cursor: pointer; }
`

export const Check = styled.div`
  margin-right: 8px;
`

export const Container = styled.form`
  padding:     0 4px;
  /* border:      1px solid ${props => props.notApplicable ? 'var(--rep-neutral-light)' : props.valid ? 'var(--rep-success-light)' : props.notApplicable ? 'var(--rep-neutral-light)' : 'var(--rep-primary-light)'}; */
  /* border-left: 4px solid ${props => props.notApplicable ? 'varmiddlerep-neutral-light)' : props.valid ? 'var(--rep-success-middle)' : props.notApplicable ? 'var(--rep-neutral-middle)' : 'var(--rep-primary-middle)'}; */
  /* margin:      16px 0; */
  /* background:  ${props => props.notApplicable ? 'var(--rep-neutral-primary-light)' : 'none'}; */
`

// export const NA = styled.div`
//   display:         flex;
//   flex-direction:  row;
//   justify-content: center;
//   align-items:     center;
//   gap:             4px;
//   border:          1px solid ${props => props.active ? 'var(--rep-success)' : 'var(--rep-neutral)'};
//   color:           ${props => props.active ? 'var(--rep-success)' : 'var(--rep-neutral)'};
//   padding:         8px;
//   border-radius:   5px;
//   margin:          16px 0;
//   background:      white;
//   text-align:      center;
//   min-width:       50px;
//   > p     { margin: 0 }
//   &:hover { cursor: pointer }
// `

export const BtnContainer = styled.div`
  display:         flex;
  gap:             8px;
  justify-content: flex-end;
  flex-wrap:       wrap;
`

export const Question = styled.div`
  color:          ${props => props.color || 'var(--rep-neutral-primary)'};
  border:         ${props => props.border || 'none'};
  font-size:      1rem;
  /* margin-left:    4px; */
  /* padding-bottom: 4px; */
  font-weight:    500;
  margin-top:  16px;
`


export const QuestionContainer = styled.div`
  /* display:         flex; */
  /* gap:             8px; */
  /* justify-content: space-between; */
  /* align-items:     center; */
  /* margin:          8px 0; */
  /* border-bottom:          1px solid var(--rep-primary-light); */
  /* padding-bottom:         12px; */
  /* border-radius:   5px; */
  padding:         0 4px;
  /* margin: 12px 0; */
`



export const FieldOptions = styled.div`
  display        : flex;
  flex-direction : row;
  gap            : 8px;
  align-items    : center;
  justify-content: flex-start;
`


