import React, { useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon }                     from '@fortawesome/react-fontawesome'

import Button           from '@components/Button'
import Checkbox         from '@form/Checkbox'
import DatePicker       from '@form/DatePicker'
import Input            from '@form/Input'
import RecurrencyString from './RecurrencyString'
import Select           from '@form/Select'
import Switch           from '@components/Switch'
import Toggle           from '@components/Toggle'
import WithTooltip      from '@components/WithTooltip'

import * as FormStyle from '@form/FormStyles'

import { useGlobalContextState } from '@context/GlobalContext'

import { parseBoolean } from '@utils/parser'

import IMaintenancePlan, { ByWeekDays, ByWeeksDaysType, IRecurrency } from '@interfaces/IMaintenancePlan.d'

interface RecurrencyFormProps {
  data?:    IMaintenancePlan
  setData?: React.Dispatch<React.SetStateAction<IMaintenancePlan>>
  options?: boolean
  resume?:  string
}

const RecurrencyForm: React.FC<RecurrencyFormProps> = ({
  data,
  setData,
  options = true,
  resume = 'block'
}) => {

  const { i18n, CONSTANTS } = useGlobalContextState()
  const { RECURRENCY } = CONSTANTS
  const { MODES, FREQUENCIES, FIXED, STOP }= RECURRENCY

  const MONTH_MATCH = {
    1:  { content: i18n.t('calendar.months')[0][0],  value: '1' },
    2:  { content: i18n.t('calendar.months')[1][0],  value: '2' },
    3:  { content: i18n.t('calendar.months')[2][0],  value: '3' },
    4:  { content: i18n.t('calendar.months')[3][0],  value: '4' },
    5:  { content: i18n.t('calendar.months')[4][0],  value: '5' },
    6:  { content: i18n.t('calendar.months')[5][0],  value: '6' },
    7:  { content: i18n.t('calendar.months')[6][0],  value: '7' },
    8:  { content: i18n.t('calendar.months')[7][0],  value: '8' },
    9:  { content: i18n.t('calendar.months')[8][0],  value: '9' },
    10: { content: i18n.t('calendar.months')[9][0],  value: '10' },
    11: { content: i18n.t('calendar.months')[10][0], value: '11' },
    12: { content: i18n.t('calendar.months')[11][0], value: '12' },
  }

  const ITERATOR_MATCH = {
    1:    { content: i18n.t('calendar.words.first'),  value: '1' },
    2:    { content: i18n.t('calendar.words.second'), value: '2' },
    3:    { content: i18n.t('calendar.words.third'),  value: '3' },
    '-1': { content: i18n.t('calendar.words.last'),   value: '-1' }
  }

  const [rules,       setRules]       = useState<IRecurrency>({
    mode:          data.recurrence.mode          || MODES.RECURRENCY,
    dayrecurrency: data.recurrence.dayrecurrency || FIXED.FIXED_DATE,
    exclusions:    data.recurrence.exclusions    || '',
    frequency:     data.recurrence.frequency     || FREQUENCIES.DAILY,
    interval:      data.recurrence.interval      || 1,
    byweekday:     data.recurrence.byweekday     || '',
    bymonthday:    data.recurrence.bymonthday    || '',
    bymonth:       data.recurrence.bymonth       || '',
    bysetpos:      data.recurrence.bysetpos      || '',
    stopcondition: data.recurrence.stopcondition || STOP.NEVER,
    until:         data.recurrence.until         || null,
    count:         data.recurrence.count         || null,
    auto_generate: typeof parseBoolean(data.recurrence.auto_generate) === 'undefined' ? true : parseBoolean(data.recurrence.auto_generate)
  })

  const [showDetails, setShowDetails] = useState(
    rules.mode === MODES.RECURRENCY
      ? !!(rules.until || rules.count || rules.exclusions)
      : false
  )

  useEffect(() => setData(initial => ({...initial, recurrence: rules })), [rules])

  const selectMode = mode => {
    if (mode === MODES.INTERVAL) {
      setRules({...rules, mode: mode, byweekday: null, bymonthday: null, bymonth: null, bysetpos: null, stopcondition: STOP.NEVER, until: null, count: null, exclusions: '' })
    } else if (mode === MODES.RECURRENCY) {
      setRules({...rules, mode: mode, auto_generate: null })
    }
  }

  const selectRecurrencyMode = dayrecurrency => {
    if (dayrecurrency === FIXED.FIXED_DATE) {
      setRules(rules => ({...rules, dayrecurrency, bysetpos: null, byweekday: null }))
    } else if (dayrecurrency === FIXED.FIXED_DAY) {
      setRules(rules => ({...rules, dayrecurrency, bymonthday: null, byweekday: rules.byweekday || 'MO' }))
    }
  }

  const selectFrequencyMode = frequency => {
    if (frequency.value === FREQUENCIES.DAILY) {
      setRules(rules => ({...rules, frequency: frequency.value, byweekday: null, bymonthday: null, bymonth: null, bysetpos: null, }))
    } else if (frequency.value === FREQUENCIES.WEEKLY) {
      setRules(rules => ({...rules, frequency: frequency.value, byweekday: null, bymonthday: null, bymonth: null, bysetpos: null, }))
    } else if (frequency.value === FREQUENCIES.MONTHLY) {
      setRules(rules => ({...rules, frequency: frequency.value, bymonthday: null, bymonth: null }))
    } else if (frequency.value === FREQUENCIES.YEARLY) {
      setRules(rules => ({...rules, frequency: frequency.value, bymonthday: null, bymonth: null }))
    }
  }

  const frequencies = useMemo(() => [
    { content: i18n.t('calendar.words.days'),   value: FREQUENCIES.DAILY },
    { content: i18n.t('calendar.words.weeks'),  value: FREQUENCIES.WEEKLY },
    { content: i18n.t('calendar.words.months'), value: FREQUENCIES.MONTHLY },
    { content: i18n.t('calendar.words.years'),  value: FREQUENCIES.YEARLY }
  ], [i18n.locale])

  const days = useMemo(() => [
    { content: i18n.t('calendar.weekDays')[2][0], value: 'MO' },
    { content: i18n.t('calendar.weekDays')[3][0], value: 'TU' },
    { content: i18n.t('calendar.weekDays')[4][0], value: 'WE' },
    { content: i18n.t('calendar.weekDays')[5][0], value: 'TH' },
    { content: i18n.t('calendar.weekDays')[6][0], value: 'FR' },
    { content: i18n.t('calendar.weekDays')[0][0], value: 'SA' },
    { content: i18n.t('calendar.weekDays')[1][0], value: 'SU' }
  ], [i18n.locale])

  const months = useMemo(() => [
    { content: i18n.t('calendar.months')[0][0],  value: '1' },
    { content: i18n.t('calendar.months')[1][0],  value: '2' },
    { content: i18n.t('calendar.months')[2][0],  value: '3' },
    { content: i18n.t('calendar.months')[3][0],  value: '4' },
    { content: i18n.t('calendar.months')[4][0],  value: '5' },
    { content: i18n.t('calendar.months')[5][0],  value: '6' },
    { content: i18n.t('calendar.months')[6][0],  value: '7' },
    { content: i18n.t('calendar.months')[7][0],  value: '8' },
    { content: i18n.t('calendar.months')[8][0],  value: '9' },
    { content: i18n.t('calendar.months')[9][0],  value: '10' },
    { content: i18n.t('calendar.months')[10][0], value: '11' },
    { content: i18n.t('calendar.months')[11][0], value: '12' },
  ], [i18n.locale])

  const iterators = useMemo(() => [
    { content: i18n.t('calendar.words.first'),  value: '1' },
    { content: i18n.t('calendar.words.second'), value: '2' },
    { content: i18n.t('calendar.words.third'),  value: '3' },
    { content: i18n.t('calendar.words.last'),   value: '-1' }
  ], [i18n.locale])

  return (
    <div style={{marginTop: '12px'}}>
      <FormStyle.Header marginY="M">
        <FontAwesomeIcon icon="repeat" />
        {i18n.t('maintenance.recurrence_type')}
      </FormStyle.Header>
      <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '8px'}}>
        <Toggle
          initialOption = {rules.mode === MODES.INTERVAL ? 'left' : 'right'}
          color         = '--rep-accent'
          callback      = {selectMode}
          options       = {{
            left: {
              id:      MODES.INTERVAL,
              content: i18n.t('maintenance.fixed_interval'),
              icon:    <FontAwesomeIcon icon="arrows-left-right" />
            },
            right: {
              id:      MODES.RECURRENCY,
              content: i18n.t('maintenance.fixed_date'),
              icon:    <FontAwesomeIcon icon="calendar-plus" />

            }
          }}
        />
      </div>

      {rules.mode === MODES.INTERVAL &&
        <div>
          <FormStyle.TextBlock marginY='S' fullWidth border='transparent'>
            {i18n.t('maintenance.form.maintenance_scheduled')}
            <FormStyle.Tooltip>
              <WithTooltip content={i18n.t('maintenance.form.tooltip.interval')}>
                <FontAwesomeIcon icon="circle-question" />
              </WithTooltip>
            </FormStyle.Tooltip>
          </FormStyle.TextBlock>
          <FormStyle.Group>
            <FormStyle.Group>
              <Input
                type         = 'number'
                name         = 'interval'
                min          = {1}
                placeholder  = '1, 2, 3…'
                defaultValue = {rules.interval}
                change       = {event => setRules(rules => ({...rules, interval: Number.parseInt(event.target.value, 10) }))}
                width        = '120px'
                required
              />
              <Select
                name         = "frequency"
                defaultValue = {[frequencies.find(f => f.value === rules.frequency)]}
                callback     = {selectFrequencyMode}
                placeholder  = {i18n.t('maintenance.placeholder.day_week_month')}
                options      = {frequencies}
              />
            </FormStyle.Group>
          </FormStyle.Group>
          <FormStyle.TextBlock marginY='S' fullWidth border='transparent'>
            {i18n.t('maintenance.form.after_closing_of_previous_maintenance')}
          </FormStyle.TextBlock>
          {options &&
            <Button
              icon    = {<FontAwesomeIcon icon={showDetails ? 'angle-up' : 'angle-down'} />}
              click   = {() => setShowDetails(!showDetails)}
              marginY = 'S'
              color   = 'var(--rep-primary)'
              border  = {showDetails ? 'var(--rep-primary)' : ''}
            >
              {i18n.t('shared.more_options')}
            </Button>
          }
          {showDetails &&
          <>
            <FormStyle.Header marginY='S'>
              <FontAwesomeIcon icon="wand-magic-sparkles" />
              {i18n.t('maintenance.ticket_generation')}
            </FormStyle.Header>
            <Checkbox
              name    = 'auto_generate-checkbox'
              label   = {i18n.t('maintenance.form.auto_generate_tickets')}
              tooltip = {
                <div style={{whiteSpace: 'pre-line'}}>
                  {i18n.t('maintenance.form.tooltip.auto_generation')}
                </div>
              }
              defaultValue = {rules.auto_generate}
              callback     = {val => setRules(rules => ({...rules, auto_generate: val.isCheck }))}
              marginY      = 'S'
            />
          </>
          }

        </div>
      }
      <Input type="hidden" name="auto_generate" defaultValue={parseBoolean(rules?.auto_generate)} />

      {rules.mode === MODES.RECURRENCY &&
        <div>
          <FormStyle.Group marginY='M' justify='none'>
            <FormStyle.TextBlock border='transparent'>{i18n.t('maintenance.form.every')}…</FormStyle.TextBlock>
            <Input
              type         = 'number'
              name         = 'interval'
              min          = {1}
              defaultValue = {rules.interval}
              change       = {event => setRules(rules => ({...rules, interval: Number.parseInt(event.target.value, 10) }))}
            />
            <Select
              name         = "frequency"
              defaultValue = {[frequencies.find(f => f.value === rules.frequency)]}
              callback     = {selectFrequencyMode}
              placeholder  = {i18n.t('maintenance.placeholder.day_week_month')}
              options      = {frequencies}
              withEmpty
              required
            />
          </FormStyle.Group>

          {rules.frequency === FREQUENCIES.WEEKLY &&
            <>
              <FormStyle.TextBlock marginY='M' border='transparent'>{i18n.t('maintenance.form.on_days').toLowerCase()}</FormStyle.TextBlock>
              <Switch
                name       = 'byweekday'
                selected   = {rules?.byweekday?.split(',')}
                callback   = {(byweekday: ByWeekDays) => {
                  let weekdays: ByWeekDays[] = rules?.byweekday?.split(',') || []
                  if (weekdays.includes(byweekday)) {
                    weekdays = weekdays.filter(d => d !== byweekday)
                  } else if (byweekday) {
                    weekdays.push(byweekday)
                  }
                  const weekday: ByWeeksDaysType = weekdays.filter(e => e).join(',')
                  setRules(rules => ({...rules, byweekday: weekday }))
                }}
                desktopCol = {7}
                options    = {days}
                marginY    = 'M'
                multiselect
              />
            </>
          }

          {(rules.frequency === FREQUENCIES.MONTHLY || rules.frequency === FREQUENCIES.YEARLY) &&
            <>
              <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '8px'}}>
                <Toggle
                  initialOption = {rules.dayrecurrency === FIXED.FIXED_DATE ? 'left' : 'right'}
                  color         = '--rep-primary'
                  callback      = {selectRecurrencyMode}
                  options       = {{
                    left: {
                      id:      FIXED.FIXED_DATE,
                      content: i18n.t('maintenance.fixed_date'),
                      icon:    <FontAwesomeIcon icon="calendar-day" />
                    },
                    right: {
                      id:      FIXED.FIXED_DAY,
                      content: i18n.t('maintenance.fixed_day'),
                      icon:    <FontAwesomeIcon icon="repeat" />

                    }
                  }}
                />
              </div>

              {rules.dayrecurrency === FIXED.FIXED_DATE &&
                <FormStyle.Group justify='none'>
                  <FormStyle.TextBlock border='transparent'>{i18n.t('maintenance.form.on_the')}…</FormStyle.TextBlock>
                  <Input
                    type         = "number"
                    name         = 'bymonthday'
                    min          = {0}
                    max          = {31}
                    defaultValue = {rules.bymonthday}
                    change       = {event => setRules(rules => ({...rules, bymonthday: Number.parseInt(event.target.value, 10) }))}
                    placeholder  = {i18n.t('maintenance.placeholder.between_1_and_31')}
                    required
                  />
                  <FormStyle.TextBlock border='transparent'>{i18n.t('maintenance.form.of_the_month').toLowerCase()}</FormStyle.TextBlock>
                </FormStyle.Group>
              }
              {rules.dayrecurrency === FIXED.FIXED_DAY &&
                <FormStyle.Group justify='none'>
                  <FormStyle.TextBlock border='transparent'>{i18n.t('maintenance.form.on_the')}…</FormStyle.TextBlock>
                  <Select
                    name         = "bysetpos"
                    callback     = {bysetpos => setRules(rules => ({...rules, bysetpos: bysetpos.value }))}
                    placeholder  = {i18n.t('maintenance.placeholder.first_second_etc')}
                    defaultValue = {[ITERATOR_MATCH[rules.bysetpos]]}
                    options      = {iterators}
                    marginY      = 'S'
                    required
                  />

                  <Select
                    name       = 'byweekday'
                    defaultValue   = {[days.find(d => d.value === rules.byweekday)]}
                    callback   = {byweekday => setRules(rules => ({...rules, byweekday: byweekday.value }))}
                    options    = {days}
                    marginY    = 'S'
                  />
                </FormStyle.Group>
              }
            </>
          }

          {rules.frequency === FREQUENCIES.YEARLY &&
            <FormStyle.Group marginY = 'S'>
              <FormStyle.TextBlock border='transparent'>{i18n.t('maintenance.form.on_the_month_of').toLowerCase()}</FormStyle.TextBlock>
              <Select
                name         = 'bymonth'
                callback     = {bymonth => setRules(rules => ({...rules, bymonth: bymonth.value }))}
                defaultValue = {[MONTH_MATCH[rules.bymonth]]}
                placeholder  = {i18n.t('maintenance.placeholder.jan_feb_etc')}
                options      = {months}
              />
            </FormStyle.Group>
          }

          {options &&
          <Button
            icon    = {<FontAwesomeIcon icon={showDetails ? 'angle-up' : 'angle-down'} />}
            click   = {() => setShowDetails(!showDetails)}
            marginY = 'S'
            color   = 'var(--rep-primary)'
            border  = {showDetails ? 'var(--rep-primary)' : ''}
          >
            {i18n.t('shared.more_options')}
          </Button>
          }
          {showDetails &&
            <>
              <FormStyle.Label>
                {i18n.t('maintenance.except_during')}
              </FormStyle.Label>
              <DatePicker
                disableYear
                mode     = 'range'
                name     = 'exclusions'
                callback = {dates => setRules(rules => ({...rules, exclusions: dates.map(d => d.toISOString()).join(',') }))}
                date     = {
                  rules?.exclusions
                    ?.split(',')
                    ?.filter(d => d)
                    ?.map(d => new Date(d)) || []
                }
                required
              />

              <FormStyle.Label>
                {i18n.t('maintenance.end_of_recurrence')}
              </FormStyle.Label>

              <Switch
                allWidth
                marginY    = 'S'
                name       = "stopcondition"
                selected   = {rules.stopcondition}
                callback   = {stopcondition => setRules(rules => ({...rules, stopcondition }))}
                desktopCol = {3}
                options    = {[
                  {
                    content: i18n.t('maintenance.end.none'),
                    value:   STOP.NEVER
                  },
                  {
                    content: i18n.t('maintenance.end.fixed_date'),
                    value:   STOP.DATE
                  },
                  {
                    content: i18n.t('maintenance.end.nb_occurences'),
                    value:   STOP.COUNT
                  },
                ]}
              />

              {rules.stopcondition === STOP.DATE &&
                  <DatePicker
                    name     = 'until'
                    callback = {date => setRules(rules => ({...rules, until: date }))}
                    date     = {rules.until}
                  />
              }

              {rules.stopcondition === STOP.COUNT &&
                  <FormStyle.Group justify='none' marginY='S'>
                    <FormStyle.TextBlock border='transparent'>{i18n.t('maintenance.form.after')}…</FormStyle.TextBlock>
                    <Input
                      type         = 'number'
                      name         = 'count'
                      min          = {1}
                      defaultValue = {rules.count}
                      change       = {e => setRules(rules => ({...rules, count: parseInt(e.target.value) }))}
                      width        = '120px'
                    />
                    <FormStyle.TextBlock border='transparent'>{i18n.t('maintenance.form.occurences').toLowerCase()}</FormStyle.TextBlock>
                  </FormStyle.Group>
              }
            </>
          }
          {resume && resume == 'block' &&
          <FormStyle.Header marginY='M'>
            <FontAwesomeIcon icon="rectangle-list" />
            {i18n.t('shared.summary')}
          </FormStyle.Header>
          }

          {resume && <RecurrencyString rules={rules} type='accent' />}
        </div>
      }
    </div>
  )
}

export default RecurrencyForm
