import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AvatarList      from '@components/AvatarList'
import Button          from '@components/Button'
import Callout         from '@components/Callout'
import Input           from '@components/Form/Input'
import Tag             from '@components/Tag'

import * as FormStyle from '@form/FormStyles'
import * as Style from './style'

import { useGlobalContextState } from '@context/GlobalContext'

import { ITaskBox } from './types.d'
import SelectDoerForm from '@components/User/SelectDoerForm'
import Spacer from '@components/Spacer'

const TaskBox: React.FC<ITaskBox> = ({
  task,
  index,
  showUsersAs = 'list',
  editAction,
  removeAction,
  selectUsers,
  selectProvider,
  // removeUser,
  suggestedUser,
  showNotify,
  defaultNotify
}) => {

  const { current_company, setInfoWindowProps } = useGlobalContextState()

  return(
    <Style.TaskBox>
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
          <FontAwesomeIcon icon="clipboard-check" />
          <h3>{task.name}</h3>
          {(!!task.hours || !!task.minutes) &&
            <Tag
              color          = 'var(--rep-primary)'
              border         = 'var(--rep-primary-light)'
              tooltipContent = "Durée prévue"
              icon           = {<FontAwesomeIcon icon="clock" />}
              marginY        = 'S'
            >
              {!!task.hours && `${task.hours}h`} {!!task.minutes && `${task.minutes}min`}
            </Tag>
          }
          {!!task.periodicity && task.periodicity !== 1 &&
            <Tag
              color          = 'var(--rep-primary)'
              border         = 'var(--rep-primary-light)'
              tooltipContent = "Périodicité"
              icon           = {<FontAwesomeIcon icon="rotate-right" />}
              marginY        = 'S'
            >
              {task.periodicity}
            </Tag>
          }
        </div>
        <div style={{ display: 'flex', gap: '8px' }}>
          {!!editAction &&
            <Button
              click      = {() => editAction(task)}
              background = "var(--rep-primary-light)"
              color      = "var(--rep-primary)"
              icon       = {<FontAwesomeIcon icon="square-pen" />}
              marginY    = 'S'
            />
          }
          {!!removeAction &&
            <Button
              click       = {removeAction}
              background  = 'var(--rep-danger-light)'
              color       = 'var(--rep-danger)'
              icon        = {<FontAwesomeIcon icon='trash' />}
              confirmText = 'Remove ?'
              confirmIcon = {<FontAwesomeIcon icon='trash' />}
              marginY     = 'S'
              confirm
            />
          }
        </div>
      </div>

      <p>{task.description}</p>

      {(!!task.formsTemplates?.length ||!!task.formsCreated?.length) &&
        <FormStyle.Group style={{ justifyContent: 'flex-start' }}>
          {task.formsTemplates?.map((f, key) =>
            <Tag
              key    = {key}
              icon   = {<FontAwesomeIcon icon="receipt" />}
              border = "var(--rep-primary)"
              color  = "var(--rep-primary)"
              click  = {() => current_company.beta_access && setInfoWindowProps({item: f, type: 'form-templates', target: 'modal' })}
            >
              {f.name}
            </Tag>
          )}
          {task.formsCreated?.map((f, key)   =>
            <Tag
              key    = {key}
              icon   = {<FontAwesomeIcon icon="receipt" />}
              border = "var(--rep-primary)"
              color  = "var(--rep-primary)"
              click  = {() => current_company.beta_access && setInfoWindowProps({item: f, type: 'form-templates', target: 'modal' })}
            >
              {f.name}
            </Tag>
          )}
        </FormStyle.Group>
      }

      <Spacer size="m" />

      {showUsersAs === 'avatars' &&
        <AvatarList
          users = {task.users}
        />
      }

      {/* {showUsersAs === 'list' &&
        !!task.users.length   &&
          task.users.map(user =>
            <MultiselectItem
              key          = {user.id}
              border       = 'var(--rep-neutral-primary-middle)'
              name         = {user.name}
              icon         = {<FontAwesomeIcon icon={user.service_company ? 'user-tie' : 'user-gear'} />}
              removeAction = {removeUser ? (_event) => removeUser(task, user) : null}
            />
          )
      } */}
      {/*
      <Input
        name         = {`task-index`}
        type         = "hidden"
        defaultValue = {task.users.map(u => u.id).toString()}
      /> */}


      {!!suggestedUser &&
        <Callout type="primary" marginY='M'>
          {suggestedUser?.reason}
        </Callout>
      }

      {!!selectUsers &&
        <SelectDoerForm
          taskId                 = {task.id}
          index                  = {index}
          assignedTo             = {task.assignation}
          selectProviderCallback = {selectProvider}
          selectUsersCallback    = {selectUsers}
          defaultUsers           = {task.users}
          defaultProvider        = {task.provider}
          showPeriodSelection    = {false}
          showNotify             = {showNotify}
          defaultNotify          = {defaultNotify}
          multiselect
          // cleanAfterSelect
        />
      }

    </Style.TaskBox>
  )
}

export default TaskBox
