import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  startDrag(e) {
    this.sourceRow = e.target

    e.dataTransfer.setData('text', e.target.id)
    e.dataTransfer.effectAllowed = 'move'
  }

  enterRow(e) {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'move'

    if (document.getElementById('sourceRowCopy')) { return }

    const sourceRowCopy = this.sourceRow.cloneNode(true)
    sourceRowCopy.classList.add('table-primary')
    sourceRowCopy.id = 'sourceRowCopy'
    e.target.closest('tr').after(sourceRowCopy)
  }

  leaveRow() {
    document.getElementById('sourceRowCopy').remove()
  }

  dropRow(e) {
    e.preventDefault()
    document.getElementById('sourceRowCopy').remove()

    const data      = e.dataTransfer.getData('text')
    const targetRow = e.target.closest('tr')
    targetRow.after(document.getElementById(data))

    const questionOrder = Array.from(this.element.querySelectorAll('tbody > tr')).map((row) => row.dataset.questionId)

    fetch(`/form_templates/${this.templateId}/reorder_questions.json`, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      method: 'PATCH',
      body:   JSON.stringify({ order: questionOrder })
    })
      .then(response => response.json())
      .then((data) => {
        if (data.errors) { return alert(data.errors) }
      })
  }

  set sourceRow(row) { this._sourceRow = row }

  get sourceRow()    { return this._sourceRow }
  get templateId()   { return this.element.dataset.templateId }
  get csrfToken()    { return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content') }

}
