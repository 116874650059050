import styled from 'styled-components'

export const Modal = styled.div`
  -ms-overflow-style: none;
  scrollbar-width:    none;
  position:           fixed;
  will-change:        top;
  overflow:           scroll;
  background:         white;
  border-radius:      4px;
`

export const ModalOverlay = styled.div`
  visibility:  hidden;
  position:    fixed;
  will-change: visibility, opacity;
  background: #060e14;
  opacity:     65%;
  height:      100vh;
  width:       100vw;
  top:         0;
  left:        0;
  z-index:     2499;
`

export const ModalTitle = styled.div`
  display:         flex;
  justify-content: space-between;
  align-items:     center;
  border-bottom:   1px solid var(--rep-neutral-primary-light);
  padding:         32px 40px;
  margin-bottom:   16px;
  position:        sticky;
  background:     white;
  top:             0;
  z-index:         1000;
  height: 100px;
  h3 {
    font-size:     1.3rem;
    margin-bottom: 0;
  }
  p {
    color: var(--rep-neutral-primary);
  }
`

export const ModalContent = styled.div`
  padding: 0 40px 40px 40px;
`

export const ModalAction = styled.div`
  position: sticky;
  bottom: 0;
  background: white;
`

export const CloseIcon = styled.div`
  position:        absolute;
  right:           16px;
  top:             16px;
  background:      var(--rep-primary-light);
  color:           var(--rep-primary);
  width:           30px;
  height:          30px;
  border-radius:   4px;
  display:         flex;
  justify-content: center;
  align-items:     center;
  &:hover {
    cursor:  pointer;
    opacity: .8;
  }
`
