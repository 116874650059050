import styled, { css, keyframes } from 'styled-components'

const desktopSizes = {
  none: '0',
  M:    '4px 8px;',
  L:    '8px 16px',
  XL:   '16px 32px',
}

const mobileSize = {
  none: '0',
  M:    '8px 16px',
  L:    '16px 32px',
  XL:   '32px 48px;'
}

const resting = keyframes``
const rotate = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`


const DesktopPadding = css` ${props => desktopSizes[props.size] || '4px 8px;'  } `
const MobilePadding  = css` ${props => mobileSize[props.size]   || '8px 16px;' } `

export const Tag = styled.div`
  display:        ${props => props.inline ? 'inline-flex' : 'flex'};
  max-width:      100%;
  align-items:    center;
  width:          fit-content;
  min-height:     30px;
  /* padding:        4px 8px; */
  padding:         ${props => props.isDesktop ? DesktopPadding : MobilePadding};
  background:     ${props => props.isDesktop ? (props.background || 'transparent') : (props.background || props.hover || 'transparent')};
  color:          ${props => props.color ? props.color : 'black'};
  border-radius:  5px;
  border:         ${props => props.border ? '1px' : '0px'} solid ${props => props.border ? props.border : 'black'};
  font-weight:    450;
  user-select:    none;
  box-sizing:     border-box;
  font-size:      .8rem;
  white-space:    nowrap;
  /* overflow:       hidden; */
  text-overflow:  ellipsis;
  transition:     background .3s ease;
  margin-top: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return '0'
    }
  }};
  margin-bottom: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return '0'
    }
  }};

  white-space:   nowrap;
  overflow:      hidden;
  text-overflow: ellipsis;
  transition:    background .3s ease;

  &:hover {
    cursor:     ${props => props.clickable ? 'pointer' : 'default'};
    background: ${props => props.hover ? props.hover : props.background};
  }
`

export const TagIcon = styled.div`
  margin-right: ${props => props.hasContent ? '8px' : '0'};
  animation:    ${props => props.animate ? rotate : resting} .2s linear;
`
