import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DatePicker        from '@form/DatePicker'
import FileUploader      from '@form/FileUploader'
import Form              from '@components/Form/Form'
import MeasurementInForm from '@components/Measure/MeasureValue/MeasurmentInForm'
import Textarea          from '@form/Textarea'

import { useGlobalContextState } from '@context/GlobalContext'
import { useTaskContextState } from '@context/TaskContext'

import * as FormStyle from '@form/FormStyles'

import IReport from '@interfaces/IReport.d'
import Spacer from '@components/Spacer'

interface DoneForm {
  report:   IReport
  callback: (data: object, fieldset: React.RefObject<HTMLFieldSetElement>) => void
}

const DoneForm: React.FC<DoneForm> = ({
  callback,
  report
}) => {

  const { i18n, current_company, CONSTANTS } = useGlobalContextState()
  const { task, updateMethods } = useTaskContextState()

  const { MEASURMENT } = CONSTANTS.REPORT_TYPES

  const [endedDate, _setEndedDate] = useState(task.done_date || new Date())

  return (
    <Form
      callback={callback}
      // addFormData      = {addFormData}
      submitBackground = {'var(--rep-success-light)'}
      submitColor      = {'var(--rep-success)'}
      submitText       = {i18n.t('actions.close_task')}
    >
      {task.permissions.edit_closing_date &&
        <>
          <FormStyle.Header marginY='S'><FontAwesomeIcon icon="calendar-check" />{i18n.t('todo.ended_at')}</FormStyle.Header>
          <DatePicker
            name        = {'ended_at'}
            date        = {endedDate}
            maxDateTime = {new Date()}
          />
        </>
      }

      {report.measurments.filter(m => m.time === MEASURMENT.AT_TASK_CLOSING).map((measurment, index) =>
        <MeasurementInForm
          key        = {measurment.id}
          index      = {index}
          measurment = {measurment}
        />
      )
      }

      <Spacer />

      <FormStyle.Header marginY='S'>
        <FontAwesomeIcon icon="comment-medical" />
        {i18n.t('todo.closing_comment')}
      </FormStyle.Header>
      <Textarea
        name         = {'comment'}
        defaultValue = {task.comment}
        required     = {current_company.permissions.task_closing_comment_required}
        placeholder  = {i18n.t('todo.add_closing_comment')}
        marginY      = {'M'}
      />
      <FileUploader
        objectType      = {'to_do_item'}
        objectId        = {task.id}
        closeModalAfter = {false}
        updateMethods   = {updateMethods}
        files           = {task.documents}
        multiple
        destroyable
        grid
      />
    </Form>
  )
}

export default DoneForm
