import React from 'react'

/**
 * Interface définissant les propriétés du composant Spacer
 * @interface SpacerProps
 * @property {('sm'|'s'|'m'|'l'|'xl'|number)} [size='m'] - Taille de l'espacement
 */
interface SpacerProps {
  size?: 'sm' | 's' | 'm' | 'l' | 'xl' | number;
}

/**
 * Correspondance entre les tailles nommées et leurs valeurs en pixels
 * @constant
 * @type {Record<string, number>}
 */
const sizeMap = {
  sm: 4,
  s:  8,
  m:  16,
  l:  24,
  xl: 32,
}


/**
 * Composant qui crée un espacement vertical ou horizontal
 *
 * @component
 * @example
 * // Espacement moyen (16px)
 * <Spacer />
 *
 * @example
 * // Petit espacement (8px)
 * <Spacer size="s" />
 *
 * @example
 * // Espacement personnalisé (20px)
 * <Spacer size={20} />
 *
 * @param {SpacerProps} props - Les propriétés du composant
 * @param {('sm'|'s'|'m'|'l'|'xl'|number)} [props.size='m'] - Taille de l'espacement
 * @returns {JSX.Element} Élément div avec la marge spécifiée
 */
const Spacer: React.FC<SpacerProps> = ({ size = 'm' }) => {
  const marginSize = typeof size === 'number' ? size : sizeMap[size]

  return <div style={{ margin: marginSize }} />
}

export default Spacer
