import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '@components/Button'
import Switch from '@components/Switch'
import Tag    from '@components/Tag'

import CalendarFilters from './CalendarFilters'
import MainLine        from './MainLine/MainLine'

import PlanForm        from '../Form/PlanForm'

import * as Style from './style'

import { useGlobalContextState }       from '@context/GlobalContext'
import { useMaintenancesContextState } from '@context/MaintenancesContext'

import { MAINTENANCE_ACTIONS, maintenanceReducer } from '@reducers/maintenanceReducer'

import { getCurrentWeek, getWeekArray, getWeeksInMonth } from '@utils/time.js'

const MaintenanceCalendar = () => {

  const { i18n, current_user, fetchApi, showModal, closeModal } = useGlobalContextState()

  const {
    MAINTENANCE_CONSTANTS,
    permissions,
    initialPagination, startDayOfWeek,
    maintainables, setMaintainables,
    filters, setFilters,
    isCurrentYear,
    currentWeek, setCurrentWeek,
    weekArray, setWeekArray,
    weekCount, setWeekCount,
    buildFilterString
  } = useMaintenancesContextState()

  const { VIEWS } = MAINTENANCE_CONSTANTS

  const [loading,             setLoading]            = useState(false)
  const [showFilters,         toggleFilters]         = useState(false)
  const [calendarPagination,  setCalendarPagination] = useState(initialPagination)

  const currentWeekBlock     = useRef(null)
  const calendarContainerRef = useRef(null)


  const [_state, dispatch] = useReducer(maintenanceReducer, {
    fetchApi,
    VIEWS,
    filters,
    closeModal,
    setLoading,
    setMainLines: setMaintainables,
  })

  const [controller, setController] = useState(new AbortController())

  useEffect(() => fetchPage(1), [])

  const firstRender = useRef(true)

  const filterCount = useMemo(() => {
    const filtersCopy = {... filters}
    const excludedKeys = [
      'view',
      'year',
      'amenity_type_name',
      'plan_name',
      'zone_name',
      'amenity_name',
      'expertise_name'
    ]
    return Object.keys(
      Object.fromEntries(
        Object.entries(filtersCopy)
          .filter(([k, v]) => !excludedKeys.includes(k) && !!v)
      )
    ).length
  }, [filters])

  const scrollToCurrentWeek = useCallback(() => {
    if (!isCurrentYear || !firstRender.current || !currentWeekBlock.current) return
    currentWeekBlock.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
    firstRender.current = false
  }, [isCurrentYear])

  const fetchPage = page => {
    const newController = new AbortController()
    const signal = newController.signal
    setController(newController)
    dispatch({
      type:          MAINTENANCE_ACTIONS.FETCH_MAINLINES,
      callbacks:     [scrollToCurrentWeek],
      page,
      filters,
      signal,
      pagination:    calendarPagination,
      setPagination: setCalendarPagination,
    })
  }

  const handleScroll = e => {
    if (calendarContainerRef.current.scrollHeight <= calendarContainerRef.current.clientHeight) return
    if (loading) return
    if (Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 1) {
      fetchPage(null)
    }
  }

  const changeView = view => {
    controller.abort()
    fetchApi({
      url:      'users/update_preferences',
      method:   'PATCH',
      body:     { preferences: { maintenance: { view }} },
      callback: () => {
        if (view === VIEWS.MAINTENANCES) {
          window.location.assign('/maintenances/listing')
        } else if (view === VIEWS.TICKETS) {
          window.location.assign('/maintenances/full_index')
        } else if (view === VIEWS.PLANS_TABLE) {
          window.location.assign('/maintenance_plans/full_index')
        } else {
          setFilters(filters => ({ ...filters, view: view }))
          setMaintainables([])
        }
      }
    })
  }

  useEffect(() => {
    if (firstRender.current) {
      // if (current_user.preferences?.maintenance?.view === VIEWS.TABLE) window.location.assign('/maintenances/listing')
      scrollToCurrentWeek()
    }
    const filterString = buildFilterString(filters)
    window.history.replaceState(null, '', `${window.location.pathname}?${filterString}`)
    setCalendarPagination({ page: 0, next: 1, last: null })
    setMaintainables([])
    fetchPage(1)
  }, [filters])

  const newMaintenancePlan = useCallback(() => {
    showModal({
      title:   i18n.t('maintenance.maintenance_plan'),
      content: <PlanForm
        setMainLines  = {setMaintainables}
        setPagination = {setCalendarPagination}
        setLoading    = {setLoading}
      />,
      asWizard: true
    })
  }, [])

  useEffect(() => {
    setCurrentWeek(isCurrentYear ? getCurrentWeek() : null)
    const newWeekArray = getWeekArray(filters.year, startDayOfWeek)
    setWeekArray(newWeekArray)
    setWeekCount(Object.keys(newWeekArray).length)
  }, [filters.year])

  const weeksInMonth = useMemo(() => getWeeksInMonth(weekArray), [weekArray])

  const monthsHeader = useMemo(() => (
    <Style.CalendarTable length={weekCount} stickyTop>
      <Style.CalendarMonth background='white' sticky />
      {Object.keys(weeksInMonth).map(monthIndex =>
        <Style.CalendarMonth
          key          = {monthIndex}
          weeks        = {weeksInMonth[monthIndex]}
          currentMonth = {isCurrentYear && (parseInt(monthIndex)) === new Date().getMonth()}
        >
          {new Date(filters.year, parseInt(monthIndex), 1).toLocaleString(i18n.locale, { month: 'long' })}
        </Style.CalendarMonth>
      )}
    </Style.CalendarTable>
  ), [weekArray])

  const weeksHeader = useMemo(() => {
    const addWeekZero = weekArray['0'] ? true : false
    return (
      Object.keys(weekArray).map(week =>
        <Style.CalendarBox
          key         = {`week-${week}`}
          background  = 'white'
          currentWeek = {parseInt(week) === currentWeek}
          ref         = {parseInt(week) === currentWeek ? currentWeekBlock : null}
          main
        >
          W{addWeekZero ? parseInt(week) + 1 : week}
        </Style.CalendarBox>
      )
    )
  }, [weekArray])

  const datesHeader = useMemo(() =>
    Object.keys(weekArray).map(week =>
      <Style.CalendarBox
        id          = {`dates-${week}`}
        key         = {`dates-${week}`}
        background  = 'white'
        currentWeek = {parseInt(week) === currentWeek}
        fontSize    = '8px'
        main
      >
        {weekArray[week][0].toLocaleDateString(i18n.locale, { day: 'numeric' })}{'->'}{weekArray[week][1].toLocaleDateString(i18n.locale, { day: 'numeric' })}
      </Style.CalendarBox>
    ), [weekArray, filters.year])

  return (
    <Style.CalendarContainer>
      {permissions.maintenance_plans.can_create_maintenance_plan &&
        <Style.ButtonGroupBetween>
          <h5 style={{marginBottom: '0'}}>{i18n.t('shared.servicing')}</h5>
          <Button
            background = 'var(--rep-primary)'
            color      = 'white'
            icon       = {<FontAwesomeIcon icon="plus" />}
            click      = {newMaintenancePlan}
          >
            {i18n.t('maintenance.new_maintenance_plan')}
          </Button>
        </Style.ButtonGroupBetween>
      }
      <Style.SwitchMenu>
        <Switch
          name       = 'frequency'
          selected   = {filters.view}
          callback   = {changeView}
          desktopCol = {3}
          options    = {[
            {
              icon:    <FontAwesomeIcon icon="gear" />,
              content: i18n.t('maintenance.view.calendar_asset'),
              value:   VIEWS.MAINTAINABLES
            },
            {
              icon:    <FontAwesomeIcon icon="sitemap" />,
              content: i18n.t('maintenance.view.calendar_plan'),
              value:   VIEWS.PLANS
            },
            {
              icon:    <FontAwesomeIcon icon="table" />,
              content: i18n.t('maintenance.view.plan_table'),
              value:   VIEWS.PLANS_TABLE
            },
            {
              icon:    <FontAwesomeIcon icon="clipboard-list" />,
              content: i18n.t('maintenance.view.table'),
              value:   VIEWS.MAINTENANCES
            },
            {
              icon:    <FontAwesomeIcon icon="ticket" />,
              content: i18n.t('maintenance.view.maintenance_operations'),
              value:   VIEWS.TICKETS
            }
          ]}
        />
      </Style.SwitchMenu>

      <Style.Filters background>
        <Style.ButtonGroupBetween>
          <Style.ButtonGroup>
            <Button
              click = {() => setFilters(filters => ({...filters, year: filters.year - 1 }))}
              icon  = {<FontAwesomeIcon size="2xl" icon="square-caret-left" />}
            />
            <h3 style={{marginBottom: '0'}}>{filters.year}</h3>
            <Button
              click = {() => setFilters(filters => ({...filters, year: filters.year + 1 }))}
              icon  = {<FontAwesomeIcon size="2xl" icon="square-caret-right" />}
            />
            <Button
              icon       = {<FontAwesomeIcon icon={['fas', `wand-magic${filterCount ? '-sparkles' : ''}`]} />}
              background = {filterCount ? 'var(--rep-primary-light)' : null}
              border     = 'var(--rep-primary)'
              click      = {() => toggleFilters(!showFilters)}
            >
              {i18n.t('actions.filter')}
              {!!filterCount &&
               <Tag
                 color="white"
                 background="var(--rep-danger)"
               >
                 {filterCount}
               </Tag>
              }
            </Button>


          </Style.ButtonGroup>
          <div style={{display: 'flex', gap: '8px'}}>
            <Tag color='var(--rep-danger)'  icon={<FontAwesomeIcon icon="circle" />}> {i18n.t('maintenance.calendar.late_legend')}</Tag>
            <Tag color='var(--rep-warning)' icon={<FontAwesomeIcon icon="circle" />}> {i18n.t('maintenance.calendar.unassigned_legend')}</Tag>
            <Tag color='var(--rep-accent)'  icon={<FontAwesomeIcon icon="circle" />}> {i18n.t('maintenance.calendar.assigned_legend')}</Tag>
            <Tag color='var(--rep-primary)' icon={<FontAwesomeIcon icon="circle" />}> {i18n.t('maintenance.calendar.to_close_legend')}</Tag>
            <Tag color='var(--rep-success)' icon={<FontAwesomeIcon icon="circle" />}> {i18n.t('maintenance.calendar.done_legend')}</Tag>
          </div>
        </Style.ButtonGroupBetween>
        {showFilters && <CalendarFilters />}
      </Style.Filters>

      <Style.CalendarContent ref={calendarContainerRef} onScroll={handleScroll}>
        {monthsHeader}
        <Style.CalendarTable length={weekCount} main>
          <Style.CalendarName />
          {weeksHeader}
          <Style.CalendarName>
            <div></div>
            <div style={{ gridColumn: '1 / span 4' }}>
              {filters.view === VIEWS.PLANS
                ? i18n.t('maintenance.maintenance_list')
                : i18n.t('maintenance.assets_list')
              }
            </div>
          </Style.CalendarName>
          {datesHeader}

          {maintainables.length
            ? maintainables.map(line =>
              <MainLine
                key           = {`${line.content.data.class_plural}-${line.content.data.id}`}
                line          = {line}
              />
            )
            : loading
              ? <></>
              :<Style.CalendarEmpty>
                <FontAwesomeIcon icon="person-digging" />
                <div>{i18n.t('maintenance.none_found')}</div>
              </Style.CalendarEmpty>
          }

          {loading
            ? <Style.Loading length={weekCount + 1} />
            : (calendarPagination.last !== calendarPagination.page &&
              <Style.CalendarLoadingContainer length={weekCount + 1}>
                <Style.CalendarEmpty onClick={() => fetchPage(null)}>
                  <FontAwesomeIcon icon="arrow-down" />
                  {i18n.t('shared.load_more')}
                </Style.CalendarEmpty>
              </Style.CalendarLoadingContainer>
            )
          }
        </Style.CalendarTable>
      </Style.CalendarContent>
    </Style.CalendarContainer>
  )
}

export default MaintenanceCalendar


