import styled, { keyframes } from 'styled-components'

export const SelectContainer = styled.div`
  position:   relative;
  flex-grow:  1;
  min-height: 3rem;
  margin-top: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return '0'
    }
  }};
  margin-bottom: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return '0'
    }
  }};
  & > label {
    position:   absolute;
    top:        -8px;
    left:       12px;
    z-index:    200;
    color:      ${props => props.required && !props.selected ? 'var(--rep-warning)' : 'var(--rep-neutral)'};
    background: ${props => props.disabled ? 'transparent' : 'white'};
    padding:    0 4px;
    font-size:  .7rem;
  }
`

export const SelectOptions = styled.div`
  border-radius: 0 0 5px 5px;
  max-height:    40vh;
  min-height:    fit-content;
  overflow-y:    auto;
  border:        1px solid var(--rep-primary-light);
  box-shadow:    0px 5px 5px var(--rep-neutral-primary-light);
  border-color:  ${props => props.opened ? 'var(--rep-primary-light)' : 'var(--rep-primary)'};
  border-top:    none;
  position:      absolute;
  width:         100%;
  z-index:       1000;
  background:  white;
`

export const SelectOption = styled.div`
  padding:         8px;
  transition:      .3s ease;
  color:           var(--rep-neutral-primary);
  font-weight:     ${props => props.none ? '100' : 'initial'};
  font-size:       .9rem;
  border-top:      1px solid var(--rep-primary-light);
  user-select:     none;
  display:         flex;
  align-items:     center;
  justify-content: space-between;
  min-height:      2rem;
  background:      ${props => props.selected || props.ariaSelected ? 'var(--rep-primary-light)' : 'transparent'};
  span {
    font-size: .8em;
    color:     var(--rep-primary);
  }
  &:hover {
    background: var(--rep-neutral-primary-light);
    cursor:     pointer;
  }
`

export const LoadMore = styled.div`
  border-top:      1px solid var(--rep-neutral-light);
  display:         flex;
  justify-content: center;
  padding:         8px;
  align-items:     center;
  color:           var(--rep-primary);
  &:hover {
    background: var(--rep-neutral-light);
    cursor: pointer;
  }
`

export const OptionTotal = styled.div`
  border-top:      1px solid var(--rep-neutral-light);
  display:         flex;
  justify-content: center;
  padding:         8px;
  align-items:     center;
  color:           var(--rep-neutral);
`

export const rotatingAnimation = keyframes`
    from  { transform: rotate(0deg)   }
    to    { transform: rotate(360deg) }
`

export const OptionsLoading = styled.div`
  display:                    flex;
  justify-content:            center;
  align-items:                center;
  height:                     3rem;
  border:                     1px solid var(--rep-primary);
  color:                      var(--rep-primary);
  border-bottom-left-radius:  5px;
  border-bottom-right-radius: 5px;
  svg {
    animation-name:             ${rotatingAnimation};
    animation-duration:         1s;
    animation-iteration-count:  infinite;
  }
`

export const SelectedContainer = styled.div`
  display:                    grid;
  grid-template-columns:      auto 10%;
  padding:                    8px;
  min-height:                 3rem;
  height:                     100%;
  border:                     1px solid var(--rep-primary-light);
  border-bottom:              ${props => props.opened ? 'none' : '1px solid var(--rep-primary-light)'};
  border-color:               ${props => props.opened ? 'var(--rep-primary)' : (props.required && !props.selected ? 'var(--rep-warning)' : 'var(--rep-primary-light)')};
  border-bottom-left-radius:  ${props => props.opened ? 'none' : '5px'};
  border-bottom-right-radius: ${props => props.opened ? 'none' : '5px'};
  border-top-left-radius:     5px;
  border-top-right-radius:    5px;
  position:                   relative;
  background:                 ${props => props.disabled ? 'var(--rep-neutral-light)' : 'initial'};
  &:hover { cursor: pointer }
`

export const SelectedItems = styled.div`
  display:   flex;
  flex-wrap: wrap;
  gap:       8px;
`

export const SelectInput = styled.input`
  outline:    none;
  border:     none;
  /* flex-grow:  1; */
  width:      calc(100% - 32px - ${props => props.selectedWidth || 0}px);
  height: 2rem;
  min-width: 200px;
  font-size:  ${props => props.selected ? 'initial' : '1rem'};
  background: ${props => props.disabled ? 'var(--rep-neutral-light)' : props.background ? 'var(--rep-neutral-light)' : 'white' };
  color: var(--rep-neutral-primary);
  border-radius: 5px;
  padding: 4px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${props => props.required && !props.hasValue ? 'var(--rep-warning)' : 'var(--rep-neutral-middle)'};
  }
  :-ms-input-placeholder {
    color: ${props => props.required && !props.hasValue ? 'var(--rep-warning)' : 'var(--rep-neutral-middle)'};
  }
  &:hover {
    /* cursor: ${props => props.multiselect ? 'text' : 'pointer' } */
    cursor: text;
  }
`

export const Caret = styled.div`
  position:      absolute;
  right:         0;
  top:           0;
  color:         var(--rep-primary);
  height:        100%;
  display:       flex;
  align-items:   center;
  padding-left:  16px;
  padding-right: 16px;
  border-left:   ${props => !props.opened ? '1px solid var(--rep-primary-light)' : 'none'};
`

export const CaretIcon = styled.div`
  transform:  ${props => props.opened ? 'rotate(180deg)' : 'none'};
  transition: .3s ease;
`

export const Selected = styled.div`
  border-radius: 5px;
  background:    ${props => props.multiselect ? 'var(--rep-primary-light)' : 'transparent' };
  color:         ${props => props.multiselect ? 'var(--rep-primary)' : 'var(--rep-neutral-primary)'};
  display:       flex;
  align-items:   center;
  gap:           4px;
  padding:       4px 8px;
  width:         fit-content;
  font-size:     1rem;
  &:hover { cursor: pointer; }
`

export const FilterOptions = styled.div`
  display:    flex;
  padding:    8px 4px;
  gap:        8px;
  background: ${props => props.selected ? 'var(--rep-primary-light)' : 'none'};
`

export const SearchHint = styled.div`
  color: var(--rep-neutral-primary);
  font-style: italic;
  text-align: center;
  padding: 8px 0;
`

export const NonSearchPlaceholder = styled.div`
  color: var(--rep-neutral-primary-middle);
  font-weight: 300;
  /* font-style: italic; */
  text-align: center;
  padding: 8px 0;
`
