import React, { useMemo, useReducer, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button           from '@components/Button'
import DatePicker       from '@form/DatePicker'
import FormTemplateForm from '@components/FormTemplate/Form'
import Input            from '@form/Input'
import MultiselectItem  from '@form/Select/MultiselectItem'
import Select           from '@form/Select'
import SelectDoerForm   from '@components/User/SelectDoerForm'
import Textarea         from '@form/Textarea'
import WorkPeriod       from '@components/WorkPeriod'

import * as FormStyle            from '@form/FormStyles'

import { useGlobalContextState } from '@context/GlobalContext'

import { TaskFormProps } from './types.d'

import { TASK_ACTIONS, taskReducer } from '@reducers/taskReducer'
import Form from '@components/Form/Form'
import Spacer from '@components/Spacer'

const TaskForm: React.FC<TaskFormProps> = ({
  report,
  task,
  assignTo,
  updatePeriod,
  deletePeriod,
  updateMethods
}) => {

  const {
    i18n,
    initAPI,
    current_company,
    setInfoWindowProps,
    fetchApi,
    closeModal,
  } = useGlobalContextState()

  const [_state, dispatch] = useReducer(taskReducer, { report, fetchApi, closeModal, updateMethods })

  const API = initAPI({ reportId: report.id })

  const [showUserForm,      setShowUserForm]      = useState(!task ? !!report?.suggested_user : !task?.work_periods)
  const [formsReply,        setFormsReply]        = useState(task?.forms || [])
  const [formsTemplates,    setFormsTemplates]    = useState([])
  const [formsCreated,      setFormsCreated]      = useState([])
  const [showQuestionForm,  setShowQuestionForm]  = useState(false)
  const [invoicingSelected, setInvoicingSelected] = useState(task ? [task.invoice_contact].filter(e => e) : (report.unit?.invoice_contact ? [report.unit.invoice_contact] : []))

  const addFormTemplates = forms => setFormsTemplates([...formsTemplates, ...forms.map(f => f.object)])
  const createForm       = form  => {
    setFormsCreated([...formsCreated, form])
    setInfoWindowProps({ item: null, type: 'form', target: 'modal'})
  }
  const removeFormReply    = form => setFormsReply(formsReply.filter(f => f.id !== form.id))
  const removeFormTemplate = form => setFormsTemplates(formsTemplates.filter(f => f.id !== form.id))
  const removeFormCreated  = form => setFormsCreated(formsCreated.filter(f => f.tempId !== form.tempId))

  const updateTask = (data, fieldset) => dispatch({
    type:      TASK_ACTIONS.UPDATE,
    data,
    task,
    fieldset,
    callbacks: [
      () => API.fetchReport().then(updateMethods.report),
      closeModal
    ]})

  const createTask = (data, fieldset) => dispatch({
    type:      TASK_ACTIONS.CREATE,
    data,
    report,
    fieldset,
    callbacks: [
      () => API.fetchReport().then(updateMethods.report)
    ],
    followUps: [() => setInfoWindowProps({})]
  })

  const callback = task?.id ? updateTask : createTask

  const defaultPeriod = useMemo(() => {
    if (!task?.id && report.maintenance_start_task) {
      const startDate = new Date(report.maintenance_start_task)
      const stopDate  = new Date(report.maintenance_start_task)
      if (task?.duration) { stopDate.setSeconds(stopDate.getSeconds() + task.duration) }
      return [startDate, stopDate]
    } else if (task?.planned_start && task?.planned_end) {
      return [task.planned_start, task.planned_end]
    } else {
      return []
    }
  }, [])

  return(
    <Form callback = {callback}>
      <FormStyle.Header>
        <FontAwesomeIcon icon="circle-info" />
        {i18n.t('shared.general_informations')}
      </FormStyle.Header>
      <Input
        type         = 'text'
        name         = 'title'
        label        = {i18n.t('shared.name')}
        defaultValue = {task ? task.title : report.title}
        marginY      = 'M'
        required
      />
      <Textarea
        name         = 'description'
        label        = {i18n.t('shared.description')}
        defaultValue = {task ? task.description : report.tasks.length ? '' : report.description}
        marginY      = 'M'
      />

      {current_company.permissions.see_estimated_time &&
        <>
          <FormStyle.Label>{i18n.t('todo.expected_duration')}</FormStyle.Label>
          <FormStyle.Group marginY="M">
            <Input
              name         = 'hours'
              label        = {i18n.t('shared.hours')}
              type         = 'number'
              defaultValue = {task ? Math.floor(task.duration / 3600) : undefined}
            />
            <Input
              name         = 'minutes'
              label        = {i18n.t('shared.minutes')}
              type         = 'number'
              min          = {0}
              max          = {59}
              maxLength    = {2}
              defaultValue = {task ? Math.floor((task.duration / 60) % 60) : undefined}
            />
          </FormStyle.Group>
        </>
      }

      {report.permissions.can_assign_task &&
        <>
          <Spacer size='l' />

          <FormStyle.Header marginY='S'>
            <FontAwesomeIcon icon="users" />{i18n.t('todo.actions.assign_to')}
          </FormStyle.Header>
          {task?.id && task.work_periods.map(period =>
            <WorkPeriod
              key          = {period.id}
              task         = {task}
              period       = {period}
              updatePeriod = {updatePeriod}
              deletePeriod = {deletePeriod}
              editable     = {task.permissions.unassign_task}
            />
          )}
          <Button
            icon    = {<FontAwesomeIcon icon={showUserForm ? 'angle-down' : 'plus' } />}
            click   = {() => setShowUserForm(!showUserForm)}
            marginY = 'S'
            color   = 'var(--rep-success)'
            // border  = {showUserForm ? '' : 'var(--rep-success)'}
          >
            {i18n.t('todo.actions.assign_task')}
          </Button>

          {showUserForm &&
            <SelectDoerForm
              assignedTo    = {assignTo ? assignTo : null}
              defaultUsers  = {task?.users || (report?.suggested_user?.type === 'internal' && [report?.suggested_user?.selected]) || []}
              defaultPeriod = {defaultPeriod ? defaultPeriod.map(d => new Date(d)) : [new Date(), new Date()]}
              suggested     = {report?.suggested_user}
              showTime      = {current_company.permissions.dispatch_task_with_hours || !!report.maintenance_start_task}
            />
          }
        </>
      }

      {current_company.permissions.can_see_forms &&
        <>
          <FormStyle.Header marginY='M'>
            <FontAwesomeIcon icon="list-check" />
            {i18n.t('form.forms')}
          </FormStyle.Header>

          <Select
            label        = {i18n.t('form.form_templates')}
            name         = 'form_templates'
            searchUrl    = '/form_templates/search'
            defaultFilter={'archived'}
            filters      = {[{
              id:      'archived',
              name:    'archived',
              filters: { archived: false }
            }]}
            defaultValue = {[]}
            callback     = {addFormTemplates}
            format       = {{ content: 'name', value: 'id', details: '' }}
            multiselect  = {true}
            placeholder  = {i18n.t('form.your_forms')}
            marginY      = 'S'
            search
            cleanAfterSelect
          />
          {formsReply.map(form =>
            <>
              <MultiselectItem
                key          = {form.id}
                name         = {form.name}
                icon         = {<FontAwesomeIcon icon="file-lines" />}
                confirmText  = {i18n.t('actions.confirm_delete')}
                removeAction = {() => removeFormReply(form)}
                click        = {() => current_company.beta_access && setInfoWindowProps({ item: form, type: 'form-templates', target: 'modal' })}
              />
              <Input type="hidden" name="form_replies[]" defaultValue={form.id} />
            </>
          )}
          {formsTemplates.map(form =>
            <>
              <MultiselectItem
                key          = {form.id}
                name         = {form.name}
                icon         = {<FontAwesomeIcon icon="file-lines" />}
                confirmText  = {i18n.t('actions.confirm_delete')}
                removeAction = {() => removeFormTemplate(form)}
                click        = {() => current_company.beta_access && setInfoWindowProps({ item: {...form, form_id: form.id}, type: 'form-templates', target: 'modal' })}
              />
              <Input type="hidden" name="form_templates[]" defaultValue={form.id} />
            </>
          )}

          {formsCreated.map((form, index) =>
            <div key={index}>
              <MultiselectItem
                key          = {form.tempId}
                name         = {form.name}
                icon         = {<FontAwesomeIcon icon="file-circle-plus" />}
                confirmText  = {i18n.t('actions.confirm_delete')}
                removeAction = {() => removeFormCreated(form)}
                click        = {() => current_company.beta_access && setInfoWindowProps({ item: form, type: 'form-templates', target: 'modal' })}
              />
              <Input type="hidden" name={`forms_created[${index}].name`}     defaultValue={form.name} />
              <Input type="hidden" name={`forms_created[${index}].template`} defaultValue={form.template} />
              {form.questions.map((question, qIndex) =>
                <>
                  <Input type="hidden" name={`forms_created[${index}].questions[${qIndex}].question`}       defaultValue={question.question} />
                  <Input type="hidden" name={`forms_created[${index}].questions[${qIndex}].answer_type`}    defaultValue={question.answer_type} />
                  {!!question.answer_options?.length &&question.answer_options.map((opt, oIndex) =>
                    <Input key={oIndex} type="hidden" name={`forms_created[${index}].questions[${qIndex}].answer_options[${oIndex}]`} defaultValue={opt} />
                  )}
                  <Input type="hidden" name={`forms_created[${index}].questions[${qIndex}].na_field`}       defaultValue={question.na} />
                  <Input type="hidden" name={`forms_created[${index}].questions[${qIndex}].comment_field`}  defaultValue={question.comment} />
                  <Input type="hidden" name={`forms_created[${index}].questions[${qIndex}].photo_field`}    defaultValue={question.photo} />
                  <Input type="hidden" name={`forms_created[${index}].questions[${qIndex}].hint`}           defaultValue={question.hint} />
                </>
              )}
            </div>
          )}

          {current_company.beta_access
            ? <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
              <Button
                icon    = {<FontAwesomeIcon icon='plus' />}
                color   = 'var(--rep-success)'
                click   = {() => setInfoWindowProps({
                  item: <FormTemplateForm
                    addForm         = {createForm}
                    canSaveTemplate = {report.permissions.can_create_form_template}
                  />,
                  type:   'form',
                  target: 'modal',
                  title:  i18n.t('form.actions.create')
                })}
                marginY = 'S'
              >
                {i18n.t('form.actions.create_form_template')}
              </Button>
            </div>
            : <>
              <Button
                icon    = {<FontAwesomeIcon icon={showQuestionForm ? 'angle-down' : 'plus'} />}
                click   = {() => setShowQuestionForm(!showQuestionForm)}
                marginY = 'S'
                color   = 'var(--rep-success)'
              >
                {i18n.t('form.actions.create')}
              </Button>
              {showQuestionForm &&
                <FormTemplateForm
                  addForm         = {createForm}
                  canSaveTemplate = {report.permissions.can_create_form_template}
                />
              }
            </>

          }
        </>
      }

      <FormStyle.Header marginY='M'>
        <FontAwesomeIcon icon="receipt" />{i18n.t('invoice_contact.invoice_contact')}
      </FormStyle.Header>

      <Select
        name         = 'invoice_contact'
        label        = {i18n.t('invoice_contact.your_invoice_contacts')}
        searchUrl    = '/invoice_contacts/search'
        defaultValue = {invoicingSelected}
        callback     = {invoicing => setInvoicingSelected([invoicing.object])}
        format       = {{ content: 'name', value: 'id', details: 'add_name' }}
        placeholder  = {i18n.t('invoice_contact.your_invoice_contacts')}
        marginY      = 'S'
        search
        withEmpty
      />

      <Spacer />

      <FormStyle.Header marginY='S'>
        <FontAwesomeIcon icon="border-none" />{i18n.t('todo.todo_expected_date')}
      </FormStyle.Header>

      <DatePicker
        name     = 'planned_end'
        date     = {task?.planned_end}
        tooltip  = {task?.work_periods?.length && i18n.t('todo.tooltip.automatic_planned_end')}
        disabled = {!!task?.work_periods?.length}
        marginY  = 'M'
      />

      <Spacer />
    </Form>
  )
}

export default TaskForm
