import styled from 'styled-components'

export const Progress = styled.progress`
  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
  &[value] {
    width:              100%;
    height:             16px;
    border:             none;
    background:         var(--rep-neutral-light);
    border-radius:      20px;
  }

  &[value]::-webkit-progress-value {
    height:             16px;
    border:             none;
    background-color:   ${props => props.color || 'var(--rep-success)'};
    border-radius:      20px;
    transition:         width .5s ease;
  }

  &[value]::-webkit-progress-bar {
    background-color: var(--rep-neutral-light);
    border-radius:    20px;
  }

  &[value]::-moz-progress-bar {
    border-radius: 20px;
    background:    ${props => props.color || 'var(--rep-success)'};
    transition:    width .5s ease;
  }
`
