import React, { useEffect, useState } from 'react'

import * as Style    from './style'
import IRadioButtons from './types.d'
import Tag           from '@components/Tag'

/**
 * @see Interface {@link IRadioButtons}
 * @description Radio buttons component. Requires a set of options.
 */
const RadioButtons: React.FC<IRadioButtons> = ({
  callback,
  defaultValue,
  icon,
  marginY,
  name,
  required,
  color        = 'var(--rep-neutral-primary)',
  labelColor   = 'var(--rep-neutral-primary)',
  labelContent = '',
  options      = [],
  tooltip      = '',
}) => {

  const [selected, setSelected] = useState(defaultValue || '')

  useEffect(() => { setSelected(defaultValue) }, [defaultValue])

  const selectValue = value => {
    ((selected === value) && !required) ? setSelected('') : setSelected(value)

    !!callback && callback(selected)
  }

  return (
    <Style.RadioButtonsContainer
      required = {required}
      marginY  = {marginY}
    >
      <Tag
        background     = 'transparent'
        color          = {labelColor}
        icon           = {icon}
        tooltipContent = {tooltip}
      >
        {labelContent}
      </Tag>

      <Style.OptionsContainer>
        {options.map((option) => (
          <Tag
            key        = {`${name}-${option.value}`}
            background = {selected === option.value ? 'var(--rep-primary-light)' : 'transparent'}
            border     = 'var(--rep-primary-light)'
            color      = {color}
            click      = {() => selectValue(option.value)}
            hover      = 'var(--rep-primary-light)'
          >
            <input
              type  = "radio"
              id    = {`${name}-${option.value}`}
              name  = {name}
              style = {{display: 'none'}}
              value = {option.value}
            />
            <label htmlFor={`${name}-${option.value}`}>
              {option.content}
            </label>
          </Tag>
        ))}
      </Style.OptionsContainer>
    </Style.RadioButtonsContainer>
  )
}

export default RadioButtons
