import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import toast from 'react-hot-toast'

const CREATE      = 'CONVERSATION_CREATE'
const ADD_MESSAGE = 'CONVERSATION_ADD_MESSAGE'
const ADD_FILE    = 'CONVERSATION_ADD_FILE'
const ADD_USER    = 'CONVERSATION_ADD_USER'
const REMOVE_USER = 'CONVERSATION_REMOVE_USER'

export const CONVERSATION_ACTIONS = {
  CREATE:      CREATE,
  ADD_MESSAGE: ADD_MESSAGE,
  ADD_FILE:    ADD_FILE,
  ADD_USER:    ADD_USER,
  REMOVE_USER: REMOVE_USER
}

export function conversationReducer(state, action) {

  const { fetchApi, updateMethods, closeModal } = state

  const fieldset    = action.fieldset
  const contentType = action.contentType ? action.contentType : 'application/json'

  switch (action.type) {
    case CREATE:
      fetchApi({
        url:       `/reports/${action.report.id}/conversations/rep_create.json`,
        method:    'POST',
        contentType,
        body:      action.data,
        fieldset,
        callback:  data => updateMethods.addConversation(data.response),
        followUps: [closeModal]
      })
      return state

    case ADD_MESSAGE:
      fetchApi({
        url:       `/conversations/${action.conversation.id}/rep_message.json`,
        method:    'POST',
        body:      { content: action.message },
        fieldset,
        callback:  data => updateMethods.conversation(data.response),
        followUps: [
          () => action.setIsSending(false)
        ]
      })
      return state

    case ADD_FILE:
      fetchApi({
        url:         `/conversations/${action.conversation.id}/rep_message`,
        method:      'POST',
        contentType: null,
        credentials: 'same-origin',
        fieldset,
        body:        action.file,
        withLoading: true,
        stringify:   false,
        callback:    data => updateMethods.conversation(data.response)
      })
      return state

    case ADD_USER:
      fetchApi({
        url:       `/conversations/${action.conversation.id}/rep_add_users.json`,
        method:    'PATCH',
        contentType,
        body:      action.data,
        fieldset,
        callback:  data => updateMethods.conversation(data.response),
        followUps: [closeModal]
      })
      return state

    case REMOVE_USER:
      fetchApi({
        url:       `/conversations/${action.conversation.id}/rep_remove_user.json`,
        method:    'PATCH',
        body:      action.data,
        fieldset,
        callback:  data => updateMethods.conversation(data.response),
        followUps: [closeModal]
      })
      return state

    default:
      if (window.env !== 'production') toast.error('Reducer action not defined', { icon: <FontAwesomeIcon icon="face-tired" />, iconTheme: { primary: 'var(--rep-danger)', secondary: 'var(--rep-danger-light)' } })
      return state

  }
}
