import React, { useReducer } from 'react'

import Checkbox   from '@form/Checkbox'
import DatePicker from '@form/DatePicker'
import Form       from '@components/Form/Form'
import Input      from '@form/Input'

import { useGlobalContextState }       from '@context/GlobalContext'
import { useMaintenancesContextState } from '@context/MaintenancesContext'

import { MAINTENANCE_ACTIONS, maintenanceReducer } from '@reducers/maintenanceReducer'

const DragDropForm = ({
  maintenance,
  reportId,
  date,
  fetchMainLine,
  fetchSubLine
}) => {

  const { i18n, fetchApi, closeModal } = useGlobalContextState()
  const { filters, MAINTENANCE_CONSTANTS }        = useMaintenancesContextState()

  const { VIEWS } = MAINTENANCE_CONSTANTS

  const [_state, dispatch] = useReducer(maintenanceReducer, {
    fetchApi,
    filters,
    closeModal,
    VIEWS,
  })

  const replanifyMaintenance = (data, fieldset) => {
    dispatch({
      type:      MAINTENANCE_ACTIONS.MOVE,
      maintenance,
      reportId,
      data,
      fieldset,
      callbacks: [
        () => fetchSubLine(),
        () => fetchMainLine()
      ],
    })
  }

  return (
    <Form
      callback   = {replanifyMaintenance}
      submitText = {i18n.t('maintenance.actions.plan_maintenance')}
    >
      <Input
        type         = "hidden"
        name         = "report_id"
        defaultValue = {reportId}
      />
      <DatePicker
        label = {i18n.t('report.expected_date')}
        name  = 'date'
        date  = {date}
        required
      />
      <Checkbox
        name         = 'notify'
        label        = {i18n.t('maintenance.form.notify_associated_change')}
        defaultValue = {true}
        marginY      = 'M'
      />
    </Form>
  )
}

export default DragDropForm
