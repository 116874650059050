import React, { useEffect, useReducer, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import FormQuestion from '@components/FormTemplate/FormQuestion/FormQuestion'
import Spacer       from '@components/Spacer'

import * as InfoStyle from '@components/InfoWindow/style'

import IForm from '@interfaces/IForm.d'

import { FORM_ACTIONS, formReducer } from '@reducers/formReducer'

import { useGlobalContextState } from '@context/GlobalContext'

interface IInfoFormTemplate {
  form:            IForm
  closeInfoWindow: () => void
}

const InfoFormTemplate = ({ form, closeInfoWindow }: IInfoFormTemplate) => {

  const { i18n, fetchApi } = useGlobalContextState()

  const [template, setTemplate] = useState(form)

  const [loading, setLoading] = useState(false)

  const [_formState, formDispatch] = useReducer(formReducer, { fetchApi })

  console.log(form)
  const fetchFormTemplate = () => {
    if (form.form_id) {
      formDispatch({
        type:        FORM_ACTIONS.FETCH_TEMPLATE,
        template_id: form.form_id,
        // filters:   { questions: true },
        callbacks:   [data => setTemplate(data), () => setLoading(false)]
      })
    } else {
      formDispatch({
        type:      FORM_ACTIONS.FETCH_REPLY,
        reply:     form,
        // filters:   { questions: true },
        callbacks: [data => setTemplate(data), () => setLoading(false)]
      })

    }
  }

  useEffect(() => {
    if (form.created) return
    if (loading) return
    setLoading(true)
    fetchFormTemplate()
  }, [form.id])

  if (loading || !template || !template.questions) return(<InfoStyle.Loader><FontAwesomeIcon icon="hourglass-half" spinPulse /></InfoStyle.Loader>)

  return(
    <div>
      <InfoStyle.InfoHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '4px 0' }}>
          <div></div>
          <InfoStyle.CloseIcon onClick={closeInfoWindow}>
            <FontAwesomeIcon icon="times" />
          </InfoStyle.CloseIcon>
        </div>
        <h2>{template.name}</h2>
      </InfoStyle.InfoHeader>

      <Spacer />

      {template.questions.map((question, key) =>
        <FormQuestion
          key      = {key}
          question = {question}
        />
        // <Style.QuestionContainer key={key}>
        // {/* <Style.QuestionName>
        //   <FontAwesomeIcon icon="list" />
        //   {question.question}
        // </Style.QuestionName>
        // <div style={{ display: 'flex', gap: '8px' }}>
        //   {!!question.hint &&
        //     <Tag
        //       icon           = {<FontAwesomeIcon icon="circle-exclamation" />}
        //       color          = {question.hint ? 'var(--rep-primary)' : 'var(--rep-neutral)'}
        //       border         = {question.hint ? 'var(--rep-primary)' : 'var(--rep-neutral)'}
        //       tooltipTitle   = {'Hint'}
        //       tooltipContent = {question.hint}
        //     />
        //   }
        //   <Tag
        //     icon           = {<FontAwesomeIcon icon="comment-dots" />}
        //     color          = {question.show_comment ? 'var(--rep-primary)' : 'var(--rep-neutral)'}
        //     border         = {question.show_comment ? 'var(--rep-primary)' : 'var(--rep-neutral)'}
        //     tooltipContent = {question.show_comment ? i18n.t('form.accept_comment') : i18n.t('form.reject_comment')}
        //   />
        //   <Tag
        //     icon           = {<FontAwesomeIcon icon="eye-slash" />}
        //     color          = {question.allow_not_applicable ? 'var(--rep-primary)' : 'var(--rep-neutral)'}
        //     border         = {question.allow_not_applicable ? 'var(--rep-primary)' : 'var(--rep-neutral)'}
        //     tooltipContent = {question.allow_not_applicable ? i18n.t('form.accept_na') : i18n.t('form.reject_na')}
        //   />
        // </div>
        // <Input
        //   name         = {`question-${key}`}
        //   type         = "hidden"
        //   defaultValue = {JSON.stringify(question)}
        // /> */}
        // </Style.QuestionContainer>
      )}

    </div>
  )
}

export default InfoFormTemplate
