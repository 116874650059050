import styled from 'styled-components'

export const RadioButtonsContainer = styled.fieldset`
  display:        flex;
  align-items:    center;
  gap:            8px;
  margin:         4px 0;
`

export const Label = styled.label`
  font-size:      .8rem;
  margin-left:    4px;
  color:          var(--rep-neutral-primary);
`

export const Required = styled.span`
  color: var(--rep-warning);
`

export const OptionsContainer = styled.div`
  display:        flex;
  flex-direction: row;
  flex-wrap:      wrap;
  gap:            4px;
`
