import React, { useCallback, useMemo, useReducer } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Avatar      from '@components/Avatar/Avatar'
import Button      from '@components/Button'
import Card        from '@components/Card'
import Dropdown    from '@components/Dropdown'
import Tag         from '@components/Tag/Tag'
import WithTooltip from '@components/WithTooltip/WithTooltip'

import ControlRemarkForm from './Form'
import ReportForm        from '../Report/Form'

import { useGlobalContextState } from '../../context/GlobalContext'

import * as Style from './style'

import ControlRemarkProps from './types.d'

import { REMARK_ACTIONS, remarkReducer } from '../../reducers'

const ControlRemark: React.FC<ControlRemarkProps> = ({
  remark,
  report,
  showOriginReport     = false,
  showFollowingReports = true,
  displayOptions       = true,
  updateMethods
}) => {

  const {
    current_company,
    fetchApi,
    i18n,
    showModal,
    closeModal,
    CONSTANTS,
    infoWindowProps,
    setInfoWindowProps
  } = useGlobalContextState()


  const [_state, dispatch] = useReducer(remarkReducer, { remark, updateMethods, fetchApi, closeModal })

  const openRemark    = () => dispatch({ type: REMARK_ACTIONS.OPEN,    remark })
  const closeRemark   = () => dispatch({ type: REMARK_ACTIONS.CLOSE,   remark })
  const destroyRemark = () => dispatch({ type: REMARK_ACTIONS.DESTROY, remark })

  const createReport = useCallback(() => showModal({
    title:   i18n.t('report.actions.create_report'),
    content: <ReportForm
      report = {{
        ...report,
        id:          null,
        title:       i18n.t('control_remarks.new_report_name', { control_name: report.title }),
        description: remark.description,
        remark:      remark
      }}
      updateMethods = {updateMethods}
    />
  }), [remark])

  const actions = useMemo(() => {
    const actionsArray = []
    actionsArray.push({
      icon:    <FontAwesomeIcon icon="file-export" />,
      content: i18n.t('report.actions.create_report'),
      click:   createReport
    })
    actionsArray.push({
      icon:       <FontAwesomeIcon icon="pen" />,
      color:      'var(--rep-primary)',
      content:    i18n.t('actions.edit'),
      background: 'var(--rep-primary-light)',
      click:      () => showModal({
        title:         i18n.t('control_remarks.actions.edit'),
        expected_date: null,
        content:       <ControlRemarkForm
          remark   = {remark}
          report   = {report}
          dispatch = {dispatch}
        />
      })
    })
    actionsArray.push({
      icon:       <FontAwesomeIcon icon="trash" />,
      color:      'var(--rep-danger)',
      background: 'var(--rep-danger-light)',
      content:    i18n.t('actions.destroy'),
      click:      destroyRemark
    })

    return actionsArray
  }, [remark])

  const statusIcon = ({
    [CONSTANTS.REMARK.STATUS.OPEN]:     <FontAwesomeIcon icon="section" />,
    [CONSTANTS.REMARK.STATUS.CLOSED]:   <FontAwesomeIcon icon="check" />,
    [CONSTANTS.REMARK.STATUS.CANCELED]: <FontAwesomeIcon icon="times" />
  })[remark.status] ?? <FontAwesomeIcon icon="section" />

  const openDetails = (object, type, target: 'page') => {
    current_company.beta_access
      ? setInfoWindowProps({ item: object, type: type, target: target })
      : window.open(`/${type}/${object.id}`, '_blank', 'noopener,noreferrer')
  }

  return(
    <Card
      border    = 'var(--rep-neutral-primary-light)'
      togglable = {false}
      headerTop = {
        <>
          {displayOptions
            ? <Dropdown
              options   = {actions}
              withArrow = {false}
              color     = 'var(--rep-primary)'
              icon      = {<FontAwesomeIcon icon="cog" />}
            />
            : <div></div>
          }
          <Style.RemarkStatus status={remark.status}>
            {statusIcon}
            {i18n.t(`control_remarks.${remark.status}`)}
          </Style.RemarkStatus>
        </>
      }
      bodyReduced = {
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '4px', margin: '4px 0', flexWrap: 'wrap' }}>
          {!!remark.following_reports.length &&
              showFollowingReports &&
              remark.following_reports.map((rep, key) =>
                <WithTooltip
                  key     = {key}
                  title   = {i18n.t('report.report_number')}
                  content = {rep.report_number}
                >
                  <Button
                    icon  = {<FontAwesomeIcon icon="file-export" />}
                    color = 'var(--rep-neutral-primary)'
                    hover = 'var(--rep-neutral-primary-light)'
                    click = {() => openDetails(rep, 'reports')}
                  >
                    {rep.title}
                  </Button>
                </WithTooltip>
              )
          }

          {showOriginReport &&
            <WithTooltip
              title   = {i18n.t('report.report_number')}
              content = {remark.report.report_number}
            >
              <Button
                icon  = {<FontAwesomeIcon icon="file-export" />}
                color = 'var(--rep-neutral-primary)'
                hover = 'var(--rep-neutral-primary-light)'
                click = {() => openDetails(remark.report, 'reports')}
              >
                {remark.report.title}
              </Button>
            </WithTooltip>
          }

          {!remark.following_reports.length && remark.status === CONSTANTS.REMARK.STATUS.OPEN &&
            <Tag
              icon  = {<FontAwesomeIcon icon="file-circle-plus" />}
              color = 'var(--rep-primary)'
              hover = 'var(--rep-primary-light)'
              click = {createReport}
            >
              {i18n.t('report.actions.create_report')}
            </Tag>
          }

          {!!displayOptions && remark.status === CONSTANTS.REMARK.STATUS.OPEN &&
            <Button
              icon  = {<FontAwesomeIcon icon="check" />}
              color = 'var(--rep-success)'
              hover = 'var(--rep-success-light)'
              click = {closeRemark}
            >
              {i18n.t('control_remarks.actions.close')}
            </Button>
          }

          {!!displayOptions && remark.status === CONSTANTS.REMARK.STATUS.CLOSED &&
            <Button
              icon  = {<FontAwesomeIcon icon="lock-open" />}
              color = 'var(--rep-warning)'
              hover = 'var(--rep-warning-light)'
              click = {openRemark}
            >
              {i18n.t('control_remarks.actions.reopen')}
            </Button>
          }
        </div>
      }
    >
      <Style.RemarkTitle>
        {remark.description}
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '4px', margin: '4px 0' }}>
          {!!remark.created_by &&
            <Avatar
              firstName  = {remark.created_by.first_name}
              lastName   = {remark.created_by.last_name}
              background = 'transparent'
              border     = 'var(--rep-neutral-primary-light)'
              color      = 'var(--rep-neutral-primary)'
              title      = {i18n.t('control_remarks.created_on', {date: new Date(remark.created_at).toLocaleDateString(i18n.locale)})}
            />
          }
          {!!remark.closed_by &&
            <Avatar
              firstName  = {remark.closed_by.first_name}
              lastName   = {remark.closed_by.last_name}
              background = 'transparent'
              border     = 'var(--rep-success-light)'
              color      = 'var(--rep-success)'
              title      = {i18n.t('control_remarks.closed_on', {date: new Date(remark.closing_date).toLocaleDateString(i18n.locale)})}
            />
          }
        </div>
      </Style.RemarkTitle>
    </Card>
  )

}

export default ControlRemark
