import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['cart', 'orderId', 'totalReceived']

  add(event) {
    const mat_id = event.target.getAttribute('data-mat-id')
    const value  = event.target.getAttribute('data-quantity')
    fetch(`/material_orders/${this.material_order_id}/add_quantity`,
      {
        method:  'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF_Token': this.csrfToken
        },
        body: JSON.stringify({ mat_id: mat_id, value: value })
      })
      .then(response => response.json())
      .then(data => {
        var existing_line = document.getElementById(`material_order_line_${data.response.id}`)
        var tableInput    = document.getElementById(`material_quantity_input_${data.response.material.id}`)
        if (tableInput) {
          tableInput.value = data.response.quantity <= 0 ? '' : data.response.quantity
        }
        if (existing_line && data.response.quantity <= 0) {
          return existing_line.remove()
        }
        this.updateMaterial(data, existing_line)
      })
  }

  add_value(event) {
    const mat_id = event.target.getAttribute('data-mat-id')
    const value  = event.target.value
    fetch(`/material_orders/${this.material_order_id}/update_quantity`,
      {
        method:  'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF_Token': this.csrfToken
        },
        body: JSON.stringify({ mat_id: mat_id, value: value })
      })
      .then(response => response.json())
      .then(data => {
        var existing_line = document.getElementById(`material_order_line_${data.response.id}`)
        var tableInput    = document.getElementById(`material_quantity_input_${data.response.material.id}`)
        if (tableInput && data.response.quantity <= 0) {
          tableInput.value = ''
        }
        if (existing_line && data.response.quantity <= 0) {
          return existing_line.remove()
        }
        this.updateMaterial(data, existing_line)
      })
  }

  editMaterial(e) {
    const lineId   = e.target.dataset.lineId
    const quantity = document.getElementById('material_order_line_quantity_ordered').value
    const price    = document.getElementById('material_order_line_unit_price_ordered').value

    fetch(`/material_order_lines/${lineId}`, {
      method:  'PATCH',
      headers: {
        'X-CSRF-Token': this.csrfToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        material_order_line: {
          quantity_ordered:   quantity,
          unit_price_ordered: price
        }
      })
    })
      .then(response => response.json())
      .then(data => {
        var existing_line = document.getElementById(`material_order_line_${data.response.id}`)
        var tableInput    = document.getElementById(`material_quantity_input_${data.response.material.id}`)
        if (tableInput) {
          tableInput.value = data.response.quantity <= 0 ? '' : data.response.quantity
        }
        if (existing_line && data.response.quantity <= 0) {
          existing_line.remove()
          document.getElementById('edit-line')
            .querySelector('.btn-close')
            .click()
          return
        }
        this.updateMaterial(data, existing_line)
        document.getElementById('edit-line')
          .querySelector('.btn-close')
          .click()
      })
  }

  updateMaterial(data, existing_line) {
    if (existing_line) {
      existing_line.querySelector('.mat_order_quantity').innerText   = `${window.i18n.t('material_order.quantity_ordered')}: ${data.response.quantity}`
      existing_line.querySelector('.mat_order_unit_price').innerText = `${window.i18n.t('material.unit_price')}: ${data.response.unit_price}`
      return
    }

    this.cart_list.insertAdjacentHTML('beforeend', `
        <li class="list-group-item list-group-item-action" id="material_order_line_${data.response.id}">
          <div class="card_content">
            <div class="card_title">
              ${data.response.material.name}
              <a target="_blank" href="/materials/${data.response.material.id}">
                <i class="fa-solid fa-arrow-up-right-from-square fa-xs ms-1"></i>
              </a>
            </div>
            <div class="card_address simple_text">
              ${window.i18n.t('material.brand')}: ${data.response.material.brand}
            </div>
            <div class="card_address simple_text mat_order_quantity">
              ${window.i18n.t('material_order.quantity_ordered')}: ${data.response.quantity}
            </div>
            <div class="card_address simple_text mat_order_unit_price">
              ${window.i18n.t('material.unit_price')}: ${data.response.unit_price}
            </div>
          </div>
          <div class="btn-container align-items-center">
            <a  href="/openModal?div=edit-line-content&material_order_line=${data.response.id}&modal=material_orders%2Fpartials%2Fedit_line&modal_id=edit-line-btn"
                class="hover-pointer"
                data-remote="true">
              <i class="fas fa-pen"></i>
            </a>
            <i  class="fas fa-trash-alt pointer text-primary ms-1 text-danger"
                data-mat-id="${data.response.material.id}"
                data-line-id="${data.response.id}"
                data-action="click->material-orders--manage-lines#remove"></i>
          </div>
        </li>
      `
    )
  }

  remove(event) {
    let lineId = event.target.getAttribute('data-line-id')

    if (lineId === null) {
      const matId = event.target.getAttribute('data-mat-id')
      const item  = this.cartTarget.querySelector(`.list-group-item [data-mat-id="${matId}"]`)
      lineId      = item.getAttribute('data-line-id')
    }

    fetch(`/material_order_lines/${lineId}`,
      {
        method:  'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF_Token': this.csrfToken
        }
      })
      .then(response => response.json())
      .then(data => {
        var tableInput = document.getElementById(`material_quantity_input_${data.response.material.id}`)
        if (tableInput) { tableInput.value = '' }
        document.getElementById(`material_order_line_${data.response.id}`).remove()
      })
  }

  cancel_delivery(event) {
    const material_order_line_id = event.target.getAttribute('data-item-id')
    fetch(`/material_order_lines/${material_order_line_id}/cancel_delivery`,
      {
        method:  'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF_Token': this.csrfToken
        }
      })
      .then(response => response.json())
      .then(data => {
        if (document.getElementById(`delivered-${material_order_line_id}`)) {
          document.getElementById(`delivered-${material_order_line_id}`).innerText  = window.i18n.t('material_order.status.cancelled')
          document.getElementById(`missing-${material_order_line_id}`).innerText    = window.i18n.t('material_order.status.cancelled')
          document.getElementById(`button-div-${material_order_line_id}`).innerHTML = ''
          document.getElementById(`button-div-${material_order_line_id}`).innerHTML = `
            <div class='btn btn-outline-primary' title="${window.i18n.t('actions.edit')}" data-item-id="${material_order_line_id}" data-action="click->material-orders--manage-lines#reopen">
              <i class="fas fa-edit" data-item-id="${material_order_line_id}"></i>
            </div>
          `
        }
        this.checkClosable(data.closable)
        this.checkCancellable(data.cancellable)
      })
  }

  reopen(event) {
    const material_order_line_id = event.target.getAttribute('data-item-id')
    fetch(`/material_order_lines/${material_order_line_id}/reopen_delivery`,
      {
        method:  'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF_Token': this.csrfToken
        }
      })
      .then(response => response.json())
      .then(data => {
        if (document.getElementById(`delivered-${material_order_line_id}`)) {
          this.totalReceivedTarget.innerText = data.total_received
          document.getElementById(`delivered-${material_order_line_id}`).innerText      = '0.0'
          document.getElementById(`missing-${material_order_line_id}`).innerText        = data.line.quantity_ordered
          document.getElementById(`received-total-${material_order_line_id}`).innerText = '0,00'
          document.getElementById(`button-div-${material_order_line_id}`).innerHTML     = ''
          document.getElementById(`button-div-${material_order_line_id}`).innerHTML     = `
            <div id="buttons-${material_order_line_id}" class="d-flex">
              <a data-remote="true" href="/openModal?div=partial-delivery-content&amp;material_order=${this.material_order_id}&amp;material_order_line=${material_order_line_id}&amp;modal=material_orders%2Fpartials%2Fdelivered&amp;modal_id=partial-delivery-btn">
                <div class="btn btn-outline-success me-1" title="${window.i18n.t('material_order.status.delivered')}">
                  <i class="fas fa-parachute-box"></i>
                </div>
              </a>
              <div class='btn btn-outline-danger' title="${window.i18n.t('material_order.status.cancelled_delivery')}" data-item-id="${material_order_line_id}" data-action="click->material-orders--manage-lines#cancel_delivery">
                <i class="fas fa-ban" data-item-id="${material_order_line_id}"></i>
              </div>
            </div>
          `
        }
        this.checkClosable(data.closable)
        this.checkCancellable(data.cancellable)
      })
  }

  checkClosable(closable) {
    if (closable === true) {
      document.getElementById('fully_delivered').classList.remove('hidden')
    } else {
      document.getElementById('fully_delivered').classList.add('hidden')
    }
  }

  checkCancellable(cancellable) {
    if (cancellable === true) {
      document.getElementById('cancel_order').classList.remove('hidden')
    } else {
      document.getElementById('cancel_order').classList.add('hidden')
    }
  }

  get material_order_id() {
    return this.orderIdTarget.getAttribute('data-order-id')
  }

  get cart_list() {
    return this.cartTarget
  }

  get csrfToken() {
    return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }

}
