import React, { useReducer, useState } from 'react'
import { FontAwesomeIcon }       from '@fortawesome/react-fontawesome'

import Avatar     from '@components/Avatar'
import Button     from '@components/Button'
import Form       from '@components/Form/Form'
import Select     from '@form/Select'

import * as Style                from './style'

import { useGlobalContextState } from '@context/GlobalContext'

import { IConversation }         from '@interfaces/index'

import { CONVERSATION_ACTIONS, conversationReducer } from '@reducers/index'

interface EditUserFormProps {
  conversation:  IConversation,
  updateMethods: object,
}

const EditUsersForm: React.FC<EditUserFormProps> = ({ conversation, updateMethods }) => {

  const {
    initAPI,
    i18n,
    fetchApi,
    closeModal
  } = useGlobalContextState()

  const [showUserForm, setShowUserForm] = useState(false)

  const [_state, dispatch] = useReducer(conversationReducer, { initAPI, fetchApi, updateMethods, closeModal })

  const addUser = (data, fieldset) => dispatch({
    type: CONVERSATION_ACTIONS.ADD_USER,
    conversation,
    data,
    fieldset
  })
  const removeUser = data => dispatch({
    type: CONVERSATION_ACTIONS.REMOVE_USER,
    conversation,
    data
  })

  return(
    <div>
      {conversation.participants.map(user =>
        <Style.UserLine key={user.id}>
          <Style.UserDisplay>
            <Avatar
              firstName = {user.first_name}
              lastName  = {user.last_name}
            />
            <Style.UserRole>{i18n.t(`shared.${user.role}`)}</Style.UserRole>
            <Style.UserName>{user.name}</Style.UserName>
          </Style.UserDisplay>
          {conversation.permissions.can_remove_users &&
            <Button
              icon       = {<FontAwesomeIcon icon="trash-alt" />}
              background = 'var(--rep-danger-light)'
              color      = 'var(--rep-danger)'
              click      = {() => removeUser({ user_id: user.id })}>
              {i18n.t('actions.remove')}
            </Button>
          }
        </Style.UserLine>
      )}
      {conversation.permissions.can_add_users &&
        <>
          <Button
            color   = 'var(--rep-success)'
            hover   = 'var(--rep-success-light)'
            icon    = {<FontAwesomeIcon icon="plus-square" />}
            click   = {() => setShowUserForm(!showUserForm)}
            marginY = 'M'
          >
            {i18n.t('conversation.actions.add_users')}
          </Button>

          {showUserForm &&
            <Form
              callback       = {addUser}
              submitText     = {i18n.t('conversation.actions.add_users')}
            >
              <Select
                name             = 'users'
                search           = {true}
                searchUrl        = '/users/search'
                label            = {i18n.t('conversation.actions.add_users')}
                defaultValue     = {[]}
                multiselect      = {true}
                format           = {{ content: 'name', value: 'id', details: 'role' }}
                detailsLocaleKey = 'shared'
                placeholder      = {i18n.t('user.users')}
                marginY          = 'M'
              />
            </Form>
          }
        </>
      }
    </div>
  )
}

export default EditUsersForm
