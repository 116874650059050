import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Tag from '@components/Tag'

import * as Style from './multiselectStyle'

import { useGlobalContextState } from '@context/GlobalContext'

interface MultiSelectProps {
  name:            string
  icon?:           React.ReactNode
  tooltip?:        string
  confirmText?:    string
  removeAction?:   (any?) => void
  click:           (any?) => void
  children?:       React.ReactNode
  colorName?:      string
  backgroundName?: string
  border?:         string
}

const MultiselectItem: React.FC<MultiSelectProps> = ({
  name,
  icon,
  tooltip,
  // confirmText,
  click,
  removeAction,
  colorName = 'var(--rep-neutral-primary)',
  backgroundName,
  children,
  border = 'var(--rep-neutral-primary-light)',
}) => {

  const { i18n } = useGlobalContextState()

  return(

    <Style.Item border={border}>
      <Style.ItemHeader>
        <Tag
          tooltipContent = {tooltip}
          icon           = {icon}
          background     = {backgroundName}
          color          = {colorName}
          click          = {() => click ? click() : null}
        >
          {name}
        </Tag>

        {!!removeAction &&
        <Style.removeIcon>
          <Tag
            click          = {removeAction}
            tooltipContent = {i18n.t('shared.actions.remove')}
            icon           = {<FontAwesomeIcon icon="times" />}
            hover          = 'var(--rep-danger-light)'
            color          = 'var(--rep-danger)'
            marginY        = 'S'
            // confirmText    = {confirmText}
            // confirmIcon    = {<FontAwesomeIcon icon="trash" />}
            // confirm
          />
        </Style.removeIcon>
        }
      </Style.ItemHeader>
      <Style.Content>{children}</Style.Content>
    </Style.Item>
  )
}

export default MultiselectItem
