import DatePicker from 'react-datepicker'
import React      from 'react'
import styled     from 'styled-components'

export const DatePickerContainer = styled.div`
  position: relative;
  width:    100%;
  margin-top: ${props => {
    switch (props.marginY) {
      case 'S': return 'var(--rep-spacer-s)'
      case 'M': return 'var(--rep-spacer-m)'
      case 'L': return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default: return '0'
    }
  }};
  margin-bottom: ${props => {
    switch (props.marginY) {
      case 'S': return 'var(--rep-spacer-s)'
      case 'M': return 'var(--rep-spacer-m)'
      case 'L': return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default: return '0'
    }
  }};


`

export const DatePickerWrapper = styled(({ className, ...props }) => (
  <DatePicker {...props} wrapperClassName={className} />
))`
  width: 100%;

  /* .react-datepicker__input-container {
    height: 300px;
  } */

  /* Style the input */
  .react-datepicker__input-container {
    /* Calendar Icon */
    .react-datepicker__calendar-icon {
      position:  absolute;
      font-size: 1em;
      top:       10px;
    }

    input {
      width:         100%;
      padding:       12px 24px;
      border:        1px solid ${props => props.readOnly ? 'var(--rep-neutral-primary-light)' : !props.complete && props.required ? 'var(--rep-warning)' : 'var(--rep-primary-light)'};
      background:    ${props => props.readOnly ? 'var(--rep-neutral-light)' : 'white'};
      border-radius: 4px;
      font-size:     16px;
      color:         var(--rep-neutral-primary);
      &[placeholder] {
        color: ${props => !props.complete && props.required ? 'var(--rep-warning)' : 'var(--rep-neutral-primary)'};
      }
      &:focus {
        border-color: ${props => !props.complete && props.required ? 'var(--rep-warning)' : 'var(--rep-primary)'};
        outline:      none;
      }
      &:hover {
        cursor: ${props => props.readOnly ? 'not-allowed' : 'pointer'};
      }
    }

    .react-datepicker__close-icon::after {
      color:      var(--rep-primary);
      background: transparent;
      font-size:  16px;
    }
  }
`

export const Label = styled.label`
  font-size:  12px;
  color:      var(--rep-neutral-primary);
  position:   absolute;
  top:        -12px;
  left:       12px;
  background: white;
  padding:    2px;
`

export const Calendar = styled.div`
  box-shadow: 0 6px 12px rgba(27, 37, 86, 0.16);
  border:     none;

  .react-datepicker__day {
    color: var(--rep-neutral-primary);
  }

  .react-datepicker__day--today {
    border:        1px solid var(--rep-primary);
    border-radius: 4px;
    &:hover {
      background: var(--rep-primary);
      color:    white;
    }
  }
  .react-datepicker__day--selected {
    background: var(--rep-primary);
    color:    white;
    &:hover {
      background: var(--rep-primary);
      color:    white;
    }
  }

  .react-datepicker__day--in-selecting-range {
    background: var(--rep-primary-light);
    color:      var(--rep-primary);
    &:hover {
      background: var(--rep-primary);
      color:    white;
    }
  }

  .react-datepicker__day--in-range {
    background: var(--rep-primary-light);
    color:      var(--rep-primary);
    &:hover {
      background: var(--rep-primary);
      color:    white;
    }
  }

  .react-datepicker__day--disabled {
    color: var(--rep-neutral-primary-middle);
  }

  .react-datepicker__day--outside-month {
    color: var(--rep-neutral);
  }

  li.react-datepicker__time-list-item--selected {
    background-color: var(--rep-primary) !important;
    &:hover {
      background-color: var(--rep-primary) !important;
    }
  }
`

export const Popper = styled.div`
  z-index:  120000;
  position: absolute;

  .react-datepicker__triangle {
    stroke: none;
  }

  .react-datepicker__navigation-icon::before {
    border-color: var(--rep-neutral-primary);
  }

  .react-datepicker {
    font-family: inherit;
    border-radius: 4px;
    color: var(--rep-neutral-primary);
    width: max-content;
  }

  /* Styling Calendar header */
  .react-datepicker__header {
    border-bottom: none;

    .react-datepicker__current-month {
      color: var(--rep-neutral-primary);
      text-transform: capitalize;
    }
    .react-datepicker__day-name {
      text-transform: capitalize;
      color: var(--rep-neutral-primary);
    }

    .react-datepicker__day--keyboard-selected:not([aria-disabled="true"]):hover {
      background: var(--rep-primary);
      color: white;
    }

    .react-datepicker .react-datepicker__month-container .react-datepicker__header {
      background-color: var(--rep-primary-light);
      border-bottom: none;
    }

    /* Style the selected date */
    .react-datepicker__day--selected {
      background-color: var(--rep-primary-light);
      color: var(--rep-primary);
      border-radius: 4px;

      &:hover {
        background-color: #2589db;
      }
    }

    /* Styling days in range */
    .react-datepicker__day--in-range {
      background-color: var(--rep-primary-light);
    }
  }
`
