import { Controller } from "@hotwired/stimulus"
export default class extends Controller {

  static targets = ["controllerDiv", "inputs", "buttons", "button", "free", "report", "charge_number", "lineType"]

  showChoice(event) {
    const choice = event.target.dataset.inputChoice
    this.inputsTarget.querySelectorAll(".exclusif_input").forEach((input) => {
      if (input.tagName === 'SELECT') {}
      else {
        input.value = "";
      }
    })

    Array.from(this.inputsTarget.children).forEach((div) => {
      if (div.id != choice) {
        div.classList.add("hidden")
      } else {
        div.classList.remove("hidden")
      }
    })

    Array.from(this.buttonsTarget.children).forEach((button) => {
      button.classList.remove("active")
    })

    event.target.classList.add("active")
    this.lineTypeTarget.value = choice
    this.checkValidate(choice)
  }

  checkInput(event) {
    if (event.target.value != "") {
      this.buttonTarget.classList.remove("hidden")
    } else {
      this.buttonTarget.classList.add("hidden")
    }
  }

  checkValidate(choice) {
    switch (choice) {
      case 'free':
        if (this.freeTarget.value != "") {
          this.buttonTarget.classList.remove("hidden")
        } else {
          this.buttonTarget.classList.add("hidden")
        }
        break;
      case 'report':
        if (this.reportTarget.value != "") {
          this.buttonTarget.classList.remove("hidden")
        } else {
          this.buttonTarget.classList.add("hidden")
        }
        break;
      case 'charge_number':
        if (this.charge_numberTarget.value != "") {
          this.buttonTarget.classList.remove("hidden")
        } else {
          this.buttonTarget.classList.add("hidden")
        }
        break;
    }
  }

  closeModal() {
    this.controllerDivTarget.closest('.modal-content').querySelector('.btn-close').click()
  }
}
