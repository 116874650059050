import React, { useMemo }  from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Avatar          from '@components/Avatar'
import AvatarList      from '@components/AvatarList'
import Button          from '@components/Button'
import Callout         from '@components/Callout'
import Dropdown        from '@components/Dropdown'
import EndReport       from '@components/Report/EndReport'
import FieldTag        from '@components/Form/FieldTag'
// import MachineStop     from '@components/MachineStop'
import ReportEdit      from '@components/Report/Form'
import RequestForm     from '@components/Material/Request/RequestForm'
import Tag             from '@components/Tag'
import WithTooltip     from '@components/WithTooltip'
import WorkorderPrint  from '@components/Report/WorkorderPrint'

import comburantImg    from './images/comburant.png'
import corrosiveImg    from './images/corrosive.png'
import environementImg from './images/environement.png'
import explosionImg    from './images/explosion.png'
import fuelImg         from './images/fuel.png'
import healthImg       from './images/health.png'
import nociveImg       from './images/nocive.png'
import pressureImg     from './images/pressure.png'
import toxicImg        from './images/toxic.png'

import { useGlobalContextState } from '@context/GlobalContext'
import { useReportContextState } from '@context/ReportContext'

import { REPORT_ACTIONS } from '@reducers/index'

import * as HeaderStyle from './style'
import ManagerActions from '../actions/managerActions'


const ReportHeader = () => {
  const {
    showModal,
    i18n,
    current_user,
    current_company,
    isDesktop,
    CONSTANTS,
    fetchApi,
    // closeModal,
    initAPI,
    setInfoWindowProps
  } = useGlobalContextState()

  const {
    report,
    reportDispatch,
    updateMethods
  } = useReportContextState()

  const { REPORT, REMARK, DANGER_ICONS, MACHINE_STOP } = CONSTANTS

  const API = initAPI({ reportId: report.id })

  const uncancelReport = () => reportDispatch({
    type:      REPORT_ACTIONS.UNCANCEL,
    report:    report,
    callbacks: [
      () => API.fetchTasks().then(updateMethods.tasks),
      () => API.fetchCosts().then(updateMethods.costs)
    ]
  })

  const generateFullReport  = () => reportDispatch({ type: REPORT_ACTIONS.GENERATE_FULL_REPORT, report })
  const generateCostsReport = () => reportDispatch({ type: REPORT_ACTIONS.GENERATE_COSTS_REPORT, report })

  const amenityTag =({
    [MACHINE_STOP.STATUS.STOPPED]: {
      content:    `(${i18n.t('machine_stop.status.stopped')})`,
      icon:       <FontAwesomeIcon icon="explosion" />,
      color:      'var(--rep-danger)',
      colorLight: 'var(--rep-danger-light)'
    },
    [MACHINE_STOP.STATUS.READY]: {
      content:    `(${i18n.t('machine_stop.status.ready')})`,
      icon:       <FontAwesomeIcon icon="wrench" />,
      color:      'var(--rep-warning)',
      colorLight: 'var(--rep-warning-light)'
    },
    [MACHINE_STOP.STATUS.RESTARTED]: {
      content:    `(${i18n.t('machine_stop.status.restarted')})`,
      icon:       <FontAwesomeIcon icon="plug-circle-check" />,
      color:      'var(--rep-success)',
      colorLight: 'var(--rep-success-light)'
    }
  })[report?.machine_stop?.status] ?? {
    content:    '',
    icon:       <FontAwesomeIcon icon="gear" />,
    color:      'var(--rep-primary)',
    colorLight: 'var(--rep-primary-light)'
  }

  const unitTag = ({
    [CONSTANTS.REPORT.TYPE.RECYCLE]: {
      icon:       <FontAwesomeIcon icon="cart-arrow-down" />,
      color:      'var(--rep-primary)',
      colorLight: 'var(--rep-primary-light)'
    },
    [CONSTANTS.REPORT.TYPE.MOVE]: {
      icon:       <FontAwesomeIcon icon="cart-arrow-down" />,
      color:      'var(--rep-primary)',
      colorLight: 'var(--rep-primary-light)'
    }
  })[report.type.hh_name] ?? {
    icon:       <FontAwesomeIcon icon="location-dot" />,
    color:      'var(--rep-primary)',
    colorLight: 'var(--rep-primary-light)'
  }

  const options = useMemo<object>(() => {
    return { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric'  }
  }, [])

  const displayWorkOrder = () => {
    if (current_user.managing) {
      return showModal({
        title:   i18n.t('report.work_order'),
        content: <WorkorderPrint report={report} />
      })
    }
    displayWasteReport
  }

  const displayWasteReport = () => {
    return fetchApi({
      url:      `/reports/${report.id}/generate_work_order?user_id=${current_user.id}&waste_report=true`,
      method:   'POST',
      callback: data => { window.open(`${report.id}/download_pdf?document_id=${data.response.attributes.table.id}`), '_blank', 'noopener,noreferrer' }
    })
  }

  const actions = useMemo(() => {
    const resultActions = []
    if (report.permissions.can_create_material_request) resultActions.push({
      icon:    <FontAwesomeIcon icon="code-pull-request" />,
      color:   'var(--rep-neutral-primary)',
      content: i18n.t('material_request.material_request'),
      click:   () => showModal({
        title:   i18n.t('material_request.actions.prepare_request'),
        content: <RequestForm report = {report} callback={() => API.fetchTasks(report.id).then(updateMethods.tasks)} />,
      })
    })

    if (report.permissions.can_access_intervention_report) resultActions.push({
      icon:    <FontAwesomeIcon icon="print" />,
      color:   'var(--rep-neutral-primary)',
      content: i18n.t('report.intervention_report'),
      click:   generateFullReport
    })

    if (report.permissions.can_access_intervention_report) resultActions.push({
      icon:    <FontAwesomeIcon icon="print" />,
      color:   'var(--rep-neutral-primary)',
      content: i18n.t('report.costs_report'),
      click:   generateCostsReport
    })

    if (report.permissions.can_access_work_order) resultActions.push({
      icon:    <FontAwesomeIcon icon="print" />,
      color:   'var(--rep-neutral-primary)',
      content: i18n.t('report.work_order'),
      click:   displayWorkOrder
    })

    if (report.type.hh_name === REPORT.TYPE.RECYCLE) resultActions.push({
      icon:    <FontAwesomeIcon icon="biohazard" />,
      color:   'var(--rep-neutral-primary)',
      content: i18n.t('report.report_types.recycle_list'),
      click:   displayWasteReport
    })

    if (report.permissions.can_update_report && !isDesktop) resultActions.push({
      icon:       <FontAwesomeIcon icon="edit" />,
      color:      'var(--rep-primary)',
      background: 'var(--rep-primary-light)',
      content:    report.permissions.can_prefilter_report && !report.permissions.can_manage_report && !report.responsible
        ? i18n.t('actions.assign')
        : i18n.t('actions.edit'),
      borderTop: true,
      click:     () => showModal({
        title:   i18n.t('report.edit_report'),
        content: <ReportEdit
          report        = {report}
          updateMethods = {updateMethods}
        />,
      })
    })

    if (report.permissions.can_cancel_report && !isDesktop) resultActions.push({
      icon:       <FontAwesomeIcon icon="trash-alt" />,
      color:      'var(--rep-danger)',
      background: 'var(--rep-danger-light)',
      content:    i18n.t('report.actions.cancel_report'),
      borderTop:  true,
      click:      () => showModal({
        title:   i18n.t('report.actions.cancel_report'),
        content: <EndReport
          step          = 'cancel'
          report        = {report}
          dispatch      = {reportDispatch}
          updateMethods = {updateMethods}
        />,
      })
    })
    if (report.permissions.can_uncancel_report && !isDesktop) resultActions.push({
      icon:       <FontAwesomeIcon icon="clock-rotate-left" />,
      color:      'var(--rep-warning)',
      background: 'var(--rep-warning-light)',
      content:    i18n.t('actions.reactivate'),
      borderTop:  true,
      click:      uncancelReport
    })
    return resultActions
  }, [report])

  const status = ({
    [CONSTANTS.REPORT.STATUS.NEW]:
      <Tag
        background     = 'var(--rep-primary-light)'
        color          = 'var(--rep-primary)'
        icon           = {<FontAwesomeIcon icon="star" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.ASSIGNED]:
      <Tag
        background     = 'var(--rep-accent-light)'
        color          = 'var(--rep-accent)'
        icon           = {<FontAwesomeIcon icon="users" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.ONGOING]:
      <Tag
        background     = 'var(--rep-success-light)'
        color          = 'var(--rep-success)'
        icon           = {<FontAwesomeIcon icon={['far', 'circle']} fade />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.DONE]:
      <Tag
        background     = 'var(--rep-warning-light)'
        color          = 'var(--rep-warning)'
        icon           = {<FontAwesomeIcon icon="clipboard-check" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t('report.status.done_tasks')}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.ON_HOLD]:
      <Tag
        background     = 'var(--rep-danger-light)'
        color          = 'var(--rep-danger)'
        icon           = {<FontAwesomeIcon icon="pause" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.TO_APPROVE]:
      <Tag
        background     = 'var(--rep-warning-light)'
        color          = 'var(--rep-success)'
        border          = 'var(--rep-success)'
        icon           = {<FontAwesomeIcon icon="check-to-slot" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t('report.to_be_validated_by', { name: report.validated_by?.name || '' })}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.APPROVED]:
      <Tag
        background     = 'var(--rep-success-light)'
        color          = 'var(--rep-success)'
        icon           = {<FontAwesomeIcon icon="check" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.CLOSED]:
      <Tag
        background     = 'var(--rep-neutral-light)'
        color          = 'var(--rep-neutral-primary)'
        icon           = {<FontAwesomeIcon icon="lock" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.CANCELED]:
      <Tag
        background     = 'var(--rep-danger-light)'
        color          = 'var(--rep-danger)'
        icon           = {<FontAwesomeIcon icon="ban" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>
  })[report.status] ?? <div></div>

  const managerActions = useMemo(() => {
    return <ManagerActions options={{edit: true }} />
  }, [report, report.permissions])

  const dangerIcons = useMemo(() => {
    const icons = []
    if (report.danger_icons.includes(DANGER_ICONS.PRESSURE))    icons.push({ icon: pressureImg,     content: i18n.t('offer_line.danger.pressure') })
    if (report.danger_icons.includes(DANGER_ICONS.EXPLOSION))   icons.push({ icon: explosionImg,    content: i18n.t('offer_line.danger.explosive') })
    if (report.danger_icons.includes(DANGER_ICONS.OXIDIZER))    icons.push({ icon: comburantImg,    content: i18n.t('offer_line.danger.comburant') })
    if (report.danger_icons.includes(DANGER_ICONS.FUEL))        icons.push({ icon: fuelImg,         content: i18n.t('offer_line.danger.fuel') })
    if (report.danger_icons.includes(DANGER_ICONS.CORROSIVE))   icons.push({ icon: corrosiveImg,    content: i18n.t('offer_line.danger.corrosive') })
    if (report.danger_icons.includes(DANGER_ICONS.NOCIVE))      icons.push({ icon: nociveImg,       content: i18n.t('offer_line.danger.nocive') })
    if (report.danger_icons.includes(DANGER_ICONS.TOXIC))       icons.push({ icon: toxicImg,        content: i18n.t('offer_line.danger.toxic') })
    if (report.danger_icons.includes(DANGER_ICONS.HEALTH))      icons.push({ icon: healthImg,       content: i18n.t('offer_line.danger.health') })
    if (report.danger_icons.includes(DANGER_ICONS.ENVIRONMENT)) icons.push({ icon: environementImg, content: i18n.t('offer_line.danger.environment') })
    return icons
  }, [report.danger_icons])

  const materialQuotations = useMemo(() => {
    const quotationOptions = []
    report.material_quotations.forEach((quotation) => {
      quotationOptions.push({
        icon:       <FontAwesomeIcon icon="arrow-up-right-from-square" />,
        content:    `${quotation.number || i18n.t('form.n/a')} - ${i18n.t(`material_quotation.status.${quotation.status}`)}`,
        color:      'var(--rep-primary)',
        background: 'var(--rep-neutral-primary-light)',
        click:      () => { window.open(`/material_quotations/${quotation.id}`, '_blank', 'noopener,noreferrer') }
      })
    })
    return quotationOptions
  }, [report, report.material_quotations])

  const materialOrders = useMemo(() => {
    const orderOptions = []
    report.material_orders.forEach((order) => {
      orderOptions.push({
        icon:       <FontAwesomeIcon icon="arrow-up-right-from-square" />,
        content:    `${order.number || i18n.t('form.n/a')} - ${i18n.t(`material_order.status.${order.status}`)}`,
        color:      'var(--rep-primary)',
        background: 'var(--rep-neutral-primary-light)',
        click:      () => { window.open(`/material_orders/${order.id}`, '_blank', 'noopener,noreferrer') }
      })
    })
    return orderOptions
  }, [report, report.material_orders])

  const openDetails = (object, type, fromModal = false) => {
    current_company.beta_access
      ? setInfoWindowProps({ item: object, type: type, target: 'page'})
      : window.open(`/${type}/${object.id}`, '_blank', 'noopener,noreferrer')
  }

  return (
    <HeaderStyle.HeaderContainer>
      <HeaderStyle.TopStyle>
        <HeaderStyle.TopTagsStyle>
          {!!report.urgent &&
            <Tag
              background     = 'var(--rep-danger)'
              border         = 'var(--rep-danger)'
              color          = 'white'
              icon           = {<FontAwesomeIcon icon="land-mine-on" size="sm"/>}
              tooltipContent = {i18n.t('report.urgent')}
            >
              {i18n.t('report.urgent')}
            </Tag>
          }
          {!!report.waste_category &&
            <Tag
              icon		       = {<FontAwesomeIcon icon="dumpster" />}
              color          = 'var(--rep-primary)'
              border         = 'var(--rep-primary)'
              tooltipContent = {i18n.t('waste_category.waste_category')}
            >
              {report.waste_category.description}
            </Tag>
          }
          <Tag
            icon           = {<FontAwesomeIcon icon="hashtag" />}
            color          = 'var(--rep-neutral-primary)'
            background     = 'var(--rep-neutral-light)'
            hover          = 'var(--rep-neutral-primary-light)'
            border         = 'white'
            tooltipContent = {i18n.t('report.report_number')}
            click          = {() => navigator.clipboard.writeText(report.report_number)}
          >
            {report.report_number}
          </Tag>
          {!!report.input_code &&
            <Tag
              color          = 'var(--rep-neutral-primary)'
              background     = 'var(--rep-neutral-light)'
              hover          = 'var(--rep-neutral-primary-light)'
              border         = 'white'
              tooltipContent = {i18n.t('charge_number.charge_number')}
            >
              {report.input_code.label}
            </Tag>
          }
          {!!report.category &&
            <Tag
              color          = 'var(--rep-neutral-primary)'
              background     = 'var(--rep-neutral-light)'
              hover          = 'var(--rep-neutral-primary-light)'
              tooltipContent = {i18n.t('expertise.expertise')}
            >
              {report.category.name}
            </Tag>
          }
          {!!report.anomaly &&
            <Tag
              icon           = {<FontAwesomeIcon icon="hammer" />}
              color          = 'var(--rep-neutral-primary)'
              background     = 'var(--rep-neutral-light)'
              hover          = 'var(--rep-neutral-primary-light)'
              tooltipContent = {i18n.t('anomaly.anomaly')}
            >
              {report.anomaly.name}
            </Tag>
          }
          {!!report.priority &&
            <Tag
              icon           = {<FontAwesomeIcon icon="exclamation-triangle" />}
              color          = 'var(--rep-neutral-primary)'
              background     = 'var(--rep-neutral-light)'
              hover          = 'var(--rep-neutral-primary-light)'
              tooltipContent = {i18n.t('report.priority')}
            >
              {report.priority.name}
            </Tag>
          }
          {!!report.deadline && report.status !== REPORT.STATUS.CANCELED &&
            <Tag
              icon           = {report.deadline_delta >= 0 ? <FontAwesomeIcon icon="calendar-day" /> : <FontAwesomeIcon icon="triangle-exclamation" />}
              color          = {report.deadline_delta >= 0 ? 'var(--rep-neutral-primary)' : 'var(--rep-danger)'}
              background     = {report.deadline_delta >= 0 ? 'var(--rep-neutral-light)' : 'var(--rep-danger-light)'}
              hover          = {report.deadline_delta >= 0 ? 'var(--rep-neutral-primary-light)' : 'var(--rep-danger-light)'}
              tooltipContent = {report.status !== REPORT.STATUS.CLOSED ? report.deadline_string :  i18n.t('report.deadline')}
              tooltipTitle   = {report.status !== REPORT.STATUS.CLOSED && i18n.t('report.deadline')}
            >
              {new Date(report.deadline).toLocaleDateString(i18n.locale)}
            </Tag>

          }
          {!!report.expected_date &&
            <Tag
              icon		       = {<FontAwesomeIcon icon="calendar-plus" />}
              color          = {report.expected_delta >= 0 ? 'var(--rep-neutral-primary)' : 'var(--rep-danger)'}
              background     = {report.expected_delta >= 0 ? 'var(--rep-neutral-light)' : 'var(--rep-danger-light)'}
              hover          = {report.expected_delta >= 0 ? 'var(--rep-neutral-primary-light)' : 'var(--rep-danger-light)'}
              tooltipContent = {report.status !== REPORT.STATUS.CLOSED ? report.expected_string : i18n.t('report.expected_date')}
              tooltipTitle   = {report.status !== REPORT.STATUS.CLOSED && i18n.t('report.expected_date')}
            >
              {new Date(report.expected_date).toLocaleDateString(i18n.locale)}
            </Tag>
          }
          {!!report.done_at &&
            <Tag
              icon		       = {<FontAwesomeIcon icon="clipboard-check" />}
              color          = {'var(--rep-warning)'}
              background     = {'var(--rep-warning-light)'}
              hover          = {'var(--rep-warning-light)'}
              tooltipTitle   = {i18n.t('report.done_at')}
              tooltipContent = {report.done_string}
            >
              {new Date(report.done_at).toLocaleDateString(i18n.locale)}
            </Tag>
          }
          {!!report.material_requests.length
            && report.material_requests.map(request =>
              <Tag
                key            = {request.id}
                icon           = {<FontAwesomeIcon icon='boxes-stacked' />}
                color          = {request.status === 'requested' ? 'var(--rep-warning)' : request.status === 'draft' ? 'var(--rep-neutral)' : 'var(--rep-success)'}
                background     = {request.status === 'requested' ? 'var(--rep-warning-light)' : request.status === 'draft' ? 'var(--rep-neutral-light)' : 'var(--rep-success-light)'}
                hover          = {request.status === 'requested' ? 'var(--rep-warning-light)' : request.status === 'draft' ? 'var(--rep-neutral-light)' : 'var(--rep-success-light)'}
                tooltipContent = {`[${request.status === 'draft' ? i18n.t('material_request.status.draft') : request.number}] Materials requested by ${request.requester.name}`}
                click          = {() => window.open(`/material_requests/${request.id}`, '_blank', 'noopener,noreferrer')}
              />
            )}
        </HeaderStyle.TopTagsStyle>
        <HeaderStyle.UsersStyles>
          {!isDesktop && status}
          {!!report.created_by &&
            <Avatar
              firstName       = {report.created_by?.first_name}
              lastName        = {report.created_by?.last_name}
              title           = {i18n.t('shared.created_by')}
              // background      = 'transparent'
              // border          = 'var(--rep-accent)'
              background      = 'var(--rep-accent-light)'
              color           = 'var(--rep-accent)'
              tooltipContent  = {
                <>
                  <div>
                    {i18n.t('report.created_by_on', { date: new Intl.DateTimeFormat(i18n.locale).format(new Date(report.created_at)), user: `${report.created_by?.first_name} ${report.created_by?.last_name}` })}
                  </div>
                  { report.created_by?.phone &&
                    <div>
                      {<FontAwesomeIcon icon="phone" />} {report.created_by?.phone}
                    </div>
                  }
                </>
              }
            />
          }
          {!!report.requester &&
            <Avatar
              firstName  = {report.requester?.first_name}
              lastName   = {report.requester?.last_name}
              title      = {i18n.t('shared.requested_by')}
              // background = 'transparent'
              // border     = 'var(--rep-neutral)'
              background = 'var(--rep-neutral-light)'
              color      = 'var(--rep-neutral)'
              tooltipContent  = {
                <>
                  <div>
                    {`${report.requester?.first_name} ${report.requester?.last_name}` }
                  </div>
                  {report.requester?.phone &&
                    <div>
                      <FontAwesomeIcon icon="phone" /> {report.requester?.phone}
                    </div>
                  }
                </>
              }
            />
          }
          {report.responsible &&
            <Avatar
              firstName    = {report.responsible?.first_name}
              lastName     = {report.responsible?.last_name}
              title        = {i18n.t('shared.responsible')}
              // background   = 'transparent'
              // border       = 'var(--rep-warning)'
              background   = 'var(--rep-warning-light)'
              color        = 'var(--rep-warning)'
              tooltipContent  = {
                <>
                  <div>
                    {`${report.responsible?.first_name} ${report.responsible?.last_name}` }
                  </div>
                  { report.responsible?.phone &&
                    <div>
                      {<FontAwesomeIcon icon="phone" />} {report.responsible?.phone}
                    </div>
                  }
                </>
              }
            />
          }
          {!!report.validated_by && report.validated_date &&
            <Avatar
              firstName  = {report.validated_by?.first_name}
              lastName   = {report.validated_by?.last_name}
              title      = {i18n.t('shared.validated_by')}
              background = 'transparent'
              border     = 'var(--rep-success)'
              color      = 'var(--rep-success)'
              tooltipContent  = {
                <>
                  <div>
                    {`${report.validated_by?.first_name} ${report.validated_by?.last_name}` }
                  </div>
                  { report.validated_by?.phone &&
                    <div>
                      {<FontAwesomeIcon icon="phone" />} {report.validated_by?.phone}
                    </div>
                  }
                </>
              } />
          }
          <AvatarList
            users    = {report.users}
            limit    = {3}
            title    = {i18n.t('report.assigned_to')}
          />
        </HeaderStyle.UsersStyles>
      </HeaderStyle.TopStyle>
      <HeaderStyle.MainTitleStyle>
        <HeaderStyle.ReportTitleStyle>
          <h1>{report.title}</h1>
        </HeaderStyle.ReportTitleStyle>

        {report.type.hh_name === CONSTANTS.REPORT.TYPE.RECYCLE && dangerIcons.map((danger, key) =>
          <WithTooltip content={danger.content} key={key}>
            <img src={danger.icon} height='28px' />
          </WithTooltip> )
        }

        {!!report.description &&
        <>
          <HeaderStyle.ReportDescription>
            <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap', marginBottom: '4px'}}>
              {!!report.custom_fields.length &&
                report.custom_fields.map(field =>
                  <FieldTag
                    key          = {field.id}
                    field        = {field}
                    background   = 'transparent'
                    withName
                    // value        = {report.custom_fields.find(f => f.field.id === field.id)?.value}
                  />
                )
              }
            </div>
            {report.description}
          </HeaderStyle.ReportDescription>
        </>
        }
      </HeaderStyle.MainTitleStyle>
      {[REPORT.STATUS.CLOSED, REPORT.STATUS.CANCELED].includes(report.status) &&
        <HeaderStyle.CalloutStyles>
          <Callout
            title       = {
              report.status === REPORT.STATUS.CANCELED
                ? i18n.t('report.canceled_on_by', { date: new Date(report.canceled_at).toLocaleDateString(i18n.locale, options), user: `${report.canceled_by?.first_name} ${report.canceled_by?.last_name}` })
                : report.ended_by
                  ? i18n.t('report.closed_on_by', { date: new Date(report.ended_at).toLocaleDateString(i18n.locale, options), user: `${report.ended_by?.first_name} ${report.ended_by?.last_name}` })
                  : i18n.t('report.closed_on', { date: new Date(report.ended_at).toLocaleDateString(i18n.locale, options)})
            }
            border      = 'none'
            background  = {report.status === REPORT.STATUS.CLOSED ? 'var(--rep-neutral-light)' : 'var(--rep-danger-light)'}
            color       = {report.status === REPORT.STATUS.CLOSED ? 'var(--rep-neutral-primary)' : 'var(--rep-danger)'}
            icon        = {<FontAwesomeIcon icon="lock" />}
            marginY     = 'S'
          >
            {report.closing_comment ? report.closing_comment : i18n.t('report.no_closing_comment')}
          </Callout>
        </HeaderStyle.CalloutStyles>
      }
      {report.archived &&
        <HeaderStyle.CalloutStyles>
          <Callout
            title       = {i18n.t('report.archived')}
            border      = 'none'
            background  = {'var(--rep-warning-light)'}
            color       = {'var(--rep-warning)'}
            icon        = {<FontAwesomeIcon icon="folder-open" />}
            marginY     = 'S'
          >
            {i18n.t('report.report_archived')}
          </Callout>
        </HeaderStyle.CalloutStyles>
      }
      <HeaderStyle.HeaderBottomStyles isDesktop={isDesktop}>
        <HeaderStyle.TagStyles>
          <Tag
            tooltipContent = {i18n.t('report.report_type')}
            icon           = {<FontAwesomeIcon icon={report.type.logo ?? 'triangle-exclamation'} />}
            color          = {report.type.color}
            background     = {`${report.type.color}20`}
          >
            {report.type.name}
          </Tag>
          {isDesktop && status}
          {!!report.unit &&
            <Tag
              icon            = {unitTag.icon}
              color           = {unitTag.color}
              background      = {unitTag.colorLight}
              hover           = {unitTag.colorLight}
              click           = {() => openDetails(report.unit, 'units')}
            >
              {report.unit.name}
            </Tag>
          }
          {!!report.amenity &&
            <Tag
              icon		        = {amenityTag.icon}
              color           = {amenityTag.color}
              background      = {amenityTag.colorLight}
              hover           = {amenityTag.colorLight}
              click           = {() => openDetails(report.amenity, 'amenities')}
            >
              {report.amenity.name} {amenityTag?.content}
            </Tag>
          }
          {!!report.drop_off &&
          <Tag
            icon		       = {<FontAwesomeIcon icon='truck-ramp-box' />}
            color          = 'var(--rep-primary)'
            tooltipContent = {i18n.t('report.drop_off')}
          >
            {report.drop_off}
          </Tag>
          }
          {!!report.location &&
          <Tag
            icon		       = {<FontAwesomeIcon icon='location-dot' />}
            color          = 'var(--rep-primary)'
            tooltipContent = {i18n.t('report.location')}
          >
            {report.location}
          </Tag>
          }
          {report.maintenance_plan &&
            <Tag
              icon		       = {<FontAwesomeIcon icon='file-contract' />}
              color          = 'var(--rep-primary)'
              hover          = 'var(--rep-primary-light)'
              tooltipContent = {i18n.t('maintenance_template.maintenance_template')}
              click          = {() => {
                report.amenity
                  ? window.open(`/amenities/${report.amenity.id}?detail=maintenance&maintenance_plan=${report.maintenance_plan.id}`, '_blank', 'noopener,noreferrer')
                  : window.open(`/units/${report.unit.id}?detail=maintenance&maintenance_plan=${report.maintenance_plan.id}`, '_blank', 'noopener,noreferrer')
              }}
            >
              {report.maintenance_plan.name}
            </Tag>
          }
          {report.remark?.id &&
            <Tag
              icon		       = {<FontAwesomeIcon icon="section" />}
              color          = {report.remark.status === REMARK.STATUS.OPEN ? 'var(--rep-warning)'                       : 'var(--rep-success)'}
              hover          = {report.remark.status === REMARK.STATUS.OPEN ? 'var(--rep-warning-light)'                 : 'var(--rep-success-light)'}
              tooltipContent = {report.remark.status === REMARK.STATUS.OPEN ? i18n.t('control_remarks.open_remark') : i18n.t('control_remarks.closed_remark')}
              click          = {() => { window.open(`/reports/${report.remark.report.id}`, '_blank', 'noopener,noreferrer') }}
            >
              {report.remark.description}
            </Tag>
          }
          {report.ext_url &&
            <Button
              icon           = {<FontAwesomeIcon icon="arrow-up-right-from-square" />}
              hover          = 'var(--rep-neutral-primary-light)'
              color          = 'var(--rep-neutral-primary)'
              click          = {() => { window.open(report.ext_url, '_blank', 'noopener,noreferrer') }}
            >
              {i18n.t('report.external_link')}
            </Button>
          }
          {!!report.material_quotations.length &&
            <Dropdown
              withArrow
              icon		        = {<FontAwesomeIcon icon="file-invoice-dollar" />}
              color           = 'var(--rep-neutral-primary)'
              background      = 'var(--rep-neutral-light)'
              hover           = 'var(--rep-neutral-primary-light)'
              options         = {materialQuotations}
            >
              {i18n.t('material_quotation.material_quotations')}
            </Dropdown>
          }
          {!!report.material_orders.length &&
            <Dropdown
              withArrow
              icon		        = {<FontAwesomeIcon icon="basket-shopping" />}
              color           = 'var(--rep-neutral-primary)'
              background      = 'var(--rep-neutral-light)'
              hover           = 'var(--rep-neutral-primary-light)'
              options         = {materialOrders}
            >
              {i18n.t('material_order.material_orders')}
            </Dropdown>
          }


        </HeaderStyle.TagStyles>
        <HeaderStyle.ActionStyles>
          {managerActions}
          {!!actions.length &&
            <Dropdown
              options = {actions}
              icon    = {<FontAwesomeIcon icon="bars" />}
              hover   = 'var(--rep-primary-light)'
            >
              {i18n.t('actions.other_actions')}
            </Dropdown>
          }
        </HeaderStyle.ActionStyles>
      </HeaderStyle.HeaderBottomStyles>
    </HeaderStyle.HeaderContainer>
  )
}

export default ReportHeader
