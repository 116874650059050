import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Style from './style'

import IAccordion from './types.d'

/**
 * @description Renders a togglable component
 * @example
 * <Accordion
 *   open      = {false}
 *   togglable = {true}
 *   header    = {<div>Click me!</div>}
 *  >
 *   I'm open !!
 *  </Accordion>
*/
const Accordion: React.FC<IAccordion> = ({
  open = false,
  togglable,
  onToggle,
  onClose,
  icon,
  header,
  action,
  border,
  counter,
  colorHeader = 'var(--rep-primary)',
  colorBody   = 'var(--rep-primary)',
  marginY     = 'M',
  background,
  children,
  noToggleRef
}) => {

  const [opened, setOpened] = useState(open || false)

  const actionRef = useRef(null)

  useEffect(() => {
    setOpened(open)
  }, [open])

  const handleClick = (e) => {
    e.preventDefault()
    if (actionRef.current.contains(e.target)) return
    if (!!noToggleRef?.current && noToggleRef?.current.contains(e.target)) return
    opened ? (!!onClose && onClose()) : (!!onToggle && onToggle())
    if (togglable) setOpened(!opened)
  }

  return (
    <Style.Accordion
      border      = {border}
      background  = {background}
      open        = {opened}
      marginY     = {marginY}
    >
      <Style.Header
        onClick     = {handleClick}
        colorHeader = {colorHeader}
      >
        {!!children  &&
          !!togglable &&
          <Style.Icon opened={opened}>
            <FontAwesomeIcon icon="angle-right" color={colorHeader} />
          </Style.Icon>
        }
        <Style.HeaderInformations>
          <Style.HeaderTitle>
            {icon}
            {header}
            {!!counter && <Style.Counter>{counter}</Style.Counter>}
          </Style.HeaderTitle>
          <div ref={actionRef}>
            {action}
          </div>
        </Style.HeaderInformations>
      </Style.Header>
      <Style.Body colorBody={colorBody}>
        {opened && children}
      </Style.Body>
    </Style.Accordion>
  )
}

export default Accordion
