import React from 'react'

import Form from '@components/Form/Form'

import Select     from '@components/Form/Select'

import * as FormStyle from '@form/FormStyles'

import { useGlobalContextState } from '@context/GlobalContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IAmenity from '@interfaces/IAmenity.d'
import IUnit from '@interfaces/IUnit.d'
import Input from '@components/Form/Input'

interface AddEquipmentToContractProps {
  equipment:     IAmenity | IUnit
  type:          'amenity' | 'unit'
  addToContract: (data: { [key: string]: any }, fieldset: React.RefObject<HTMLFieldSetElement>) => void
}

const AddEquipmentToContract: React.FC<AddEquipmentToContractProps> = ({ equipment, type, addToContract }) => {

  const { i18n } = useGlobalContextState()

  return(
    <Form
      callback       = {addToContract}
      submitText     = {i18n.t('actions.save')}
    >

      <Input
        type         = "hidden"
        name         = "equipment_id"
        defaultValue = {equipment.id}
      />

      <Input
        type         = "hidden"
        name         = "type"
        defaultValue = {type}
      />

      <FormStyle.Header>
        <FontAwesomeIcon icon="circle-info" />
        {i18n.t('contract.actions.add_equipment_to', { equipment: equipment.name })}
      </FormStyle.Header>

      <Select
        name             = 'id'
        label            = {i18n.t('contract.contract')}
        defaultValue     = {[]}
        searchUrl        = {'/contracts/rep_search'}
        filters = {[{
          id:      'excluded',
          name:    'excluded',
          filters: { exclude_type: equipment.class_plural, exclude_id: equipment.id}
        }]}
        defaultFilter = 'excluded'
        format           = {{ content: 'name', value: 'id'}}
        marginY          = 'M'
        search
        withEmpty
      />
    </Form>
  )
}

export default AddEquipmentToContract
