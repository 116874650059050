import React from 'react'
import toast from 'react-hot-toast'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CREATE = 'CONTRACT_CREATE'
const UPDATE = 'CONTRACT_UPDATE'
const LINK   = 'CONTRACT_LINK'
const UNLINK = 'CONTRACT_UNLINK'

export const CONTRACT_ACTIONS = {
  CREATE: CREATE,
  UPDATE: UPDATE,
  LINK:   LINK,
  UNLINK: UNLINK,
}

export function contractReducer(state, action) {

  const { fetchApi } = state

  const fieldset    = action.fieldset
  const contentType = action.contentType ? action.contentType : 'application/json'
  const callbacks   = action.callbacks || []

  switch (action.type) {
    case CREATE:
      fetchApi({
        url:       '/contracts/rep_create',
        method:    'POST',
        contentType,
        body:      action.data,
        followUps: callbacks,
        fieldset,
      })
      return state

    case UPDATE:
      fetchApi({
        url:       `/contracts/${action.reducerProps.id}/rep_update`,
        method:    'PATCH',
        contentType,
        body:      action.data,
        followUps: callbacks,
        fieldset,
      })
      return state

    case LINK:
      fetchApi({
        url:       '/contracts/rep_link',
        method:    'POST',
        contentType,
        body:      action.data,
        followUps: callbacks,
        fieldset
      })

      return state

    case UNLINK:
      fetchApi({
        url:       `/contracts/${action.data.id}/rep_unlink`,
        method:    'DELETE',
        body:      action.data,
        followUps: callbacks,
      })
      return state

    default:
      if (window.env !== 'production') {
        console.error(action)
        toast.error('Reducer action not defined', { icon: <FontAwesomeIcon icon="face-tired" />, iconTheme: { primary: 'var(--rep-danger)', secondary: 'var(--rep-danger-light)' } })
      }
      return state
  }
}
