import React from 'react'
import toast from 'react-hot-toast'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ADD_REQUEST = 'MATERIAL_ADD_REQUEST'

export const MATERIAL_ACTIONS = {
  ADD_REQUEST: ADD_REQUEST,
}

export function materialReducer(state, action) {

  const { fetchApi } = state

  const callbacks   = action.callbacks || []
  const fieldset    = action.fieldset
  const contentType = action.contentType ? action.contentType : 'application/json'

  switch (action.type) {
    case ADD_REQUEST:
      fetchApi({
        url:       '/material_requests.json',
        method:    'POST',
        contentType,
        body:      action.data,
        fieldset,
        followUps: callbacks
      })
      return state

    default:
      if (window.env !== 'production') {
        console.error(action)
        toast.error('Reducer action not defined', { icon: <FontAwesomeIcon icon="face-tired" />, iconTheme: { primary: 'var(--rep-danger)', secondary: 'var(--rep-danger-light)' } })
      }
      return state
  }
}
