import React, { useReducer, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DatePicker          from '@components/Form/DatePicker'
import FieldInput          from '@components/Form/FieldInput'
import Form                from '@components/Form/Form'
import Input               from '@components/Form/Input'
import Select              from '@components/Form/Select'
import SelectEquipmentForm from './SelectEquipmentForm'
import Spacer              from '@components/Spacer'
import Tag                 from '@components/Tag'
import Textarea            from '@components/Form/Textarea'

import * as FormStyle from '@form/FormStyles'

import IAmenity from '@interfaces/IAmenity.d'

import { useGlobalContextState } from '@context/GlobalContext'

import { AMENITY_ACTIONS, amenityReducer } from '@reducers/amenityReducer'

interface AmenityFormProps {
  amenity:       IAmenity,
  updateMethods: object
}

const AmenityForm: React.FC<AmenityFormProps> = ({ amenity, updateMethods }) => {

  const { i18n, fetchApi, closeModal, setInfoWindowProps } = useGlobalContextState()

  const [parent, setParent] = useState(amenity.unit)
  const [amenityType, setAmenityType] = useState(amenity.type)

  const [_state, dispatch] = useReducer(amenityReducer, { amenity, fetchApi, updateMethods, closeModal, setInfoWindowProps })

  const createAmenity = (data, fieldset) => dispatch({
    type:      AMENITY_ACTIONS.CREATE,
    form:      true,
    // contentType: 'multipart/form-data',
    data,
    fieldset,
    callbacks: [closeModal]
  })

  const updateAmenity = (data, fieldset) => dispatch({
    type:      AMENITY_ACTIONS.UPDATE,
    // contentType: 'multipart/form-data',
    data,
    amenity,
    fieldset,
    callbacks: [closeModal]
  })

  const addFormData = formData => formData.append('unit_id', parent?.id ? parent?.id.toString() : '')
  const callback = amenity?.id ? updateAmenity : createAmenity

  return (
    <Form
      addFormData    = {addFormData}
      callback       = {callback}
      submitText     = {i18n.t('actions.save')}
      submitDisabled = {(amenity?.id && !amenity.permissions.can_update_amenity)}
    >

      {/** General informations */}
      <FormStyle.Header marginY='M'>
        <FontAwesomeIcon icon="circle-info" />{i18n.t('shared.general_informations')}
      </FormStyle.Header>
      <Input
        name         = 'name'
        label        = {i18n.t('amenity.name')}
        type         = 'text'
        defaultValue = {amenity.name}
        marginY      = 'M'
      />

      <Textarea
        name         = "description"
        label        = {i18n.t('shared.description')}
        defaultValue = {amenity.description}
        marginY      = 'M'
      />

      <Input
        name         = "brand"
        label        = {i18n.t('amenity.brand')}
        type         = "text"
        marginY      = 'M'
        defaultValue = {amenity.brand}
      />

      <Input
        name         ="category"
        label        = {i18n.t('amenity.category')}
        type         = "text"
        marginY      = 'M'
        defaultValue = {amenity.model}
      />

      <Input
        name         = "serial_number"
        label        = {i18n.t('amenity.serial_number')}
        type         = "text"
        marginY      = 'M'
        defaultValue = {amenity.serial_number}
      />

      <Input
        name         = "internal_identifier"
        label        = {i18n.t('amenity.internal_identifier')}
        type         = "text"
        marginY      = 'M'
        defaultValue = {amenity.internal_identifier}
      />

      {/** Amenity type informations */}
      <FormStyle.Header marginY='M'>
        <FontAwesomeIcon icon="passport" />{i18n.t('shared.category')}
      </FormStyle.Header>
      <Select
        name         = 'amenity_type'
        label        = {i18n.t('amenity.amenity_type_long')}
        search       = {true}
        searchUrl    = '/amenity_types/rep_search'
        defaultValue = {[amenityType]}
        format       = {{ content: 'name', value: 'id' }}
        marginY      = 'M'
        callback     = {(data) => setAmenityType(data.object)}
      />

      {!!amenityType?.custom_fields?.length &&
        amenityType.custom_fields.map((field, index) =>
          <FieldInput
            key          = {field.id}
            field        = {field}
            idName       = {`fields[${index}].id`}
            name         = {`fields[${index}].value`}
            defaultValue = {amenity?.id ? amenity.custom_fields.find(f => f.id === field.id)?.value : null}
            marginY      = 'M'
            disabled     = {amenity?.id && !amenity.permissions.can_update_amenity}
          />)
      }

      {/** Location informations */}
      <FormStyle.Header marginY='M'>
        <FontAwesomeIcon icon="screwdriver-wrench" />{i18n.t('amenity.installation')}
      </FormStyle.Header>

      <FormStyle.EquipmentDisplayer selected={!!parent}>
        <Tag
          background     = 'var(--rep-neutral-light)'
          color          = 'var(--rep-neutral)'
          icon           = {<FontAwesomeIcon icon={parent ? 'building' : 'times'} />}
          tooltipContent = {parent?.path_string}
          click          = {() => !!parent && setInfoWindowProps({ item: parent, type: 'units', target: 'modal' })}
        >
          {parent?.name || i18n.t('unit.missing')}
        </Tag>
      </FormStyle.EquipmentDisplayer>

      <SelectEquipmentForm
        units              = {parent ? [parent] : []}
        amenityFieldOption = {'hidden'}
        checkCallback      = {newParent => setParent(newParent)}
        uncheckCallback    = {() => setParent(null)}
        autoSelectUnit
        callToAction       = {i18n.t('amenity.actions.select_unit')}
      />

      <Spacer size='m' />
      <DatePicker
        name  = "installation_date"
        label = {i18n.t('amenity.installation_date')}
        date  = {amenity.installation_date}
      />

      <Spacer size='m' />
      <DatePicker
        name  = "expiration_date"
        label = {i18n.t('amenity.expiration_date')}
        date  = {amenity.expiration_date}
      />

      <Input
        name         = "price"
        label        = {i18n.t('amenity.price')}
        type         = "number"
        defaultValue = {amenity.price}
        marginY      = 'M'
      />

      <Input
        name         ="quantity"
        label        = {i18n.t('amenity.quantity')}
        type         = "number"
        defaultValue = {amenity.quantity}
        marginY      = 'M'
      />

      {/** Custom fields */}
      {!!amenity.custom_fields?.length &&
        <>
          <FormStyle.Header marginY='S'><FontAwesomeIcon icon="list" />{i18n.t('custom_field.custom_fields')}</FormStyle.Header>
          {amenity.custom_fields.map((field, index) =>
            <FieldInput
              key          = {field.id}
              field        = {field}
              idName       = {`fields[${index}].id`}
              name         = {`fields[${index}].value`}
              defaultValue = {field.value}
              marginY      = 'M'
            />)
          }
        </>
      }
    </Form>
  )
}

export default AmenityForm
