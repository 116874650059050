import React from 'react'

import * as Styles   from '../style'
import { IDocument } from '@interfaces/index'
import Thumbnail     from '../Thumbnail'

interface IThumbnailGrid {
  destroyable?:      boolean,
  files?:            IDocument[]
  grid?:             boolean
  gridSize?:         number
  thumbnailDestroy?: boolean
  updateMethods:     { [key:string]: (any) => void }
}

const ThumbnailGrid: React.FC<IThumbnailGrid> = ({
  updateMethods,
  destroyable,
  thumbnailDestroy,
  gridSize,
  files = [],
  grid  = true,
}) => {

  return (
    <Styles.FilesStyles grid={grid} gridSize={gridSize}>
      {files.map(file =>
        <Thumbnail
          key             = {file.id}
          file            = {file}
          updateMethods   = {updateMethods}
          destroyable     = {destroyable}
          thumbnailDestroy = {thumbnailDestroy}
        />
      )}
    </Styles.FilesStyles>
  )
}

export default ThumbnailGrid
