import React, { useEffect, useMemo, useRef, useState }  from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Style from './style'

import InfoAmenity      from './Components/InfoAmenity'
import InfoForm         from './Components/InfoForm/InfoForm'
import InfoFormTemplate from './Components/InfoFormTemplate'
import InfoOngoing      from './Components/InfoOngoing'
import InfoReport       from './Components/InfoReport/InfoReport'
import InfoUnit         from './Components/InfoUnit'
import InfoUser         from './Components/InfoUser/InfoUser'

import { AmenityContextProvider } from '@context/AmenityContext'
import { ReportContextProvider }  from '@context/ReportContext'
import { UnitContextProvider }    from '@context/UnitContext'
import { useGlobalContextState }  from '@context/GlobalContext'

/* Targets :
* page  => Normal page
* modal => Modal
*/

interface IInfoWindow {
  name: string
}

const InfoWindow: React.FC<IInfoWindow> = ({ name }) => {

  const {
    isDesktop,
    infoWindowProps,
    setInfoWindowProps
  } = useGlobalContextState()

  const [infoHistory,  setInfoHistory]  = useState([])
  const [sidebarWidth, setSidebarWidth] = useState(500)
  const [resource,     setResource]     = useState(infoWindowProps)
  const [readOnly,     setReadOnly]     = useState(resource?.readOnly)

  const sidebarRef = useRef(null)

  useEffect(() => {
    if (name === infoWindowProps?.target) {
      setResource(infoWindowProps)
      setInfoHistory([...infoHistory, resource])
      setReadOnly(resource?.readOnly)
    }
  }, [infoWindowProps])

  const closeInfoWindow = () => {
    setInfoWindowProps({
      item:   null,
      type:   infoWindowProps?.type,
      target: infoWindowProps?.target
    })

    setInfoHistory([])
    setReadOnly(false)

    const urlParams = new URLSearchParams(window.location.search)
    urlParams.delete('info_type')
    urlParams.delete('info_id')
    window.history.replaceState(null, '', `${window.location.pathname}?${urlParams.toString()}`)
  }

  const historyBack = () => {
    const newHistory       = [...infoHistory]
    const previousResource = newHistory.slice(-2)[0]
    newHistory.splice(-1, 1)
    setResource(previousResource)
    setInfoHistory(newHistory)
  }

  const infoWindowTypeComponent = useMemo(() => {
    switch(resource?.type) {
      case 'units': return (
        <UnitContextProvider serverUnit={resource.item}>
          <InfoUnit
            readOnly        = {readOnly}
            target          = {resource.target}
            sidebarWidth    = {sidebarWidth}
            infoHistory     = {infoHistory}
            historyBack     = {historyBack}
            closeInfoWindow = {closeInfoWindow}
          />
        </UnitContextProvider>
      )

      case 'amenities': return (
        <AmenityContextProvider serverAmenity={resource.item}>
          <InfoAmenity
            readOnly        = {readOnly}
            target          = {resource.target}
            sidebarWidth    = {sidebarWidth}
            infoHistory     = {infoHistory}
            historyBack     = {historyBack}
            closeInfoWindow = {closeInfoWindow}
          />
        </AmenityContextProvider>
      )

      case 'ongoing': return (
        <InfoOngoing equipment={resource.item} />
      )

      case 'users': return (
        <InfoUser item={resource.item} />
      )

      case 'reports': return (
        <ReportContextProvider serverReport={resource.item}>
          <InfoReport
            readOnly        = {readOnly}
            target          = {resource.target}
            sidebarWidth    = {sidebarWidth}
            infoHistory     = {infoHistory}
            historyBack     = {historyBack}
            closeInfoWindow = {closeInfoWindow}
          />
        </ReportContextProvider>
      )

      case 'form': return(
        <InfoForm
          title           = {resource.title}
          closeInfoWindow = {closeInfoWindow}
        >
          {resource.item}
        </InfoForm>
      )

      case 'form-templates': return (
        <InfoFormTemplate
          form            = {resource.item}
          closeInfoWindow = {closeInfoWindow}
        />
      )

      default:  return(
        <div>Type not found.</div>
      )
    }
  }, [resource])

  const rsMouseDownHandler = (e) => {
    const x = e.clientX
    const sbWidth = window.getComputedStyle(sidebarRef.current).width
    const initialWidth = parseInt(sbWidth, 10)

    const mouseMoveHandler = (e) => {
      document.body.classList.add('moving')
      const dx = x - e.clientX // Resize from left to right
      const newWidth = initialWidth + dx

      if (newWidth >= 350 && newWidth <= (window.innerWidth * 0.8)) {
        setSidebarWidth(newWidth)
      }
    }

    const mouseUpHandler = () => {
      document.body.classList.remove('moving')
      document.removeEventListener('mouseup',   mouseUpHandler)
      document.removeEventListener('mousemove', mouseMoveHandler)
    }

    document.addEventListener('mousemove', mouseMoveHandler)
    document.addEventListener('mouseup',   mouseUpHandler)
  }
  if (resource.target !== name) { return }
  if (!resource.item)           { return }
  return (
    <>
      {resource?.item?.id && isDesktop && name === 'page' &&
        <Style.InfoWindowResizer
          right       = {sidebarWidth}
          onMouseDown = {rsMouseDownHandler}
        >
          <FontAwesomeIcon icon="grip-lines-vertical" />
        </Style.InfoWindowResizer>
      }

      <Style.InfoWindow
        ref            = {sidebarRef}
        width          = {sidebarWidth}
        isDesktop      = {isDesktop}
        displayed      = {resource.target === name}
        fromModal      = {resource.target === 'modal'}
      >
        <Style.InfoContent fromModal = {resource.target === 'modal'}>
          {infoWindowTypeComponent}
        </Style.InfoContent>
      </Style.InfoWindow>
    </>
  )
}

export default InfoWindow
