import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import toast from 'react-hot-toast'

import { buildFilterString } from '@utils/filterString'

const FETCH_MAINLINES                  = 'MAINTENANCE_FETCH_MAINLINES'
const FETCH_MAINLINE                   = 'MAINTENANCE_FETCH_MAINLINE'
const FETCH_SUBLINES                   = 'MAINTENANCE_FETCH_SUBLINES'
const FETCH_SUBLINE                    = 'MAINTENANCE_FETCH_SUBLINE'
const CREATE_PLAN                      = 'MAINTENANCE_CREATE_PLAN'
const UPDATE_PLAN                      = 'MAINTENANCE_UPDATE_PLAN'
const ADD_PLAN_EQUIPMENTS              = 'MAINTENANCE_ADD_PLAN_EQUIPMENTS'
const UPDATE_PLAN_EQUIPMENTS           = 'MAINTENANCE_UPDATE_PLAN_EQUIPMENTS'
const UPDATE_MAINTENANCE               = 'MAINTENANCE_UPDATE_MAINTENANCE'
const PLANIFY                          = 'MAINTENANCE_PLANIFY'
const MOVE                             = 'MAINTENANCE_MOVE'
const ARCHIVE_MAINTENANCE              = 'MAINTENANCE_ARCHIVE_MAINTENANCE'
const UNARCHIVE_MAINTENANCE            = 'MAINTENANCE_UNARCHIVE_MAINTENANCE'
const ARCHIVE_MAINTENANCE_PLAN         = 'MAINTENANCE_ARCHIVE_MAINTENANCE_PLAN'
const UNARCHIVE_MAINTENANCE_PLAN       = 'MAINTENANCE_UNARCHIVE_MAINTENANCE_PLAN'
const DESTROY_MAINTENANCE              = 'MAINTENANCE_DESTROY_MAINTENANCE'
const DESTROY_MAINTENANCE_PLAN         = 'MAINTENANCE_DESTROY_MAINTENANCE_PLAN'

export const MAINTENANCE_ACTIONS = {
  FETCH_MAINLINES:            FETCH_MAINLINES,
  FETCH_MAINLINE:             FETCH_MAINLINE,
  FETCH_SUBLINES:             FETCH_SUBLINES,
  FETCH_SUBLINE:              FETCH_SUBLINE,
  CREATE_PLAN:                CREATE_PLAN,
  ADD_PLAN_EQUIPMENTS:        ADD_PLAN_EQUIPMENTS,
  UPDATE_PLAN:                UPDATE_PLAN,
  UPDATE_PLAN_EQUIPMENTS:     UPDATE_PLAN_EQUIPMENTS,
  UPDATE_MAINTENANCE:         UPDATE_MAINTENANCE,
  PLANIFY:                    PLANIFY,
  MOVE:                       MOVE,
  ARCHIVE_MAINTENANCE:        ARCHIVE_MAINTENANCE,
  UNARCHIVE_MAINTENANCE:      UNARCHIVE_MAINTENANCE,
  ARCHIVE_MAINTENANCE_PLAN:   ARCHIVE_MAINTENANCE_PLAN,
  UNARCHIVE_MAINTENANCE_PLAN: UNARCHIVE_MAINTENANCE_PLAN,
  DESTROY_MAINTENANCE:        DESTROY_MAINTENANCE,
  DESTROY_MAINTENANCE_PLAN:   DESTROY_MAINTENANCE_PLAN,
}

export function maintenanceReducer(state, action) {

  const {
    fetchApi,
    closeModal,
    filters,
    // pagination,
    // updateLine,
    setMainLines,
    setMainline,
    setSubLines,
    setSubline,
    setLoading,
    setPagination,
    VIEWS
  } = state

  const callbacks   = action.callbacks || []
  const fieldset    = action.fieldset
  const contentType = action.contentType ? action.contentType : 'application/json'

  switch (action.type) {
    case FETCH_MAINLINES: {
      const page = action.page || action.pagination.next

      if (!page && (action.pagination.page >= action.pagination.last)) return state

      let response

      if (action.response) {
        response = action.response
      } else {
        switch(action.filters.view) {
          case VIEWS.PLANS:         response = 'plans'; break
          case VIEWS.MAINTAINABLES: response = 'maintainables'; break
        }
      }

      const filterString = buildFilterString({
        ...action.filters,
        group:    action.group?.id,
        response: response,
        page:     page
      })

      setLoading(true)

      fetchApi({
        url:      `/maintenances/mainlines?${filterString}`,
        callback: data => {
          if (data.pagination?.page === 1) {
            setMainLines(data.items)
          } else {
            setMainLines(items => [...items, ...data.items])
          }
          action.setPagination(data.pagination)
          setLoading(false)
        },
        followUps: callbacks,
        signal:    action.signal
      })
      return state
    }

    case FETCH_MAINLINE: {
      let response
      switch(filters.view) {
        case VIEWS.PLANS:         response = 'plans'; break
        case VIEWS.MAINTAINABLES: response = 'maintainables'; break
      }
      let filterString = ''
      if (filters.view === VIEWS.MAINTAINABLES) {
        filterString = buildFilterString({...filters, item_type: action.mainline.class_plural, item_id: action.mainline.id, response })
      } else if (filters.view === VIEWS.PLANS) {
        filterString = buildFilterString({...filters, plan: action.mainline.id, response })
      }

      fetchApi({
        url:      `maintenances/mainline.json?${filterString}`,
        callback: data => {
          !!setLoading && setLoading(false)
          setMainline(data)
        }
      })
      return state
    }

    case FETCH_SUBLINES: {
      let extendedFilters = { ...filters }

      if (filters.view === VIEWS.PLANS) {
        extendedFilters = {
          ...extendedFilters,
          plan: action.item.id,
          page: action.pagination ? action.pagination.next : 1
        }
      } else if (filters.view === VIEWS.MAINTAINABLES) {
        extendedFilters = {
          ...extendedFilters,
          item_type: action.item.class_plural,
          item_id:   action.item.id,
          page:      action.pagination ? action.pagination.next : 1
        }
      }

      const filterString = buildFilterString(extendedFilters)

      setLoading && setLoading(true)

      fetchApi({
        url:      `/maintenances/sublines.json?${filterString}`,
        callback: data => {
          if (data.pagination.page === 1) {
            setSubLines(data.items)
          } else {
            setSubLines(items => [...items, ...data.items])
          }
          setPagination(data.pagination)
          setLoading && setLoading(false)
        }
      })
      return state
    }

    case FETCH_SUBLINE: {
      let response
      switch(filters.view) {
        case VIEWS.PLANS:         response = 'plans'; break
        case VIEWS.MAINTAINABLES: response = 'maintainables'; break
      }

      let filterString = ''

      if (filters.view === VIEWS.MAINTAINABLES) {
        filterString = buildFilterString({
          ...filters,
          item_type: action.line.data.class_plural,
          item_id:   action.line.data.id,
          response
        })
      } else if (filters.view === VIEWS.PLANS) {
        filterString = buildFilterString({
          ...filters,
          plans: action.line.data.id,
          response
        })
      }

      fetchApi({
        url:      `/maintenances/${action.line.data.id}/subline.json?${filterString}`,
        callback: (data) => {
          setSubline(data)
          closeModal()
        }
      })
      return state
    }

    case CREATE_PLAN: {
      const filterString = buildFilterString(filters)
      fetchApi({
        url:         `/maintenance_plans?${filterString}`,
        method:      'POST',
        withLoading: true,
        fieldset,
        body:        action.data,
        callback:    action.callback,
        followUps:   action.followUps
      })
      return state
    }

    case UPDATE_PLAN: {
      const filterString = buildFilterString(filters)
      !!setLoading && setLoading(true)
      fetchApi({
        url:       `/maintenance_plans/${action.data.id}?${filterString}`,
        method:    'PATCH',
        body:      action.data,
        fieldset,
        callback:  closeModal,
        followUps: callbacks
      })
      return state
    }

    case ADD_PLAN_EQUIPMENTS: {
      const filterString = buildFilterString(filters)

      setLoading(true)
      fetchApi({
        url:       `/maintenance_plans/${action.plan.id}/add_equipment?${filterString}`,
        method:    'PATCH',
        body:      action.data,
        fieldset,
        callback:  closeModal,
        followUps: callbacks
      })
      return state
    }

    case UPDATE_PLAN_EQUIPMENTS: {
      const filterString = buildFilterString(filters)

      setLoading(true)
      fetchApi({
        url:       `/maintenance_plans/${action.plan.id}/update_equipments?${filterString}`,
        method:    'PATCH',
        body:      action.equipments,
        fieldset,
        callback:  closeModal,
        followUps: callbacks
      })
      return state
    }

    case UPDATE_MAINTENANCE: {
      fetchApi({
        url:       `/maintenances/${action.maintenance.id}`,
        method:    'PATCH',
        contentType,
        body:      action.data,
        fieldset,
        followUps: action.callbacks
      })
      return state
    }

    case PLANIFY: {
      const filterString = buildFilterString(filters)

      fetchApi({
        url:         `/maintenances?${filterString}`,
        method:      'POST',
        contentType,
        body:        action.data,
        fieldset,
        followUps:   action.callbacks,
        withLoading: true
      })
      return state
    }

    case MOVE: {
      const filterString = buildFilterString(filters)
      fetchApi({
        url:       `/maintenances/${action.maintenance.data.id}/change_week?${filterString}`,
        method:    'PATCH',
        contentType,
        body:      action.data,
        fieldset,
        followUps: action.callbacks
      })

      return state
    }

    case ARCHIVE_MAINTENANCE: {
      const filterString = buildFilterString(filters)

      fetchApi({
        url:       `/maintenances/${action.maintenance.data.id}/archive?${filterString}`,
        method:    'PATCH',
        callback:  closeModal,
        followUps: action.callbacks
      })

      return state
    }
    case UNARCHIVE_MAINTENANCE: {
      const filterString = buildFilterString(filters)

      fetchApi({
        url:       `/maintenances/${action.maintenance.data.id}/unarchive?${filterString}`,
        method:    'PATCH',
        contentType,
        body:      action.data,
        fieldset,
        callback:  closeModal,
        followUps: action.callbacks
      })

      return state
    }

    case ARCHIVE_MAINTENANCE_PLAN: {
      const filterString = buildFilterString(filters)

      fetchApi({
        url:         `/maintenance_plans/${action.plan.id}/archive?${filterString}`,
        method:      'PATCH',
        withLoading: true,
        callback:    closeModal,
        followUps:   action.callbacks
      })

      return state
    }
    case UNARCHIVE_MAINTENANCE_PLAN: {
      const filterString = buildFilterString(filters)

      fetchApi({
        url:         `/maintenance_plans/${action.plan.id}/unarchive?${filterString}`,
        method:      'PATCH',
        withLoading: true,
        callback:    closeModal,
        followUps:   action.callbacks
      })

      return state
    }

    case DESTROY_MAINTENANCE: {
      const filterString = buildFilterString(filters)

      fetchApi({
        url:       `/maintenances/${action.maintenance.id}?${filterString}`,
        method:    'DELETE',
        followUps: action.callbacks
      })

      return state
    }

    case DESTROY_MAINTENANCE_PLAN: {
      const filterString = buildFilterString(filters)

      fetchApi({
        url:       `/maintenance_plans/${action.plan.id}?${filterString}`,
        method:    'DELETE',
        followUps: action.callbacks
      })

      return state
    }

    default:
      if (window.env !== 'production') toast.error('Reducer action not defined', { icon: <FontAwesomeIcon icon="face-tired" />, iconTheme: { primary: 'var(--rep-danger)', secondary: 'var(--rep-danger-light)' } })
      return state
  }
}
