import React, { useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Callout    from '@components/Callout'
import Checkbox   from '@components/Form/Checkbox'
import DatePicker from '@components/Form/DatePicker'
import Input      from '@components/Form/Input'
import Select     from '@components/Form/Select'
import Switch     from '@components/Switch'

import { useGlobalContextState } from '@context/GlobalContext'

import * as FormStyle from '@form/FormStyles'

import IServiceProvider from '@interfaces/IServiceProvider.d'
import IUser            from '@interfaces/IUser.d'
import Spacer from '@components/Spacer'

interface SelectDoerFormProps {
  taskId:          number | string
  assignedTo:      string
  defaultUsers:    IUser[]
  defaultProvider: IServiceProvider
  suggested:              {
    type:     'internal' | 'external'
    reason:   string
    selected: IUser | IServiceProvider
  }
  defaultPeriod:          string | string[] | Date | Date[]
  showTime:               boolean
  forceAssignationMode:   boolean
  teams:                  boolean
  multiselect:            boolean
  showPeriodSelection:    boolean
  showNotify:             boolean
  defaultNotify:          boolean
  cleanAfterSelect:       boolean
  selectUsersCallback:    (users: IUser[]) => void
  selectProviderCallback: (provider: IServiceProvider) => void
}

const SelectDoerForm: React.FC<SelectDoerFormProps> = ({
  taskId,
  index,
  // Internal or External
  assignedTo,
  // User selected
  defaultUsers,
  defaultProvider,
  // Suggested assignation + reason
  suggested,
  // Default period for WorkPeriod
  defaultPeriod,
  // Should display time in WorkPeriod DatePicker
  showTime,
  // Force assignation to 'assignedTo' prop
  forceAssignationMode = false,
  // Select multiple users
  teams               = true,
  multiselect         = true,
  showPeriodSelection = true,
  showNotify          = true,
  defaultNotify       = true,
  cleanAfterSelect    = false,
  selectUsersCallback,
  selectProviderCallback,
}) => {

  const { i18n, current_company } = useGlobalContextState()

  const INTERNAL = 'internal'
  const EXTERNAL = 'external'

  const [dispatchTo, setDispatchTo] = useState(assignedTo || suggested?.type || (defaultProvider ? EXTERNAL : INTERNAL))

  const [userTeams, setUserTeams] = useState(current_company.user_teams)
  const [userTeamSelected, setUserTeamSelected] = useState([-1])
  const [showPeriodTime, setShowPeriodTime] = useState(showTime)

  const [provider, setProvider]             = useState(defaultProvider ? defaultProvider : suggested?.type === EXTERNAL ? suggested?.selected : null)
  const [selectedUsers, setSelectedUsers]   = useState(defaultUsers?.length ? defaultUsers : suggested?.selected ? [suggested?.selected] : [])
  // const [selectedPeriod, setSelectedPeriod] = useState(defaultPeriod ? defaultPeriod : [])

  const period = useMemo(() => defaultPeriod ? defaultPeriod : [], [])

  const assignUserTeam = (teamId) => {
    selectProvider(null)
    setDispatchTo(INTERNAL)

    const team = userTeams.find(team => team.id === teamId)

    if (userTeamSelected.includes(teamId)) {
      setUserTeamSelected(teamsSelected => teamsSelected.filter(teamSelected => teamSelected !== teamId))
      selectUsers(selectedUsers.filter(u => !team.users.includes(u)))
    } else {
      setUserTeamSelected([...userTeamSelected, team.id])

      // Unique user in the merge of arrays selectedUsers and team.users
      selectUsers([...new Map([...selectedUsers, ...team.users].map(item =>
        [item['id'], item])).values()])
    }
  }

  const selectUsers = users => {
    const uniqueUsers = [...new Map([...users].map(item =>
      [item['id'], item])).values()]
    setSelectedUsers(uniqueUsers)
    !!selectUsersCallback && selectUsersCallback(uniqueUsers)
  }

  const selectProvider = provider => {
    selectUsers(provider?.default_user ? [provider.default_user] : [])
    setProvider(provider)
    !!selectProviderCallback && selectProviderCallback(provider)
  }

  const [startDate, setStartDate] = useState(period && Array.isArray(period) && period[0] ? new Date(period[0]) : null)

  return (
    <>
      {!forceAssignationMode && <Switch
        name='assigned_to'
        options={[
          { content: i18n.t('todo.internal'), value: INTERNAL },
          { content: i18n.t('service_provider.service_provider'), value: EXTERNAL }
        ]}
        marginY='M'
        selected={dispatchTo}
        callback={assignation => {
          setDispatchTo(assignation)
          selectUsers([])
        }}
      />
      }

      <Input
        name         = {`tasks[${index}].id`}
        type         = "hidden"
        defaultValue = {taskId}
      />

      {dispatchTo === INTERNAL &&
        <>
          {!!teams && !!userTeams.length &&
            <>
              <FormStyle.Label>
                {i18n.t('user_team.user_teams')}
              </FormStyle.Label>
              <Switch
                multiselect
                name     = 'user-team'
                selected = {userTeamSelected}
                callback = {assignUserTeam}
                marginY  = 'S'
                options  = {userTeams.map(team => {
                  return {
                    content: team.name,
                    value:   team.id
                  }
                })}
              />
            </>
          }
          {suggested?.type === INTERNAL && !!suggested?.reason &&
            <Callout
              icon    = {<FontAwesomeIcon icon="rocket" />}
              type    = 'warning'
              marginY = 'M'
            >
              {suggested.reason}
            </Callout>
          }

          <Select
            name         = {taskId ? `tasks[${index}].users` : 'users'}
            label        = {i18n.t('user.users')}
            searchUrl    = '/users/search'
            defaultValue = {selectedUsers}
            filters      = {[
              {
                id:      'technicians',
                name:    i18n.t('user.role.technicians'),
                filters: { role: ['technician', 'technician_manager'] }
              },
              {
                id:      'managers',
                name:    i18n.t('user.role.managers'),
                filters: { role: ['manager', 'local_manager'] }
              },
              {
                id:      'employees',
                name:    i18n.t('user.role.employees'),
                filters: { role: ['employee', 'employee_manager'] }
              },
              {
                id:      'all',
                name:    i18n.t('shared.all'),
                filters: {}
              }
            ]}
            defaultFilter    =  'technicians'
            placeholder      =  {i18n.t('user.your_users')}
            format           =  {{ content: 'name', value: 'id', details: (dispatchTo === INTERNAL ? 'role' : 'role.service_providers') }}
            detailsLocaleKey = {dispatchTo === INTERNAL ? 'shared' : null}
            marginY          = 'M'
            callback         = {user => selectUsers(multiselect ? user.map(u => u.object) : [user.object])}
            cleanAfterSelect = {cleanAfterSelect}
            multiselect      = {multiselect}
            search
          />

          {showNotify &&
            <Checkbox
              name         = {taskId ? `tasks[${index}].notify` : 'notify'}
              label        = {i18n.t('maintenance.form.notify_associated_generation')}
              marginY      = 'M'
              defaultValue = {defaultNotify}
            />
          }
        </>
      }

      {dispatchTo === EXTERNAL &&
        <>
          <Select
            name          = {taskId ? `tasks[${index}].service_providers` : 'service_providers'}
            searchUrl     = '/service_providers/search'
            defaultValue  = {[provider]}
            label         = {i18n.t('service_provider.service_providers')}
            placeholder   = {i18n.t('service_provider.your_service_providers')}
            format        = {{ content: 'name', value: 'id', details: 'expertises' }}
            callback      = {provider => selectProvider(provider.object)}
            emptyCallback = {() => selectProvider(null)}
            marginY       = 'M'
            search
            withEmpty
          />

          {!!provider && <Select
            name             = {taskId ? `tasks[${index}].users` : 'users'}
            key              =  {provider?.id}
            label            =  {provider?.name}
            defaultValue     =  {selectedUsers}
            options          =  {provider ? provider?.users : provider?.users}
            placeholder      =  {i18n.t('todo.provider_users', { provider: provider?.name })}
            format           =  {{ content: 'name', value: 'id', details: 'role' }}
            marginY          =  'M'
            callback         =  {users => selectUsers(multiselect ? users.map(u => u.object) : [users.object])}
            cleanAfterSelect = {cleanAfterSelect}
            multiselect      = {multiselect}
            required
          />}

          {suggested?.type === EXTERNAL && !!suggested?.reason &&
            <Callout
              icon       = {<FontAwesomeIcon icon="rocket" />}
              border     = 'var(--rep-warning)'
              background = 'var(--rep-warning-light)'
              color      = 'var(--rep-warning)'
            >
              {suggested?.reason}
            </Callout>
          }

          {showNotify &&
            <Checkbox
              name         = {taskId ? `tasks[${index}].notify` : 'notify'}
              label        = {i18n.t('todo.send_notification_to_service_provider')}
              marginY      = 'M'
              defaultValue = {defaultNotify}
            />
          }
        </>
      }

      {showPeriodSelection && (!!selectedUsers.length || !!provider || !!userTeamSelected[1] || !!suggested?.length) &&
        <FormStyle.DatePickerForm>
          {showPeriodTime
            ? <FormStyle.Group
              align = 'center'
              wrap  = 'nowrap'
            >
              <DatePicker
                name     = {taskId ? `tasks[${index}][assigned_date]` : 'assigned_date'}
                label    = {i18n.t('shared.from_date')}
                date     = {period ? period[0] : null}
                callback = {date => setStartDate(new Date(date))}
                marginY  = 'M'
                timeAsSelect
                showTime
                required
              />
              <FontAwesomeIcon icon="arrow-right" color='var(--rep-neutral-primary)'/>
              <DatePicker
                name        = {taskId ? `tasks[${index}][assigned_date]` : 'assigned_date'}
                label       = {i18n.t('shared.to_date')}
                date        = {period ? period[1] : null}
                minDateTime = {startDate}
                marginY  = 'M'
                showTime
                timeAsSelect
                required
              />
            </FormStyle.Group>
            : <DatePicker
              name     = {taskId ? `tasks[${index}].assigned_date` : 'assigned_date'}
              label    = {i18n.t('todo.work_period')}
              mode     = 'range'
              date     = {period ? period : [new Date(), new Date()]}
              marginY  = 'M'
              required
            />
          }

          <Checkbox
            name         = {taskId ? `tasks[${index}].show_time` : 'show_time'}
            label        = {i18n.t('todo.actions.dispatch_hours')}
            defaultValue = {showPeriodTime}
            callback     = {() => setShowPeriodTime(showPeriodTime => !showPeriodTime)}
          />
        </FormStyle.DatePickerForm>
      }
      <Spacer size='m' />
    </>

  )
}

export default SelectDoerForm
