import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React  from 'react'

import DatePicker       from '@form/DatePicker'
import MultiselectItem  from '@form/Select/MultiselectItem'
import Thumbnail        from '@form/FileUploader/Thumbnail'

import Callout          from '@components/Callout'
import RecurrencyString from '@components/RecurrencyForm/RecurrencyString'
import Tag              from '@components/Tag'
import TaskBox          from '@components/Task/TaskBox'

import * as FormStyle from '@form/FormStyles'
import * as Style     from '../Calendar/style'

import { parseBoolean } from '@utils/parser'

import { useGlobalContextState } from '@context/GlobalContext'

import IMaintenancePlan from '@interfaces/IMaintenancePlan.d'
import Spacer from '@components/Spacer'

interface PlanResumeProps {
  data?: IMaintenancePlan
}

const PlanFormResume: React.FC<PlanResumeProps> = ({ data }) => {

  const { i18n, current_company, setInfoWindowProps } = useGlobalContextState()

  const { tasks, documents, user, provider, units, amenities } = data

  const openDetails = (object, type) => {
    current_company.beta_access
      ? setInfoWindowProps({ item: object, type: type, target: 'modal' })
      : window.open(`/${type}/${object.id}`, '_blank', 'noopener,noreferrer')
  }

  return(
    <div>
      <FormStyle.Header marginY='M'>
        <FontAwesomeIcon icon="file-lines" />
        {i18n.t('maintenance.maintenance_details')}
      </FormStyle.Header>
      {
        data.name
          ? <Style.MaintenanceBox>
            <h3>
              {data.name}
              {!!data.legal &&
                <Tag
                  color          = "var(--rep-primary)"
                  icon           = {<FontAwesomeIcon icon="medal" />}
                  tooltipContent = {i18n.t('maintenance.legal_control')}
                />
              }
            </h3>

            <p>{data.description}</p>

            {!!user && !!Object.keys(user).length &&
              <Tag
                icon           = {<FontAwesomeIcon icon={provider ? 'address-book' : 'user'} />}
                background     = 'var(--rep-primary-light)'
                color          = 'var(--rep-primary)'
                tooltipContent = {provider ? provider.name : null}
              >
                {user.name}
              </Tag>
            }
            <div style={{ display: 'flex', gap: '4px', marginTop: '4px'}}>
              {data.expertise &&
                  <Tag
                    border='var(--rep-warning)'
                    color='var(--rep-warning)'
                    tooltipContent={i18n.t('expertise.expertise')}
                  >
                    {data.expertise.name}
                  </Tag>
              }
              {data.charge_number &&
                  <Tag
                    border='var(--rep-primary)'
                    color='var(--rep-primary)'
                    tooltipContent={i18n.t('charge_number.charge_number')}
                  >
                    {data.charge_number.number}
                  </Tag>
              }
            </div>
          </Style.MaintenanceBox>
          : <Callout
            background = 'var(--rep-warning-light)'
            color      = 'var(--rep-warning)'
            marginY    = 'S'
            type       = 'warning'
          >
            {i18n.t('maintenance.error.no_name')}
          </Callout>
      }

      <div>
        <FormStyle.Header marginY='M'>
          <FontAwesomeIcon icon="calendar-day" />
          {i18n.t('maintenance.recurrence_title')}
        </FormStyle.Header>
        {data?.recurrence && !!data?.recurrence?.frequency
          ? <RecurrencyString rules = {data.recurrence} type='accent' />
          : <Callout
            type    = 'warning'
            marginY = 'S'
          >
            {i18n.t('maintenance.callout.no_recurrence')}
          </Callout>
        }

        {data?.recurrence && data?.recurrence?.mode === 'interval' &&
          (parseBoolean(data?.recurrence?.auto_generate)
            ? <Callout
              icon = {<FontAwesomeIcon icon="wand-magic-sparkles"/>}
              type = "success"
            >
              {i18n.t('maintenance.form.tickets_auto_generated')}
            </Callout>
            : <Callout
              icon = {<FontAwesomeIcon icon="times"/>}
              type = "danger"
            >
              {i18n.t('maintenance.form.not_tickets_auto_generated')}
            </Callout>
          )
        }
      </div>

      <div>
        <FormStyle.Header marginY='M'>
          <FontAwesomeIcon icon="calendar-day" />
          {i18n.t('maintenance.assets')}
        </FormStyle.Header>
        {units.map(unit =>
          <MultiselectItem
            key     = {`units-${unit.id}`}
            name    = {unit.maintainable_name}
            icon    = {<FontAwesomeIcon icon="location-dot" />}
            tooltip = {unit.path_string}
            click   = {() => openDetails({...unit, id: unit.maintainable_id }, 'units')}
          >
            {(!data.id || unit.start) &&
              <DatePicker
                name     = {`resume-unit-${unit.id}-start-date`}
                date     = {unit.start ? new Date(unit.start) : new Date()}
                disabled
              />
            }
          </MultiselectItem>
        )}
        {amenities.map(amenity =>
          <MultiselectItem
            key     = {`amenities-${amenity.id}`}
            name    = {amenity.maintainable_name}
            icon    = {<FontAwesomeIcon icon="gear" />}
            tooltip = {amenity.path_string}
            click   = {() => openDetails({...amenity, id: amenity.maintainable_id }, 'amenities')}
          >
            {(!data.id || amenity.start) &&
              <DatePicker
                name     = {`resume-unit-${amenity.id}-start-date`}
                date     = {amenity.start ? new Date(amenity.start) : new Date()}
                disabled
              />
            }
          </MultiselectItem>
        )}
        {!units.length && !amenities.length &&
        <Callout
          background = 'var(--rep-warning-light)'
          color      = 'var(--rep-warning)'
          marginY    = 'S'
        >
          {i18n.t('maintenance.callout.no_assets')}
        </Callout>
        }
      </div>

      <div>
        <FormStyle.Header marginY='M'>
          <FontAwesomeIcon icon="list" />
          {i18n.t('todo.todos')}
        </FormStyle.Header>
        {tasks.length
          ? tasks.map((task, key) =>
            <TaskBox
              key         = {key}
              task        = {task}
              showUsersAs = 'avatars'
            />
          )
          : <Callout
            background = 'var(--rep-warning-light)'
            color      = 'var(--rep-warning)'
            marginY    = 'S'
          >
            {i18n.t('maintenance.callout.no_tasks')}
          </Callout>
        }
      </div>

      <div>
        <FormStyle.Header marginY='M'>
          <FontAwesomeIcon icon="file-arrow-up" />
          {i18n.t('shared.documents')}
        </FormStyle.Header>
        <div style={{ display: 'flex', gap: 4 }}>
          {documents.map(document => <Thumbnail key={document.id} file={document} />)}
        </div>
        {!documents.length &&
          <Callout
            background = 'var(--rep-warning-light)'
            color      = 'var(--rep-warning)'
            marginY    = 'S'
          >
            {i18n.t('maintenance.callout.no_documents')}
          </Callout>
        }
      </div>

      {data?.id &&
      <>
        <Spacer size="l" />
        <hr />
        <Callout type="danger" icon={<FontAwesomeIcon icon="skull-crossbones" />}>
          {i18n.t('maintenance.callout.plan_modifications_will_replace_future_tickets')}
        </Callout>
      </>
      }
    </div>
  )
}

export default PlanFormResume
