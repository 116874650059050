import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import FieldInput from '@components/Form/FieldInput'
import Input from '@components/Form/Input'
import Textarea from '@components/Form/Textarea'

import * as FormStyle from '@form/FormStyles'

import { useGlobalContextState } from '@context/GlobalContext'

const MeasurementInForm = ({
  index,
  measurment
}) => {

  const { i18n } = useGlobalContextState()

  return (
    <>
      <FormStyle.Header><FontAwesomeIcon icon="ruler" />{measurment.name}</FormStyle.Header>
      <Input
        type         = "hidden"
        name         = {`measurments[${index}].id`}
        defaultValue = {measurment.id}
      />
      <Input
        type         = "text"
        label        = {i18n.t('measurment.value')}
        name         = {`measurments[${index}].value`}
        defaultValue = {measurment.value?.value}
        background   = {'var(--rep-primary-light)'}
        border       = {'var(--rep-primary)'}
        color        = {'var(--rep-primary)'}
        marginY      = 'M'
        required
      />
      <Textarea
        name         = {`measurments[${index}].comment`}
        label        = {i18n.t('shared.comment')}
        defaultValue = {measurment.value?.comment}
        marginY      = 'M'
      />

      {measurment.fields.map((field, key) =>
        <FieldInput
          key          = {field.id}
          field        = {field}
          idName       = {`measurments[${index}].fields[${key}].id`}
          name         = {`measurments[${index}].fields[${key}].value`}
          defaultValue = {measurment.value ? measurment.fields.find(f => f.id === field.id)?.value : ''}
          marginY      = 'M'
        />
      )}
    </>
  )
}

export default MeasurementInForm
