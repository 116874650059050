import React, { useState } from 'react'

import Form    from '@components/Form/Form'
import Select  from '@components/Form/Select'
import TaskBox from './TaskBox'

import { useGlobalContextState } from '@context/GlobalContext'

import { REPORT_ACTIONS } from '@reducers/reportReducer'

import IReport from '@interfaces/IReport.d'

interface AddTaskFromPlanProps {
  report:         IReport
  reportDispatch: React.Dispatch
}

const AddTaskFromPlan: React.FC<AddTaskFromPlanProps> = ({
  report,
  reportDispatch
}) => {

  const { i18n, closeModal } = useGlobalContextState()

  const [tasks, setTasks] = useState([])

  const remove = removed => setTasks([...tasks].filter(t => t.id !== removed.id))

  const selectUsers = (currentTask, users) => {
    const taskData = {...currentTask, users: users }
    console.log(taskData)
    const updatedTasks = [...tasks].map(t => t.id === taskData.id ? taskData : t)
    setTasks(updatedTasks)
  }

  const selectProvider = (currentTask, provider) => {
    const taskData = {...currentTask, provider: provider, users: [] }
    const updatedTasks = [...tasks].map(t => t.id === taskData.id ? taskData : t)
    setTasks(updatedTasks)
  }

  const addTask = (data, fieldset) => reportDispatch({
    type:      REPORT_ACTIONS.ADD_MAINTENANCE_TASK,
    data,
    report,
    fieldset,
    callbacks: [closeModal]
  })

  return(
    <Form
      callback={addTask}
    >
      <Select
        name         = 'maintenance-tasks'
        label        = {i18n.t('maintenance.placeholder.maintenance_tasks')}
        searchUrl    = {`/maintenance_plans/${report.maintenance_plan.id}/maintenance_tasks`}
        defaultValue = {tasks}
        placeholder  = {i18n.t('shared.actions.select')}
        format       = {{ content: 'name', value: 'id' }}
        marginY      = 'M'
        callback     = {selected => setTasks(selected.map(s => s.object))}
        multiselect
        search
      />

      {!!tasks.length &&
        tasks.map((task, index) =>
          <TaskBox
            key            = {task.id}
            index          = {index}
            task           = {task}
            removeAction   = {() => remove(task)}
            selectUsers    = {users => selectUsers(task, users)}
            selectProvider = {selectProvider}
          />
        )
      }
    </Form>
  )
}

export default AddTaskFromPlan
