import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button       from '@components/Button'
import Checkbox     from '@form/Checkbox'
import FormQuestion from './FormQuestion/FormQuestion'
import Input        from '@form/Input'
import RadioButtons from '@form/RadioButtons'
import Select       from '@form/Select'
import Spacer       from '@components/Spacer'
import Textarea     from '@form/Textarea'

import * as FormStyle from '@form/FormStyles'
import * as Style     from './style'

import { useGlobalContextState } from '@context/GlobalContext'

import IFormQuestion from '@interfaces/IFormQuestion.d'

interface IFormTemplateForm {
  addForm:         (any) => void
  canSaveTemplate: boolean
}

const FormTemplateForm = ({ addForm, canSaveTemplate }: IFormTemplateForm) => {

  const { i18n, CONSTANTS } = useGlobalContextState()
  const { FORM_ANSWER } = CONSTANTS

  const [questions, setQuestions]: [IFormQuestion[], React.Dispatch<React.SetStateAction<IFormQuestion[]>>] = useState([])

  const [name,               setName]               = useState('')
  const [template,           setTemplate]           = useState(false)

  const [questionName,       setQuestionName]       = useState('')
  const [questionUnanswered, setQuestionUnanswered] = useState('')
  const [questionAnswerType, setQuestionAnswerType] = useState('')
  const [questionComment,    setQuestionComment]    = useState(FORM_ANSWER.FIELDS.HIDDEN)
  const [questionNA,         setQuestionNA]         = useState(FORM_ANSWER.FIELDS.HIDDEN)
  const [questionPhoto,      setQuestionPhoto]      = useState(FORM_ANSWER.FIELDS.HIDDEN)
  const [answerOption,       setAnswerOption]       = useState('')
  const [answers,            setAnswers]            = useState([])
  const [_resetForm,         setResetForm]          = useState('false')

  const answerRef     = useRef(null)
  const formRef       = useRef(null)
  const inputRef      = useRef(null)
  const unansweredRef = useRef(null)

  const fieldOptions = [
    { content: i18n.t('report_type.headers.hidden'),    value: FORM_ANSWER.FIELDS.HIDDEN    },
    { content: i18n.t('report_type.headers.optional'),  value: FORM_ANSWER.FIELDS.OPTIONAL  },
    { content: i18n.t('report_type.headers.mandatory'), value: FORM_ANSWER.FIELDS.MANDATORY }
  ]

  useEffect(() => {
    formRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
  })

  const createForm = () => {
    const tempId = Array(1).fill()
      .map(z => Math.random().toString(36)
        .slice(2))
      .join('')
    addForm({ tempId, name, questions, template, created: true })
    setName('')
    setQuestions([])
    setTemplate(false)
  }

  const detectQuestionEnterKey = key => key == 'Enter' ? addQuestion() : null
  const detectAnswerEnterKey   = key => key == 'Enter' ? addAnswerOption() : null

  const addQuestion = () => {
    if (!questionName || !questionAnswerType) return
    if (questionAnswerType === FORM_ANSWER.TYPES.SELECT && !answers.length) return

    setQuestions([...questions, {
      question:       questionName,
      answer_type:    questionAnswerType,
      answer_options: answers,
      comment_field:  questionComment,
      na_field:       questionNA,
      photo_field:    questionPhoto,
      hint:           questionUnanswered
    }])
    setResetForm(true)
    setQuestionName('')
    setQuestionComment(FORM_ANSWER.FIELDS.HIDDEN)
    setQuestionNA(FORM_ANSWER.FIELDS.HIDDEN)
    setQuestionPhoto(FORM_ANSWER.FIELDS.HIDDEN)
    setQuestionUnanswered('')
    setAnswers([])
    setAnswerOption('')
    setQuestionAnswerType(null)
    unansweredRef.current.value = ''
    setTimeout(() => setResetForm(false), 500)
    inputRef.current.focus()
    inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
  }

  const removeQuestion = key => {
    const newQuestions = [...questions]
    newQuestions.splice(key, 1)
    setQuestions(newQuestions)
  }

  const addAnswerOption = () => {
    if (!answerOption) return

    setAnswers([...answers, answerOption])
    setAnswerOption('')
    answerRef.current.focus()
    answerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
  }

  const removeAnswer = key => {
    const newAnswers = [...answers]
    newAnswers.splice(key, 1)
    setAnswers(newAnswers)
  }

  return(
    <Style.FormContainer ref={formRef}>
      <Spacer size='m' />
      <FormStyle.Label
        noBorderBottom = {true}
        noMarginTop    = {true}
      >
        {i18n.t('form_reply.actions.create')}
      </FormStyle.Label>
      <FormStyle.Group>
        <Input
          type         = "text"
          label        = {i18n.t('form.name')}
          name         = "form-name"
          defaultValue = {name}
          change       = {e => setName(e.currentTarget.value)}
          tooltip      = {i18n.t('form.tooltip.form_name')}
        />
      </FormStyle.Group>

      <Style.QuestionsContainer>
        {!!name && <FormStyle.Divider marginY = 'M' />}

        {questions.map((question, key) =>
          <FormQuestion
            key            = {key}
            question       = {question}
            removeQuestion = {removeQuestion}
          />
        )}
      </Style.QuestionsContainer>

      <Spacer size='xl' />

      {!!name &&
        <>
          <FormStyle.Group marginY='M'>
            <Input
              forwardRef   = {inputRef}
              type         = "text"
              label        = {i18n.t('form.question_name')}
              name         = "template-name"
              defaultValue = {questionName}
              change       = {event => setQuestionName(event.currentTarget.value)}
              keyUp        = {detectQuestionEnterKey}
              tooltip      = {i18n.t('form.tooltip.question_name')}
              required
            />
          </FormStyle.Group>

          <Select
            callback     = {response => setQuestionAnswerType(response.value)}
            format       = {{ content: 'content', value: 'value' }}
            defaultValue = {questionAnswerType ? [{ content: i18n.t(`form_reply.types.${questionAnswerType}`), value: questionAnswerType }] : []}
            label        = {i18n.t('custom_field.reply_type')}
            name         = {'question-answer-type'}
            options      = {FORM_ANSWER.TYPES_ARRAY.map((type) => ({ content: i18n.t(`form_reply.types.${type}`), value: type }))}
            required     = {true}
          />

          <div style={{ marginTop: '8px' }}>
            {!!answers.length &&
              <Style.AnswersContainer>
                {answers.map((answer, key) =>
                  <Style.AnswerOption key={key}>
                    <Style.QuestionName>
                      • {'\u00A0'}
                      {answer}
                    </Style.QuestionName>
                    <Button
                      icon   = {<FontAwesomeIcon icon="trash" />}
                      border = 'transparent'
                      color  = 'var(--rep-danger)'
                      click  = {() => removeAnswer(key)}
                    />
                    <Input
                      name         = {`answer-options-${key}`}
                      type         = "hidden"
                      defaultValue = {JSON.stringify(answers)}
                    />
                  </Style.AnswerOption>
                )}
              </Style.AnswersContainer>
            }
            {questionAnswerType === FORM_ANSWER.TYPES.SELECT &&
              <Style.AnswerOptionInput>
                <Input
                  forwardRef   = {answerRef}
                  type         = "text"
                  label        = {i18n.t('custom_field.actions.add_option')}
                  name         = "question-answer-option"
                  defaultValue = {answerOption}
                  change       = {event => setAnswerOption(event.currentTarget.value)}
                  keyUp        = {detectAnswerEnterKey}
                  required     = {answers.length === 0}
                />
                <Button
                  icon   = {<FontAwesomeIcon icon="plus" />}
                  color  = {answerOption ? 'var(--rep-success)' : 'var(--rep-neutral)'}
                  border = {answerOption ? 'var(--rep-success)' : 'var(--rep-neutral)'}
                  click  = {addAnswerOption}
                >
                  {i18n.t('actions.add')}
                </Button>
              </Style.AnswerOptionInput>
            }
          </div>

          <Textarea
            forwardRef = {unansweredRef}
            label      = {i18n.t('form.hint_unanswered')}
            name       = "template-unanswered"
            change     = {event => setQuestionUnanswered(event.currentTarget.value)}
            tooltip    = {i18n.t('form.tooltip.unanswered')}
            marginY    = 'M'
          />
          <FormStyle.Group marginY='M'>
            <Style.QuestionCheckboxes>
              <RadioButtons
                callback     = {value => setQuestionNA(value)}
                defaultValue = {questionNA}
                icon         = {<FontAwesomeIcon icon="eye-slash" fixedWidth />}
                name         = "question-na"
                options      = {fieldOptions.filter(option => option.value !== FORM_ANSWER.FIELDS.MANDATORY)}
                required     = {true}
                tooltip      = {i18n.t('form.na')}
              />
              <RadioButtons
                callback     = {value => setQuestionPhoto(value)}
                defaultValue = {questionPhoto}
                icon         = {<FontAwesomeIcon icon="image" fixedWidth />}
                name         = "question-photo"
                options      = {fieldOptions}
                required     = {true}
                tooltip      = {i18n.t('form.photo')}
              />
              <RadioButtons
                callback     = {value => setQuestionComment(value)}
                defaultValue = {questionComment}
                icon         = {<FontAwesomeIcon icon="comment-dots" fixedWidth />}
                name         = "question-comment"
                options      = {fieldOptions}
                required     = {true}
                tooltip      = {i18n.t('form.comment')}
              />
            </Style.QuestionCheckboxes>
          </FormStyle.Group>
          <Button
            icon      = {<FontAwesomeIcon icon="plus" />}
            color     = {questionName ? 'var(--rep-success)' : 'var(--rep-neutral)'}
            border    = {questionName ? 'var(--rep-success)' : 'var(--rep-neutral)'}
            click     = {addQuestion}
            alingSelf = 'stretch'
            align     = 'center'
            fullWidth = {true}
          >
            {i18n.t('form.actions.add_form_question')}
          </Button>
        </>
      }

      <Spacer size='xl' />

      {!!name && !!questions.length &&
        <>
          {canSaveTemplate &&<Checkbox
            label        = {i18n.t('form.as_template')}
            name         = "form-template"
            defaultValue = {template}
            callback     = {e => setTemplate(e.isCheck)}
          />
          }
          <Button
            border     = 'var(--rep-success)'
            color      = 'white'
            background = 'var(--rep-success)'
            icon       = {<FontAwesomeIcon icon="floppy-disk" />}
            marginY    = 'M'
            fullWidth
            align      = 'center'
            click      = {createForm}
            size       = 'M'
          >
            {i18n.t('form.actions.add_new_form')}
          </Button>
        </>
      }
    </Style.FormContainer>
  )
}

export default FormTemplateForm
