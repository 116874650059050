import React from 'react'

import Checkbox   from '@form/Checkbox'
import DatePicker from '@form/DatePicker'
import Input      from '@form/Input'
import Select     from '@form/Select'

import FieldInputProps from './types.d'

import { useGlobalContextState } from '@context/GlobalContext'

const FieldInput: React.FC<FieldInputProps> = ({
  background = 'var(--rep-primary-light)',
  border     = 'var(--rep-primary)',
  color      = 'var(--rep-primary)',
  defaultValue,
  disabled   = false,
  field,
  marginY,
  name       = `custom-${field.id}`,
  idName,
  width,
  required   = false,
  blur       = null,
  click      = null,
  focus      = null,
  keyUp      = null,
}) => {

  const { CONSTANTS } = useGlobalContextState()

  return (
    <>
      <Input
        type         = "hidden"
        name         = {idName}
        defaultValue = {field.id}
      />

      {field.reply_type === CONSTANTS.REPLY_TYPES.DATE &&
        <DatePicker
          name     = {name}
          date     = {defaultValue}
          disabled = {disabled}
          label    = {field.name}
          required = {required}
          marginY  = {marginY}
          allowClear
        />
      }

      {field.reply_type === CONSTANTS.REPLY_TYPES.DATE_TIME &&
        <DatePicker
          date     = {defaultValue}
          disabled = {disabled}
          label    = {field.name}
          name     = {name}
          required = {required}
          marginY  = {marginY}
          allowClear
          showTime
        />
      }

      {field.reply_type === CONSTANTS.REPLY_TYPES.FREE_TEXT &&
        <Input
          type         = "text"
          name         = {name}
          label        = {field.name}
          background   = {background}
          blur         = {blur}
          border       = {border}
          click        = {click}
          color        = {color}
          defaultValue = {defaultValue}
          disabled     = {disabled}
          focus        = {focus}
          keyUp        = {keyUp}
          marginY      = {marginY}
          required     = {required}
          width        = {width}
        />
      }

      {field.reply_type === CONSTANTS.REPLY_TYPES.MULTIPLE_CHOICE &&
        <Select
          name         = {name}
          label        = {field.name}
          defaultValue = {[{ content: defaultValue, value: defaultValue }]}
          disabled     = {disabled}
          format       = {{ content: 'content', value: 'value' }}
          marginY      = {marginY}
          options      = {field.choice.map((choice) => ({ content: choice, value: choice }))}
          required     = {required}
        />
      }

      {field.reply_type === CONSTANTS.REPLY_TYPES.NUMBER &&
        <Input
          type         = "number"
          name         = {name}
          defaultValue = {defaultValue}
          label        = {field.name}
          background   = {background}
          blur         = {blur}
          border       = {border}
          click        = {click}
          color        = {color}
          disabled     = {disabled}
          focus        = {focus}
          keyUp        = {keyUp}
          marginY      = {marginY}
          required     = {required}
          width        = {width}
        />
      }

      {field.reply_type === CONSTANTS.REPLY_TYPES.TRUE_FALSE &&
        <Checkbox
          defaultValue = {defaultValue === 'true'}
          disabled     = {disabled}
          label        = {field.name}
          marginY      = {marginY}
          name         = {name}
          required     = {required}
        />
      }
    </>
  )
}

export default FieldInput
