import React, { useMemo, useReducer, useState } from 'react'

import Button      from '@components/Button'
import Dropdown    from '@components/Dropdown'
import WithTooltip from '@components/WithTooltip'

import MaintenanceForm from '@pages/Maintenances/Calendar/SubLine/MaintenanceForm'

import IMaintenance from '@interfaces/IMaintenance.d'

import { useGlobalContextState } from '@context/GlobalContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Style from './style'

import { MAINTENANCE_ACTIONS, maintenanceReducer } from '@reducers/maintenanceReducer'
interface MaintenanceCardProps {
  initMaintenance: IMaintenance
}

const MaintenanceCard: React.FC<MaintenanceCardProps> = ({ initMaintenance, sideWindowTarget = 'page'}) => {

  const { i18n, current_company, setInfoWindowProps, showModal, fetchApi, closeModal } = useGlobalContextState()

  const [maintenance, setMaintenance] = useState(initMaintenance)

  const [_state, dispatch] = useReducer(maintenanceReducer, {
    fetchApi,
    closeModal,
  })

  const updateMaintenance = (data, fieldset) => dispatch({
    type:      MAINTENANCE_ACTIONS.UPDATE_MAINTENANCE,
    data,
    maintenance,
    fieldset,
    callbacks: [data => setMaintenance(data.response), closeModal]
  })

  const actions = useMemo(() => {
    const maintenanceOptions = []

    if (maintenance.permissions.can_update_maintenance) maintenanceOptions.push({
      icon:    <FontAwesomeIcon icon="pen" />,
      color:   'var(--rep-primary)',
      content: i18n.t('maintenance.actions.maintenance_edit'),
      click:   () => showModal({
        title:   `[${maintenance.name}] ${maintenance.maintainable_name}`,
        content: <MaintenanceForm
          maintenance       = {maintenance}
          updateMaintenance = {updateMaintenance}
        />
      })
    })

    return maintenanceOptions
  }, [maintenance])

  const openDetails = (object, type) => {
    current_company.beta_access
      ? setInfoWindowProps({ item: object, type: type, target: sideWindowTarget })
      : window.open(`/${type}/${object.id}`, '_blank', 'noopener,noreferrer')
  }

  return(
    <Style.Card>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Style.Header>
          {maintenance.archived
            ? <WithTooltip content={i18n.t('maintenance.plan_archived')}>
              <FontAwesomeIcon icon="folder" color="var(--rep-warning)" style={{ marginRight: '4px' }}/>
            </WithTooltip>
            : maintenance.late &&
              <WithTooltip content={i18n.t('maintenance.late')}>
                <FontAwesomeIcon icon="triangle-exclamation" color="var(--rep-danger)" style={{ marginRight: '4px' }} />
              </WithTooltip>
          }

          {maintenance.legal
            ? <WithTooltip content={i18n.t('maintenance.legal_control')}>
              <FontAwesomeIcon icon="medal" color="var(--rep-warning)"/>
            </WithTooltip>
            : <div></div>
          }
          <Style.Title>{maintenance.name}</Style.Title>
        </Style.Header>

        <div style={{ display: 'flex', gap: '4px'}}>
          <Style.LastNext>
            {maintenance.last_id
              ? <WithTooltip content={i18n.t('maintenance.last_maintenance')}>
                <Button
                  icon   = {<FontAwesomeIcon icon="arrow-left" />}
                  click  = {() => openDetails({id: maintenance.last_id}, 'reports')}
                  border = 'var(--rep-primary)'
                  color  = 'var(--rep-primary)'
                  hover  = 'var(--rep-primary-light)'
                >
                  {new Date(maintenance.last).toLocaleDateString(i18n.locale)}
                </Button>
              </WithTooltip>
              : <div></div>
            }
            {!maintenance.archived &&
              (maintenance.next_id
                ? <WithTooltip content={i18n.t('maintenance.next_maintenance')}>
                  <Button
                    icon   = {<FontAwesomeIcon icon="arrow-right" />}
                    click  = {() => openDetails({ id: maintenance.next_id }, 'reports')}
                    border = 'var(--rep-primary)'
                    color  = 'var(--rep-primary)'
                    hover  = 'var(--rep-primary-light)'
                  >
                    {new Date(maintenance.next).toLocaleDateString(i18n.locale)}
                  </Button>
                </WithTooltip>
                : <WithTooltip content={i18n.t('maintenance.next_maintenance_not_autogenerated')}>
                  {new Date(maintenance.next).toLocaleDateString(i18n.locale)}
                </WithTooltip>
              )

            }
          </Style.LastNext>
          {!!actions.length &&
          <Dropdown
            options = {actions}
            icon    = {<FontAwesomeIcon icon="bars" />}
            hover   = 'var(--rep-primary-light)'
          />
          }
        </div>
      </div>
    </Style.Card>
  )
}

export default MaintenanceCard
