import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import WithTooltip from '@components/WithTooltip'

import { useGlobalContextState } from '@context/GlobalContext'

import InputProps from './types.d'

import * as FormStyle from '../FormStyles'
import * as Style from './style'

/**
 * @see Interface {@link IInput}
 * @description Input components
 * @example
 * <Input type="text" name="test-input" defaultValue='Change me' />
 */
const Input: React.FC<InputProps> = ({
  name,
  label,
  type       = 'text',
  defaultValue,
  hint,
  icon,
  placeholder,
  required,
  marginY,
  background = 'var(--rep-primary-light)',
  color      = 'var(--rep-primary)',
  border     = 'var(--rep-primary)',
  forwardRef = null,
  click      = null,
  focus      = null,
  change     = null,
  blur       = null,
  keyUp      = null,
  enterKey   = null,
  min,
  max,
  decimals,
  tooltip,
  maxLength = undefined,
  disabled = false,
  readOnly = false,
  width,
}) => {

  const { i18n } = useGlobalContextState() || { i18n: null }

  const [hasValue, setHasValue] = useState(!!defaultValue)
  const [focused, setFocused] = useState(false)
  const [inputValue, setInputValue] = useState(defaultValue)

  const inputRef = forwardRef || useRef(null)

  const uniqueId = useMemo(() => window.btoa(Math.random().toString()).substring(10, 15), [])

  useEffect(() => {
    if (!inputRef?.current?.value) return
    setHasValue(true)
  }, [inputRef?.current?.value])

  useEffect(() => { setInputValue(defaultValue) }, [defaultValue])
  // No value [bg: grey, border-bottom: primary, radius: none]
  // With value, unfocused [bg: primary-light, border primary-light, radius: 5px]
  // focus [[bg: white, border primary-light, radius: 5px]]

  const handleChange = event => {
    let value = event.currentTarget.value
    value.length ? setHasValue(true) : setHasValue(false)

    if (type === 'number' && isNaN(Number(value))) value = value.slice(0, 1)
    if (type === 'number' && min && max) value = Math.max(Number(min), Math.min(Number(max), Number(value)))
    if (maxLength && value.length > maxLength) value = value.slice(0, maxLength)

    Boolean(change) && change(event)
    setInputValue(value)
  }

  const handleFocus = event => {
    inputRef.current.focus()
    setFocused(true)
    Boolean(focus) && focus(event)
  }

  const handleBlur = event => {
    setFocused(false)
    Boolean(blur) && blur(event)
  }

  const handleKeyUp = event => {
    Boolean(keyUp) && keyUp(event.key)
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter' && !!enterKey) {
      event.preventDefault()
      enterKey(event)
    }
  }

  return (
    <Style.InputContainer
      marginY = {marginY}
      width   = {width}
      hidden  = {type === 'hidden'}
      // ref     = {forwardRef}
    >
      {!!label && <Style.InputLabel
        htmlFor  = {`${name}_${uniqueId}`}
        active   = {hasValue}
        onClick  = {handleFocus}
        required = {required}
        disabled = {disabled}
      >
        {label}
      </Style.InputLabel>
      }
      {!!required &&
        <FormStyle.Required>
          {i18n.t('shared.required')}
        </FormStyle.Required>
      }
      {!!tooltip &&
        <FormStyle.Tooltip>
          <WithTooltip content={tooltip}>
            <FontAwesomeIcon icon="circle-question" />
          </WithTooltip>
        </FormStyle.Tooltip>
      }
      {!!icon &&
        <Style.Icon>
          {icon}
        </Style.Icon>
      }
      <Style.Input
        id          = {`${name}_${uniqueId}`}
        icon        = {!!icon}
        required    = {required}
        type        = {type}
        ref         = {inputRef}
        name        = {name}
        onFocus     = {handleFocus}
        onKeyUp     = {handleKeyUp}
        onKeyDown   = {handleKeyDown}
        onBlur      = {handleBlur}
        onClick     = {click ? click : () => { }}
        onChange    = {handleChange}
        onWheel     = {e => e.target.blur()}
        hasValue    = {hasValue}
        focused     = {focused}
        readOnly    = {readOnly || disabled}
        placeholder = {placeholder}
        maxLength   = {maxLength}
        value       = {inputValue}
        min         = {min}
        max         = {max}
        lang        = 'en-US'
        step        = {type === 'number' ? (decimals ? .01 : 1) : undefined}
        inputMode   = {type === 'number' ? (decimals ? 'decimal' : 'numeric') : undefined}
        background  = {background}
        color       = {color}
        border      = {border}
      />
      {disabled && <Style.LockIcon><FontAwesomeIcon icon="lock" /></Style.LockIcon>}
      {!!hint && <Style.InputHint>{hint}</Style.InputHint>}
    </Style.InputContainer>
  )
}

export default Input
