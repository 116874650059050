import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import Avatar from '@components/Avatar'
import Tag    from '@components/Tag'

import * as Style from './style'

import { IReport } from '@interfaces/index'

import { useGlobalContextState } from '@context/GlobalContext'

const ReportCard: React.FC<{ report: IReport, target: string, openInfo: boolean }> = ({
  report,
  target,
  openInfo = true
}) => {

  const {
    i18n,
    current_user,
    current_company,
    CONSTANTS,
    setInfoWindowProps,
  } = useGlobalContextState()

  const status = ({
    [CONSTANTS.REPORT.STATUS.NEW]:
      <Tag
        color          = 'var(--rep-primary)'
        icon           = {<FontAwesomeIcon icon="star" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.ASSIGNED]:
      <Tag
        color          = 'var(--rep-accent)'
        icon           = {<FontAwesomeIcon icon="users" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.ONGOING]:
      <Tag
        color          = 'var(--rep-success)'
        icon           = {<FontAwesomeIcon icon={['far', 'circle']} fade />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.DONE]:
      <Tag
        color          = 'var(--rep-warning)'
        icon           = {<FontAwesomeIcon icon="clipboard-check" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t('report.status.done_tasks')}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.ON_HOLD]:
      <Tag
        color          = 'var(--rep-danger)'
        icon           = {<FontAwesomeIcon icon="pause" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.TO_APPROVE]:
      <Tag
        color          = 'var(--rep-warning)'
        icon           = {<FontAwesomeIcon icon="check-to-slot" />}
        tooltipTitle = {i18n.t('report.report_status')}
        tooltipContent = {i18n.t('report.to_be_validated_by', { name: report.validated_by?.name || '' })}
      >
        {i18n.t('report.to_be_validated')}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.APPROVED]:
      <Tag
        color          = 'var(--rep-success)'
        icon           = {<FontAwesomeIcon icon="check" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.CLOSED]:
      <Tag
        color          = 'var(--rep-neutral-primary)'
        icon           = {<FontAwesomeIcon icon="lock" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>,
    [CONSTANTS.REPORT.STATUS.CANCELED]:
      <Tag
        color          = 'var(--rep-danger)'
        icon           = {<FontAwesomeIcon icon="ban" />}
        tooltipContent = {i18n.t('report.report_status')}
      >
        {i18n.t(`report.status.${report.status}`)}
      </Tag>
  })[report.status] ?? <div></div>

  const openDetails = (object, type, target = 'page') => {
    if (!openInfo) return
    current_company.beta_access
      ? setInfoWindowProps({ item: object, type: type, target: target })
      : window.open(`/${type}/${object.id}`, '_blank', 'noopener,noreferrer')
  }

  return (
    <Style.ReportCard>
      <Style.ReportHeaderContainer onClick={() => openDetails(report, 'reports', target)}>
        {/* <Style.ReportHeader> */}
        <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap'}}>
          {status}
          <Tag
            tooltipContent = {report.type.name}
            icon           = {<FontAwesomeIcon icon={report.type.logo || 'triangle-exclamation'} />}
            color          = {report.type.color}
            background     = {`${report.type.color}20`}
          />
          {!!report.priority &&
              <Tag
                icon           = {<FontAwesomeIcon icon="exclamation-triangle" />}
                color          = 'var(--rep-neutral-primary)'
                border         = 'var(--rep-neutral-primary-light)'
                tooltipContent = {i18n.t('report.priority')}
              >
                {report.priority.name}
              </Tag>
          }
        </div>
        <div style={{ display: 'flex', gap: '4px'}}>
          {/* </Style.ReportHeader> */}
          { (report.notification_count || 0) > 0 &&
              <Style.UnseenMsgCount>{report.notification_count}</Style.UnseenMsgCount>
          }
          {current_company.permissions.access_material_requests && report.has_open_material_requests &&
            <Tag
              icon           = {<FontAwesomeIcon icon='boxes-stacked' />}
              color          = 'var(--rep-warning)'
              background     = 'var(--rep-warning-light)'
              tooltipContent = {i18n.t('material_request.material_requested')}
            />
          }
          {!!report.urgent &&
            <Tag
              background     = 'var(--rep-danger)'
              border         = 'var(--rep-danger)'
              color          = 'white'
              icon           = {<FontAwesomeIcon icon="land-mine-on" size="sm"/>}
              tooltipContent = {i18n.t('report.urgent')}
            />
          }
        </div>
      </Style.ReportHeaderContainer>
      <div onClick={() => openDetails(report, 'reports', target)}>
        <Style.ReportTitle>
          {report.title}
        </Style.ReportTitle>
        <Tag
          icon           = {<FontAwesomeIcon icon="hashtag" />}
          color          = 'var(--rep-neutral-primary)'
          hover          = 'var(--rep-neutral-light)'
          border         = 'white'
          tooltipContent = {i18n.t('report.report_number')}
          marginY        = 'S'
        >
          {report.report_number}
        </Tag>
        <span style={{ marginRight: '4px' }}></span>
        {!!report.deadline &&
          <Tag
            icon           = {report.deadline_delta >= 0 ? <FontAwesomeIcon icon="calendar-day" /> : <FontAwesomeIcon icon="triangle-exclamation" />}
            color          = {report.deadline_delta >= 0 ? 'var(--rep-neutral-primary)' : 'var(--rep-danger)'}
            background     = {report.deadline_delta >= 0 ? 'var(--rep-neutral-light)' : 'unset'}
            tooltipContent = {<>
              <div>{new Date(report.deadline).toLocaleDateString(i18n.locale)}</div>
              {report.deadline_delta < 0 && <div>{report.deadline_string}</div>}
            </>}
            tooltipTitle   = {i18n.t('report.deadline')}
          >
            {new Date(report.deadline).toLocaleDateString(i18n.locale)}
          </Tag>
        }

        {!!report.description &&
          <Style.ReportText>
            {report.description}
          </Style.ReportText>
        }
      </div>
      <Style.ReportFooter>
        <Style.FooterLeft>
          {!!report.unit &&
              <Tag
                icon           = {<FontAwesomeIcon icon="location-dot" />}
                color          = 'var(--rep-neutral-primary)'
                background     = 'transparent'
                tooltipTitle   = {i18n.t('unit.unit')}
                tooltipContent = {report.unit.path_string}
                click          = {() => openDetails(report.unit, 'units', target)}
              >
                {report.unit.name}
              </Tag>
          }
          {!!report.amenity &&
              <Tag
                icon           = {<FontAwesomeIcon icon="gear" />}
                color          = 'var(--rep-neutral-primary)'
                background     = 'transparent'
                tooltipTitle   = {i18n.t('amenity.amenity')}
                tooltipContent = {report.amenity.path_string}
                click          = {() => openDetails(report.amenity, 'amenities', target)}
              >
                {report.amenity.name}
              </Tag>
          }
        </Style.FooterLeft>
        <Style.AvatarIcons>
          {!!report.requester &&
            <Avatar
              firstName      = {report.requester?.first_name}
              lastName       = {report.requester?.last_name}
              title          = {i18n.t('shared.requested_by')}
              background     = 'var(--rep-neutral-light)'
              color          = 'var(--rep-neutral)'
              tooltipContent = {
                <>
                  <div>
                    {i18n.t('report.created_by_on', { date: new Date(report.created_at).toLocaleDateString(), user: `${report.requester?.name}` })}
                  </div>
                  {report.requester?.phone &&
                    <div>
                      {<FontAwesomeIcon icon="phone" />} {report.requester?.phone}
                    </div>
                  }
                </>
              }
            />
          }
          {!!report.responsible &&
              <Avatar
                firstName      = {report.responsible?.first_name}
                lastName       = {report.responsible?.last_name}
                title          = {i18n.t('shared.responsible')}
                background     = {report.responsible?.id === current_user.id ? 'var(--rep-warning-light)' : 'var(--rep-neutral-light)'}
                color          = {report.responsible?.id === current_user.id ? 'var(--rep-warning)' : 'var(--rep-neutral)'}
                tooltipContent = {
                  <>
                    <div>
                      {`${report.responsible?.name}`}
                    </div>
                    {report.responsible?.phone &&
                      <div>
                        {<FontAwesomeIcon icon="phone" />} {report.responsible?.phone}
                      </div>
                    }
                  </>
                }
              />
          }
        </Style.AvatarIcons>
      </Style.ReportFooter>
    </Style.ReportCard>
  )

}

export default ReportCard
