import styled from 'styled-components'

export const QuestionContainer = styled.div`
  display:         flex;
  gap:             8px;
  justify-content: space-between;
  align-items:     center;
  margin:          8px 0;
  border:          1px solid var(--rep-primary-light);
  padding:         12px;
  border-radius:   5px;
  /* padding:         0 4px; */
  margin: 8px 0;
`

export const QuestionName = styled.div`
  color:       var(--rep-neutral-primary);
  font-weight: 500;
  display:     flex;
  align-items: center;
`
