import styled from 'styled-components'

export const ProgressText = styled.div`
  display:         flex;
  justify-content: space-between;
  margin-bottom:   8px;
`

export const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: var(--rep-neutral-primary-light);
`
