import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import Button from '@components/Button'
import Input  from '@components/Form/Input'
import Tag    from '@components/Tag'

import * as Style from './style'

import { useGlobalContextState } from '@context/GlobalContext'

export default function FormQuestion({ question, removeQuestion }) {

  const { i18n, CONSTANTS } = useGlobalContextState()
  const { FORM_ANSWER } = CONSTANTS

  const formFieldColor = (value, attribute) => {
    switch (value) {
      case FORM_ANSWER.FIELDS.HIDDEN:
        if (attribute === 'background') return 'var(--rep-neutral-light)'
        if (attribute === 'color')      return 'var(--rep-neutral)'
        break
      case FORM_ANSWER.FIELDS.OPTIONAL:
        if (attribute === 'background') return 'var(--rep-primary-light)'
        if (attribute === 'color')      return 'var(--rep-primary)'
        break
      case FORM_ANSWER.FIELDS.MANDATORY:
        if (attribute === 'background') return 'var(--rep-warning-light)'
        if (attribute === 'color')      return 'var(--rep-warning)'
        break
      default:
        if (attribute === 'background') return 'var(--rep-neutral-light)'
        if (attribute === 'color')      return 'var(--rep-neutral)'
        break
    }
  }

  const answerTypeTag = (type) => {
    let fontAwesomeIcon = null

    switch(type) {
      case FORM_ANSWER.TYPES.BOOLEAN:
        fontAwesomeIcon = <FontAwesomeIcon icon="fa-square-check fa-regular" fixedWidth />
        break
      case FORM_ANSWER.TYPES.DATE:
        fontAwesomeIcon = <FontAwesomeIcon icon="fa-calendar fa-regular" fixedWidth />
        break
      case FORM_ANSWER.TYPES.DATE_TIME:
        fontAwesomeIcon = <FontAwesomeIcon icon="fa-clock fa-regular" fixedWidth />
        break
      case FORM_ANSWER.TYPES.NUMBER:
        fontAwesomeIcon = <FontAwesomeIcon icon="hashtag" fixedWidth />
        break
      case FORM_ANSWER.TYPES.SELECT:
        fontAwesomeIcon = <FontAwesomeIcon icon="list" fixedWidth />
        break
      case FORM_ANSWER.TYPES.TEXT:
        fontAwesomeIcon = <FontAwesomeIcon icon="font" fixedWidth />
        break
    }

    return <Tag
      icon           = {fontAwesomeIcon}
      color          = {'var(--rep-neutral-primary)'}
      tooltipTitle   = {i18n.t('custom_field.reply_type')}
      tooltipContent = {i18n.t(`form_reply.types.${type}`)}
    />
  }

  return (
    <Style.QuestionContainer>
      <Style.QuestionName>
        {answerTypeTag(question.answer_type)}
        {question.question}
      </Style.QuestionName>
      <div style={{ display: 'flex', gap: '8px' }}>
        {!!question.answer_options?.length &&
          <Tag
            icon           = {<FontAwesomeIcon icon="question" fixedWidth />}
            color          = {'var(--rep-neutral)'}
            border         = {'var(--rep-neutral-primary-middle)'}
            tooltipTitle   = {i18n.t('custom_field.options')}
            tooltipContent = {<>
              {question.answer_options.map((option, key) =>
                <div key={key}>{option}</div>
              )}
            </>}
          />
        }
        {!!question.hint &&
          <Tag
            icon           = {<FontAwesomeIcon icon="circle-exclamation" fixedWidth />}
            color          = {'var(--rep-neutral)'}
            border         = {'var(--rep-neutral-primary-middle)'}
            tooltipTitle   = {i18n.t('form.hint_unanswered')}
            tooltipContent = {question.hint}
          />
        }

        <Tag
          color          = {formFieldColor(question.na, 'color')}
          background     = {formFieldColor(question.na, 'background')}
          icon           = {<FontAwesomeIcon icon="eye-slash" />}
          tooltipContent = {i18n.t(`form.tooltip.na.${question.na_field}`)}
        />
        <Tag
          color          = {formFieldColor(question.photo, 'color')}
          background     = {formFieldColor(question.photo, 'background')}
          icon           = {<FontAwesomeIcon icon="image" />}
          tooltipContent = {i18n.t(`form.tooltip.photo.${question.photo_field}`)}
        />
        <Tag
          color          = {formFieldColor(question.comment, 'color')}
          background     = {formFieldColor(question.comment, 'background')}
          icon           = {<FontAwesomeIcon icon="comment-dots" />}
          tooltipContent = {i18n.t(`form.tooltip.comment.${question.comment_field}`)}
        />
        {!!removeQuestion &&
          <Button
            icon   = {<FontAwesomeIcon icon="trash" />}
            border = 'var(--rep-danger)'
            color  = 'var(--rep-danger)'
            click  = {() => removeQuestion(question.id)}
          />
        }
      </div>
      <Input
        name         = {`question-${question.id}`}
        type         = "hidden"
        defaultValue = {JSON.stringify(question)}
      />
    </Style.QuestionContainer>
  )

}
