import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as InfoStyle from '@components/InfoWindow/style'

const InfoForm = ({ closeInfoWindow, title, children }) => {
  return(
    <>
      <InfoStyle.InfoHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '4px 0' }}>
          <div></div>
          <InfoStyle.CloseIcon onClick={closeInfoWindow}>
            <FontAwesomeIcon icon="times" />
          </InfoStyle.CloseIcon>
        </div>
        <h2>{title}</h2>
      </InfoStyle.InfoHeader>
      {children}
    </>
  )
}

export default InfoForm
